<div *ngIf="this.currentUserService.browserOk" class="loginout" id="loginout">

    <ng-container *ngIf="!this.isLoggedIn && !this.loginButtonHidden">
        <button mat-button
                class="loginout-text"
                (click)="logIn()">
            LOG IN
        </button>
    </ng-container>
    <div *ngIf="this.isLoggedIn" class="loginout-text" [ngClass]="{'impersonating':doingImpersonation()}">
        <ng-container *ngIf="doingImpersonation()">Hello, {{ currentUserService.user.displayName }}</ng-container>
        <ng-container *ngIf="this.ShowAppChrome">
            <button mat-icon-button aria-label="Feedback" class="feedback-button">
                <mat-icon class="feedback-icon" (click)="sendFeedback()" title="Contact the team to leave feedback and report issues">feedback</mat-icon>
            </button>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="User Menu" class="person-button">
                <mat-icon class="person-icon">person</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="userinfo()">My Profile</button>
                <button mat-menu-item (click)="about()">About {{this.SiteName}}</button>
                <button mat-menu-item (click)="whatsnew()">What's New?</button>
                <ng-container *ngIf="this.UserIsSystemAdmin">
                    <button mat-menu-item (click)="openUITestCenter()">Open UI Test Center</button>
                </ng-container>
                <button mat-menu-item (click)="logOut()">LOG OUT</button>

                <ng-container *ngIf="!doingImpersonation()">
                    <button mat-menu-item
                            *ngFor="let user of impersonatableUsers"
                            (click)="impersonate(user.mfUserid)"
                            title="{{user.displayName}}">
                        Impersonate {{user.displayName}}
                    </button>
                </ng-container>
                <button mat-menu-item *ngIf="doingImpersonation()" (click)="stopImpersonating()">Stop Impersonating</button>
            </mat-menu>
        </ng-container>
    </div>
</div>
