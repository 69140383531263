import { Component, OnInit, Input, ElementRef } from '@angular/core';

import { Menu } from '../../shared/models/navigation/menu.model';
import { DataCollection } from '../../shared/models/site-content/data-collection.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserClaims } from '../../shared/models/user-claims.model';
import { ScriptableBaseComponent } from '../../shared/components/scriptable-base/scriptable-base.component';
import { AdditionalElementInfo } from '../../shared/models/component-scripting/additional-element-info.model';
import { QueryParamsService } from '../../shared/services/query-params.service';

@Component({
    selector: 'app-site',
    templateUrl: './site.component.html',
    styleUrls: ['./site.component.scss']
})
export class SiteComponent extends ScriptableBaseComponent implements OnInit {
    // Inputs and properties.
    @Input()
    navMenu: Menu;

    @Input()
    currentSite: DataCollection;

    private userClaims: UserClaims;

    // Constructor.
    public constructor(private route: ActivatedRoute, elForBaseClass: ElementRef, private queryParamsService: QueryParamsService) {
        super(elForBaseClass);
        //this.element('#siteSideNavContainer').hasMatListItems(new AdditionalElementInfo('span.ng-star-inserted'));
        this.element('#siteSideNavContainer').hasMatListItems(new AdditionalElementInfo({ innerTextSelector: 'span.ng-star-inserted' }));
    }

    // Life cycle method(s).
    public ngOnInit() {
        super.ngOnInit();

        // Subscribe to route changes.
        this.route.data
            .subscribe((data: { navMenu: Menu, claims: UserClaims }) => {
                this.navMenu = data.navMenu;
                this.userClaims = data.claims;
            });

        this.route.data
            .subscribe((data: { currentSite: DataCollection }) => {
                this.currentSite = data.currentSite;
                this.currentSite.foo();
            });
    }

    // Getter method(s).
    public get UserClaims(): UserClaims {
        return this.userClaims;
    }
}
