import {
    Component,
    OnInit,
    Renderer2,
    Output,
    EventEmitter,
    Type as AngularCoreType
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormFieldMode } from '../form-field-mode.enum';
import { ControlType, FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormFieldOnInitPropertyEnum } from '../../../models/form-builder/form-field-on-init-output-property.enum';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';
  
// 10-23-2020 note:  this component class is now only used, at times, 
//                   during development, as it has been supplanted
//                   with component class RichTextFormField.

// Note:  please note the 'providers' definition below, as it is needed.
//        Without it, you will get the following exception:
//
//             No value accessor for form control with unspecified name
//
// The above exception gets thrown when a component, in this case our
// base class, implements interface 'ControlValueAccessor' and does not
// provide the 'providers' definition below.  Implementing the
// 'ControlValueAccessor' interface allows a form field component to
// support [(ngMode)], so users of the component can use [(ngModel)].
@Component({
    selector: 'app-paragraph-form-field',
    templateUrl: './paragraph-form-field.component.html',
    styleUrls: ['./paragraph-form-field.component.scss', '../form-fields.scss'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ParagraphFormFieldComponent,
            multi: true
        }
    ]

})
export class ParagraphFormFieldComponent extends FormFieldBaseComponent implements OnInit {
    // Properties.
    // Note:  several properties are implemented in my base class.
    @Output() onInit = new EventEmitter();

    private readonly formFieldProperties: string[] =
        [FormFieldPropertyEnum.NAME,
        FormFieldPropertyEnum.FIELD_GROUP,
        FormFieldPropertyEnum.REQUIRED,
        FormFieldPropertyEnum.DISPLAY_NAME,
        FormFieldPropertyEnum.BLANK_VALUE,
        FormFieldPropertyEnum.HELP_TEXT,
        FormFieldPropertyEnum.PLACEHOLDER_TEXT,
        FormFieldPropertyEnum.TOOL_TIP,
        FormFieldPropertyEnum.DEFAULT_VALUE,
        FormFieldPropertyEnum.MAX_LENGTH,
        FormFieldPropertyEnum.REGEX,
        FormFieldPropertyEnum.INSTRUCTIONS_TEXT
        ];

    // Constructor.
    constructor(private renderer: Renderer2) {
        super();

        return;
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshProperties = {
            //component: this,
            //formField: this.formField,
            //properties: this.formFieldNames,

            //'requiredPreviewInstanceModesHeight': 150,
            //'requiredPreviewInstanceModesHeightUnit': 'px'
        };
        hshProperties[FormFieldOnInitPropertyEnum.COMPONENT] = this;
        hshProperties[FormFieldOnInitPropertyEnum.FORM_FIELD] = this.formField;
        hshProperties[FormFieldOnInitPropertyEnum.PROPERTIES] = this.formFieldProperties;
        hshProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT] = 150;
        hshProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT_UNIT] = 'px';

        return (hshProperties);
    }

    // Life cycle methods.
    ngOnInit(): void {
        /*
        if (this.Mode === FormFieldMode.DESIGN) {
            this.onInit.emit({ formField: this.formField, properties: this.formFieldNames });
        }
        */
        //this.onInit.emit({ component: this, formField: this.formField, properties: this.formFieldNames });
        let hshProperties = this.getProperties();
        this.onInit.emit(hshProperties);

        return;
    }

    protected formInstanceElementReceived(): void {
        if (this.Mode === 'design') {
            if ((this.FormField.displayRows === undefined) || (this.FormField.displayRows === null) || (this.FormField.displayRows.trim() === '')) {
                this.FormField.displayRows = '4';
            }
        } else if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                // If I have no value assigned but
                // have a default value, apply it now.
                this.setDefaultTextValue();

                // Use a base class method to
                // set up a decimal form group.
                this.setupTextFormControl();

                if ((this.FormField.displayRows === undefined) || (this.FormField.displayRows === null) || (this.FormField.displayRows.trim() === '')) {
                    this.FormField.displayRows = '5';
                }
            }
        }

        return;
    }

    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    protected writeValueTriggered(): void {
        if (this.Mode === 'design') {
            if ((this.FormField.displayRows === undefined) || (this.FormField.displayRows === null) || (this.FormField.displayRows.trim() === '')) {
                this.FormField.displayRows = '4';
            }
        } else if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                // If I have no value assigned but
                // have a default value, apply it now.
                // this.setDefaultTextValue();

                // Use a base class method to
                // set up a decimal form group.
                // this.setupTextFormControl();

                this.SetupFormControlFromWriteValue();

                if ((this.FormField.displayRows === undefined) || (this.FormField.displayRows === null) || (this.FormField.displayRows.trim() === '')) {
                    this.FormField.displayRows = '5';
                }
            }
        }

        return;
    }

    // Override the getDisplayValue() base class method.
    // Define a method that allows a component to return its display value.
    public pseudoStatic_getDisplayValue(formFieldParam: FormField,
        formInstanceElementParam: FormInstanceElement,
        gridRow: IGridRow,
        processingPhase: FormFieldProcessingPhaseEnum): string {
        //if ((!formInstanceElementParam.transientValueSetFlag) ||
        if ((!formInstanceElementParam.UserUpdatedData) ||
            (!formInstanceElementParam.textValue)) {
            // Set a default value.
            formInstanceElementParam.TextValue = '';
        }

        return (formInstanceElementParam.textValue);
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (ParagraphFormFieldComponent);
    }
}
