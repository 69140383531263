import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { AutocompleteService } from '../../../shared/services/autocomplete.service';
import { IAutoCompleteItemData } from '../../interfaces/iautocomplete-item-data.interface';
import { ElementRef } from '@angular/core';
import { MatChipInputEvent, MatChipList } from "@angular/material/chips";

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {
    @Input() title: string = '';
    @Output() selectedItems: EventEmitter<string[]> = new EventEmitter<string[]>();
    //VNEXT-982: KLW - Fix validation on blur
    @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('trigger') matAutocompleteTrigger: MatAutocompleteTrigger;
    @ViewChild('autocompleteInput') autocompleteInput: ElementRef<HTMLInputElement>;

    @ViewChild("chipList") selectedChips: MatChipList;

    public selectControl = new FormControl();

    //VNEXT-569: KLW - Needed for the control to highlight red when it's required
    public chipControl = new FormControl();

    public separatorKeysCodes: number[] = [ENTER, COMMA];

    public filteredData: Observable<IAutoCompleteItemData[]>;
    //VNEXT-538: KLW - Implementing Autocomplete
    private singleDropDownOptions: IAutoCompleteItemData[] = [];

    public displayFn = (): string => '';

    public searchValue = null;

    private fie_id: number = -1;
    private rawData: IAutoCompleteItemData[] = [];
    private filterString: string = '';
    private _dropDownIcon: string = 'arrow_drop_down';

    private _autocompleteService: AutocompleteService
    private _totalCount: number = 0;

    private hasStartsWith: boolean = false;
    private hasContains: boolean = false;
    private isRequired: boolean = false;

    private showChipList: boolean = true;

    constructor(private autocompleteService: AutocompleteService
    ) {
        this._autocompleteService = autocompleteService;
    }

    ngOnInit(): void {
        //VNEXT-569: KLW - Needed for the control to highlight red when it's required
        this.chipControl = new FormControl();
    }

    public get DropDownIcon(): string {
        return this._dropDownIcon;
    }

    public get ShowRemoveAll(): boolean {

        //if (this.SelectedData != null)
        //    return this.SelectedData.length > 0;
        //else
        //    false;

        return false;
    }

    public get Title(): string {
        return this.title;
    }

    public get SelectedData(): IAutoCompleteItemData[] {
        var retVal = this.rawData.filter(x => x.selected);

        //VNEXT-569: KLW - Needed for the control to highlight red when it's required
        this.chipControl.setValue(retVal);

        return retVal;
    }

    public get HideRequiredMarker(): boolean {
        return true;
    }

    public get TotalCount(): number {
        return this._totalCount;
    }

    public get Required(): boolean {
        return this.isRequired;
    }

    public get ShowChipList(): boolean {
        return this.showChipList;
    }

    //VNEXT-519: KLW - Refinements to the Type ahead functionality
    public clearOptions() {
        //this.autocompleteInput.nativeElement.value = null;
        //this.selectControl.setValue(null);
    }

    public preventBackspaceExitingFocus() {
        if (this.autocompleteInput.nativeElement.value == '') {
            this.autocompleteInput.nativeElement.focus();
        }
    }

    public setTriggerStatusIconClick() {

        if (this.matAutocompleteTrigger != null) {
            if (this.matAutocompleteTrigger.panelOpen) {
                this.matAutocompleteTrigger.closePanel();
                this._dropDownIcon = 'arrow_drop_down';
            }
            else {
                this.matAutocompleteTrigger.openPanel();
                this._dropDownIcon = 'arrow_drop_up';
            }
        }
    }

    public setTriggerStatusInputClick() {
        if (this.matAutocompleteTrigger != null) {
            if (this.matAutocompleteTrigger.panelOpen) {
                this._dropDownIcon = 'arrow_drop_up';
            }
            else {
                this._dropDownIcon = 'arrow_drop_down';
            }
        }
    }

    //VNEXT-982: KLW - Fix validation on blur
    public blurred() {
        this.onBlur.emit();
    }

    //VNEXT-538: KLW - Implementing Autocomplete
    public singleBlurred() {
        if (!this.selectControl.valid)
        {
            this.selectedItems.emit([]);
            return;
        }

        //Value is blank
        if (!this.selectControl.value[0])
        {
            this.selectedItems.emit([]);
            return;
        }

        var inputValue = this.selectControl.value;

        if (!this.singleDropDownOptions.some(x => x.item == inputValue)) {
            // this.selectedItems.emit([inputValue]);
            return;
        }

        this.onBlur.emit();
    }

    public setData(passedData: IAutoCompleteItemData[], isSingle: boolean = false) {
        this.rawData = passedData;

        if (isSingle) {
            this.selectControl.setValue(passedData);

            this.filteredData = this.selectControl.valueChanges.pipe(
                debounceTime(300),
                switchMap(value => this.autocompleteServiceSearch(value, [], this.fie_id, this.hasStartsWith, this.hasContains)
                )
            );

            this.filteredData.subscribe(
                (options) => {
                    this.singleDropDownOptions = options;
                });
        }
        else {
            this.filteredData = this.selectControl.valueChanges.pipe(
                debounceTime(300),
                switchMap(value => this.autocompleteServiceSearch(value, this.SelectedData.map(x => x.item), this.fie_id, this.hasStartsWith, this.hasContains)
                )
            );
        }

        this.matAutocompleteTrigger.panelClosingActions.subscribe(() => {
            this._dropDownIcon = 'arrow_drop_down';
        });
    }

    public setFIE_ID(id: number) {
        this.fie_id = id;
    }

    public setHasStartsWith(value: boolean) {
        this.hasStartsWith = value;
    }

    public setHasContains(value: boolean) {
        this.hasContains = value;
    }

    public setIsRequired(value: boolean) {
        this.isRequired = value;
    }

    public setShowChipList(value: boolean) {
        this.showChipList = value;
    }

    public optionClicked(event: Event, data: IAutoCompleteItemData) {
        event.stopPropagation();
        this.toggleSelection(data);
    };

    public optionClickedSingle(event: Event, data: IAutoCompleteItemData) {
        const results: string[] = [];

        results.push(data.item);
        this.selectedItems.emit(results);
    };

    public toggleSelection(data: IAutoCompleteItemData) {
        var item = this.rawData.find(x => x.item == data.item);

        if (item != null) {
            item.selected = !item.selected;
        }
        else {
            data.selected = true;
            this.rawData.push(data);
        }

        this.emitAdjustedData();
    };

    public emitAdjustedData() {
        const results: string[] = []
        this.rawData.forEach((data: IAutoCompleteItemData) => {
            if (data.selected)
                results.push(data.item);
        });
        this.selectedItems.emit(results);
    };

    public removeChip(data: IAutoCompleteItemData) {
        this.toggleSelection(data);
        this.emitAdjustedData();
    };

    public removeAllChips() {
        this.rawData.forEach(x => {
            x.selected = false;
        });

        this.emitAdjustedData();
    }

    private autocompleteServiceSearch(queryString: string = null, selectedItems: string[], fie_ID: number, hasStartsWith: boolean = false, hasContains: boolean = false): Promise<IAutoCompleteItemData[]> {
        if ((queryString != null) && (queryString.trim() != '') && this.fie_id >= 0 && ((hasContains || hasStartsWith) || (!hasContains && !hasStartsWith))) {
            var result: Promise<IAutoCompleteItemData[]> = null;

            if ((hasContains && hasStartsWith) || (!hasContains && !hasStartsWith))
                result = this._autocompleteService.search(queryString, 100, selectedItems, fie_ID);
            else if (hasContains)
                result = this._autocompleteService.contains(queryString, 100, selectedItems, fie_ID);
            else if (hasStartsWith)
                result = this._autocompleteService.startsWith(queryString, 100, selectedItems, fie_ID);

            this._totalCount = this._autocompleteService.totalCount;

            return result;
        } else {
            let retValue = new Promise<IAutoCompleteItemData[]>((resolve, reject) => {
                resolve([]);
            });

            return retValue;
        }
    }
}
