<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>
<ng-template #tooltipTemplate><ng-content select="[projectedTooltip]"></ng-content></ng-template>

<div>
    <div *ngIf="this.Mode === 'design'" class="flex-row-top-left">
        <div>
            <div class="design-mode-field-label">{{DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
            <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>

            <ng-container *ngIf="(this.SelectOptions === undefined) || (this.SelectOptions === null) || (this.SelectOptions.length === 0)">
                <mat-radio-button disabled></mat-radio-button>
            </ng-container>

            <ng-container *ngIf="(this.SelectOptions !== undefined) && (this.SelectOptions !== null) && (this.SelectOptions.length > 0)">
                <div *ngIf="!UseHorizontalTabFormat">
                    <mat-radio-group [(ngModel)]="this.DefaultRadioButtonValue">
                        <ng-container *ngFor="let strRadioButton of this.SelectOptions; let i=index">
                            <ng-container *ngIf="(i > 0) && (!this.IsVertical)">&nbsp;&nbsp;&nbsp;</ng-container>
                            <ng-container *ngIf="(i > 0) && (this.IsVertical)"><br /></ng-container>
                            <mat-radio-button value="{{strRadioButton}}" class="margin-left-10px" disabled>
                                {{strRadioButton}}
                            </mat-radio-button>
                        </ng-container>
                    </mat-radio-group>
                </div>
                <div *ngIf="UseHorizontalTabFormat">
                    <mat-tab-group>
                        <ng-container *ngFor="let strRadioButton of this.SelectOptions">
                            <mat-tab label="{{strRadioButton}}"></mat-tab>
                        </ng-container>
                    </mat-tab-group>
                </div>
            </ng-container>
        </div>
        <!--
            I tried to find a way to pass a context to project tooltip in order to
            make field-specific adjustments like this, but couldn't find a way
         -->
        <div style="margin-top:-10px">
            <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
        </div>
    </div>
    <div class="flex-row-top-left preview-instance-div" *ngIf="(this.Mode === 'preview') || (this.Mode === 'instance')">
        <div>
            <ng-container *ngIf="this.FormGroup !== null">
                <form [formGroup]="this.FormGroup">
                    <div *ngIf="!UseHorizontalTabFormat">
                        <div class="field-label">{{DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                        <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                        <mat-radio-group formControlName="radio_buttons_form">
                            <ng-container *ngFor="let strRadioButton of this.SelectOptions; let i=index">
                                <ng-container *ngIf="(i > 0) && (!this.IsVertical)">&nbsp;&nbsp;&nbsp;</ng-container>
                                <ng-container *ngIf="(i > 0) && (this.IsVertical)"><br /></ng-container>

                                <mat-radio-button value="{{strRadioButton}}"
                                                  [disabled]="this.ReadOnly"
                                                  (focus)="this.handleOnFocus($event)"
                                                  class="margin-left-10px">
                                    {{strRadioButton}}
                                </mat-radio-button>
                            </ng-container>
                        </mat-radio-group>
                    </div>
                    <div *ngIf="UseHorizontalTabFormat">
                        <mat-tab-group (selectedTabChange)="radioTabClick($event);">
                            <ng-container *ngFor="let strRadioButton of this.SelectOptions">
                                <mat-tab (click)="radioTabClick($event)" label="{{strRadioButton}}"></mat-tab>
                            </ng-container>
                        </mat-tab-group>
                    </div>
                </form>
            </ng-container>

            <ng-container *ngIf="(! this.SelectOptions) || (this.SelectOptions.length === 0)">
                <div>{{DisplayName}}</div><br />
                <span>This control has no radio buttons defined</span>
            </ng-container>
        </div>

        <div style="margin-top:-10px">
            <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
        </div>
    </div>
</div>

