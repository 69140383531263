import {
    Component,
    OnInit,
    Renderer2,
    Output,
    EventEmitter,
    Type as AngularCoreType
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

//import { FormFieldMode } from '../form-field-mode.enum';
import { ControlType, FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormField, DisplayFormatEnum } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';

// Note:  please note the 'providers' definition below, as it is needed.
//        Without it, you will get the following exception:
//
//             No value accessor for form control with unspecified name
//
// The above exception gets thrown when a component, in this case our
// base class, implements interface 'ControlValueAccessor' and does not
// provide the 'providers' definition below.  Implementing the
// 'ControlValueAccessor' interface allows a form field component to
// support [(ngMode)], so users of the component can use [(ngModel)].
@Component({
    selector: 'app-radio-buttons-form-field',
    templateUrl: './radio-buttons-form-field.component.html',
    styleUrls: ['./radio-buttons-form-field.component.scss', '../form-fields.scss'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: RadioButtonsFormFieldComponent,
            multi: true
        }
    ]

})
export class RadioButtonsFormFieldComponent extends FormFieldBaseComponent implements OnInit {
    // Properties.
    // Note:  several properties are implemented in my base class.
    @Output() onInit = new EventEmitter();
    //VNEXT-561: KLW - Needed for form group
    private formGroupName: string = 'radio_buttons_form';

    private readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.NAME,
            FormFieldPropertyEnum.FIELD_GROUP,
            FormFieldPropertyEnum.REQUIRED,
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.HELP_TEXT,
            FormFieldPropertyEnum.TOOL_TIP,
            FormFieldPropertyEnum.DEFAULT_VALUE,
            FormFieldPropertyEnum.SELECT_OPTIONS,
            FormFieldPropertyEnum.DISPLAY_FORMAT,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT
        ];

    private readonly displayFormats: string[] =
        [
            DisplayFormatEnum.HORIZONTAL,
            DisplayFormatEnum.HORIZONTALTABS,
            DisplayFormatEnum.VERTICAL,
        ];

    // Constructor.
    constructor(private renderer: Renderer2) {
        super();

        return;
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshEventProperties = {
            component: this,
            formField: this.formField,
            properties: this.formFieldProperties,
            displayFormatValues: this.displayFormats
        };

        return (hshEventProperties);
    }

    // Life cycle methods.
    public ngOnInit(): void {
        let hshEventProperties = this.getProperties();

        this.onInit.emit(hshEventProperties);

        return;
    }

    // HTML accessor methods.
    public get NoRadioButtonsConfiguredMessage(): string {
        let message: string =
            'No radio buttons defined:  click the pencil icon to configure this control';

        return (message);
    }

    public get DefaultRadioButtonValue(): string {
        let strDefaultText: string = '';

        if (this.FormField &&
            (this.FormField.defaultValue !== undefined) &&
            (this.FormField.defaultValue !== null) &&
            (this.FormField.defaultValue.trim() !== '')) {
            strDefaultText = this.FormField.defaultValue;
        }

        return (strDefaultText);
    }

    public set DefaultRadioButtonValue(strValue: string) {
        // Note:  this method is a NOOP on purpose.

        return;
    }

    public get IsVertical(): boolean {
        return (this.FormField.displayFormat === DisplayFormatEnum.VERTICAL);
    }

    public get UseHorizontalTabFormat(): boolean {
        return (this.FormField.displayFormat === DisplayFormatEnum.HORIZONTALTABS);
    }

    // Handle getting this field's form instance element.
    protected formInstanceElementReceived(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                // If I have no value assigned but
                // have a default value, apply it now.
                //if (this.FormInstanceElement.transientValueSetFlag != true) {
                //VNEXT-561: KLW - Remove default value
                if (this.FormInstanceElement.UserUpdatedData != true) {
                    this.setDefaultTextSelectValue();
                }

                // Use a base class method to
                // set up a textual form group.
                this.setupTextFormGroup('radio_buttons_form');
            }
        }

        return;
    }

    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    protected writeValueTriggered(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                this.SetupFormGroupFromWriteValue(this.formGroupName);
            }
        }

        return;
    }

    // Override notifyValueChanged.
    protected notifyValueChanged(): void {
        super.notifyValueChanged();

        super.handleOnBlur();
    }

    public radioTabClick(event: any) {
        if (event.index > -1) {
            this.FormGroup.controls['radio_buttons_form'].setValue(this.SelectOptions[event.index]);
        }
    }

    // Override the getDisplayValue() base class method.
    // Define a method that allows a component to return its display value.
    public pseudoStatic_getDisplayValue(formFieldParam: FormField,
        formInstanceElementParam: FormInstanceElement,
        gridRow: IGridRow,
        processingPhase: FormFieldProcessingPhaseEnum): string {
        //if ((!formInstanceElementParam.transientValueSetFlag) ||
        if ((!formInstanceElementParam.UserUpdatedData) ||
            (!formInstanceElementParam.textValue)) {
            // Set a default value.
            //formInstanceElementParam.textValue = '';
            this.setDefaultTextSelectValue();

            formInstanceElementParam.TextValue = this.FormInstanceElement.textValue;
        }

        // NOTE:  NEED TO REVISIT THIS.
        return (formInstanceElementParam.textValue);
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (RadioButtonsFormFieldComponent);
    }
}
