<ng-container *ngIf="this.SiteIsInBetaMode && !isFootnotesGrid">
    <div class="kendo-grid-wrapper">
        <ng-container *ngIf="!this.HideFormFieldBody">
            <kendo-grid *ngIf="FormFieldId"
                        kendoGridRemoteBinding
                        (notifyLoaded)="Helper.handleLoaded($event)"
                        [gridConfig]="this.gridFormFieldComponent.GridConfig"
                        [fieldDefinitionService]="this.FieldDefinitionService"
                        [getCellDisplayValue]="this.GridFormFieldComponent"
                        [formInstanceElementId]="this.FormInstanceElementId"
                        [kendoGridService]="this.KendoGridService"
                        [rowReorderable]="true"
                        [pageSize]="VirtualScrollingPageSize"
                        [skip]="VirtualScrollingSkip"
                        [sort]="this.dataUsedWithKendoGrid.gridState.sort"
                        scrollable="virtual"
                        [rowHeight]="23.14"
                        [height]="this.GetGridHeight()"
                        [selectable]="true"
                        [sortable]="this.SortSettings"
                        [filterable]="this.GridIsFilterable"
                        [navigable]="true"
                        (cellClick)="handleEditCellClick($event)"
                        (edit)="handleEdit($event)"
                        (cancel)="handleCancel($event)"
                        (cellClose)="handleCellClose($event)"
                        (save)="handleAddOrUpdate($event)"
                        (remove)="handleRemove($event)"
                        (add)="addRowToUI($event)"
                        (rowReorder)="handleRowReorder($event)"
                        (detailExpand)="handleDetailExpand($event)"
                        (detailCollapse)="handleDetailCollapse($event)"
                        [(filter)]="Helper.Filters">

                <ng-template kendoGridToolbarTemplate>

                    <button [disabled]="ModifyRowDisabled || NewRowDisabled" style="margin-left:4px; padding:4px; height:30px;" kendoGridAddCommand (click)="this.addValidationAndFlagForNewKendoRow()">
                        <mat-icon aria-hidden="false" aria-label="Add grid row" class="add-row-mat-icon" title="Add row">add</mat-icon>
                    </button>

                    <div *ngIf ="GridIsFilterable">
                        <button *ngIf="!ShowGridFilter" style="margin-left:4px; padding:4px; height:30px;"
                                class="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
                                (click)="this.turnOnGridFilter()">
                            <mat-icon aria-hidden="false" aria-label="Filter Grid" title="Filter Grid">filter_alt</mat-icon>
                        </button>

                        <button *ngIf="ShowGridFilter" style="margin-left:4px; padding:4px; height:30px;"
                                class="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
                                (click)="this.turnOffGridFilter()">
                            <mat-icon aria-hidden="false" aria-label="Reove Grid Filters" title="Remove Grid Filters">filter_alt_off</mat-icon>
                        </button>
                    </div>

                    <kendo-grid-spacer></kendo-grid-spacer>

                    <button *ngIf="ShowGeocodeGrid()" mat-button (click)="this.geoCodeGrid();">
                        <mat-icon aria-hidden="false" aria-label="Geocode address data in grid" class="geocode-data-mat-icon" title="Geocode address data in grid">language</mat-icon>
                    </button>

                    <button mat-button (click)="this.showPasteAreaKendo();">
                        <mat-icon aria-hidden="false" aria-label="Paste from Excel into grid" class="paste-data-mat-icon" title="Paste from Excel into grid">content_paste</mat-icon>
                    </button>

                    <button mat-button (click)="this.exportGridDataClicked()">
                        <mat-icon aria-hidden="false" aria-label="Download grid data" class="export-grid-data-mat-icon" title="Download grid data" svgIcon="microsoft-excel"></mat-icon>
                    </button>

                    <ng-container *ngIf="true">
                        <input type="file"
                               accept=".xlsx,.xls"
                               #selectExcelFile
                               class="hidden-file-input"
                               (change)="this.onExcelFileChanged($event)" />

                        <button class="grid-button" mat-button (click)="this.importGridDataClicked()">
                            <mat-icon aria-hidden="false" aria-label="Upload grid data from Excel file" title="Upload grid data from Excel">upload_file</mat-icon>
                        </button>
                    </ng-container>

                    <ng-container *ngIf="Helper.IsBeingFiltered">
                        <button class="clear-all-filters-btn" mat-stroked-button (click)='Helper.handleClearFilters()'>Clear All Filters</button>
                        {{Helper.ShowingXofN}}
                    </ng-container>

                </ng-template>

                <kendo-grid-rowreorder-column *ngIf="!HasFixedRowHeadings" [width]=30></kendo-grid-rowreorder-column>
                <ng-container *ngFor="let columnDef of this.GridColumnDefs; let iColIndex=index">
                    <kendo-grid-column *ngIf="ShowColumnInRow(columnDef)"
                                       field="{{columnDef.name}}"
                                       title="{{columnDef.displayName != null ? columnDef.displayName : columnDef.name}}"
                                       filter="{{FilterType(columnDef)}}"
                                       [editable]="!this.IsReadOnlyGrid"
                                       [filterable]="ColumnIsFilterable(columnDef)"
                                       [sortable]="IsSortable(columnDef)">

                        <!-- Added to enable use of title attribute for hover text -->
                        <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
                            <span title="{{column.title}}" class="column-header">{{column.title}}</span>
                        </ng-template>

                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span title="{{getCellDisplayValueForKendo(dataItem, columnDef, iColIndex)}}">
                                <!-- TEAMS-894: KLW - Have a new way to display a URL -->
                                <span *ngIf="!this.ShowColumnAsURLLink(columnDef); else URL" [innerHTML]="getCellDisplayValueForKendo(dataItem, columnDef, iColIndex)"></span>
                                <ng-template #URL>
                                    <a class="html-display"
                                       href="{{getCellDisplayValueForKendo(dataItem, columnDef, iColIndex)}}"
                                       target="_blank">{{getCellDisplayValueForKendo(dataItem, columnDef, iColIndex)}}</a>
                                </ng-template>
                            </span>

                            <ng-container *ngIf="this.ShowNumericTotalsFooter">
                                <ng-template kendoGridFooterTemplate>
                                    {{ this.getNumericFooterTotalValueFor(iColIndex, columnDef) | number}}
                                </ng-template>
                            </ng-container>
                        </ng-template>

                        <ng-template kendoGridEditTemplate let-dataItem>
                            <app-form-field-wrapper [formField]="columnDef"
                                                    [formInstance]="FormInstance"
                                                    [mode]="'instance'"
                                                    [readOnly]="false"
                                                    [fieldIsInGrid]="true"
                                                    [gridRowId]="this.DataUsedWithKendoGrid.selectedGridRow?.DatabaseId"
                                                    [gridColumnDefs]="this.GridColumnDefs"
                                                    [(ngModel)]="this.DataUsedWithKendoGrid.selectedGridRow?.FormInstanceElementWrappers[iColIndex].formInstanceElement"
                                                    (onInit)="this.gridColumnInit($event, columnDef)"
                                                    (ngModelChange)="this.gridColumnValueChanged(columnDef,this.DataUsedWithKendoGrid.selectedGridRow?.FormInstanceElementWrappers[iColIndex].formInstanceElement)"
                                                    (formStatusChanged)="handleValidityChange($event)"
                                                    (wrapperFormControlCreated)="this.handleWrapperFormControlCreated($event)">
                            </app-form-field-wrapper>

                            <ng-container *ngIf="this.ShowNumericTotalsFooter">
                                <ng-template kendoGridFooterTemplate>
                                    {{ this.getNumericFooterTotalValueFor(iColIndex, columnDef) | number }}
                                </ng-template>
                            </ng-container>
                        </ng-template>


                    </kendo-grid-column>
                </ng-container>

                <kendo-grid-command-column [width]="90">
                    <ng-template kendoGridCellTemplate let-isNew="isNew">
                        <button [disabled]="ModifyRowDisabled" class="command-btn" kendoGridEditCommand kendoGridFocusable><mat-icon class="row-action-icon" title="edit">edit</mat-icon></button>
                        <button [disabled]="ModifyRowDisabled" class="command-btn" kendoGridRemoveCommand kendoGridFocusable><mat-icon class="row-action-icon" title="delete">delete</mat-icon></button>
                        <!--TEAMS-838: KLW - Put in the attribute for validation -->
                        <button class="command-btn" kendoGridSaveCommand kendoGridFocusable [disabled]="this.validationFormGroup"><mat-icon class="row-action" title="Save Row">save</mat-icon></button>
                        <button class="command-btn" kendoGridCancelCommand kendoGridFocusable (click)="this.removeNewKendoRowFlag()"><mat-icon class="row-action" title="cancel">cancel</mat-icon></button>
                    </ng-template>
                </kendo-grid-command-column>

                <!-- Row Expansion to show detail pane -->
                <ng-template *ngIf="GridHasDetail()" kendoGridDetailTemplate let-dataItem>
                    <div #rowDetailPanel>
                        <ng-container *ngFor="let columnDef of this.GridColumnDefs; let iColIndex=index">
                            <ng-container *ngIf="ShowColumnInDetail(columnDef)">
                                <app-form-field-wrapper *ngIf="RowEditInProgress"
                                                        [formField]="columnDef"
                                                        [mode]="'instance'"
                                                        [readOnly]="false"
                                                        [(ngModel)]="this.DataUsedWithKendoGrid.selectedGridRow?.FormInstanceElementWrappers[iColIndex].formInstanceElement"
                                                        (onInit)="this.gridColumnInit($event, columnDef)"
                                                        (ngModelChange)="this.gridColumnValueChanged(columnDef,this.DataUsedWithKendoGrid.selectedGridRow?.FormInstanceElementWrappers[iColIndex].formInstanceElement)"
                                                        (formStatusChanged)="handleValidityChange($event)">
                                </app-form-field-wrapper>
                                <div *ngIf="!RowEditInProgress">
                                    <strong>{{columnDef.displayName}}</strong>: {{ getCellDisplayValueForKendo(dataItem, columnDef, iColIndex) }}
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>

                </ng-template>
            </kendo-grid>
        </ng-container>
        <!--
            <mat-icon aria-hidden="false" aria-label="Download grid data" class="export-grid-data-mat-icon" title="Download grid data" svgIcon="microsoft-excel"></mat-icon>
            <mat-icon aria-hidden="false" aria-label="Upload grid data from Excel file" class="import-grid-data-mat-icon" title="Upload grid data from Excel">upload_file</mat-icon>
        -->
        <ng-container *ngIf="this.HideFormFieldBody">
            <div class="export-import-buttons-only-div">
                <!-- Note:  this <div/> only shows a grid's export and import buttons. -->
                <button mat-button (click)="this.exportGridDataClicked()">
                    <img src="assets/images/v3_download.png" alt="Download to Excel">
                </button>
                <ng-container *ngIf="true">
                    <input type="file"
                           accept=".xlsx,.xls"
                           #selectExcelFile
                           class="hidden-file-input"
                           (change)="this.onExcelFileChanged($event)" />

                    <button class="grid-button" mat-button (click)="this.importGridDataClicked()">
                        <img src="assets/images/v3_upload.png" alt="Upload from Excel">
                    </button>
                </ng-container>
            </div>
        </ng-container>
        <div style="height:10px; font-height:10px;"><span *ngIf="Saving">Saving...</span></div>
    </div>
</ng-container>

<ng-container *ngIf="!this.SiteIsInBetaMode || isFootnotesGrid">
    <ng-container>
        <!-- The grid's toolbar:  -->
        <app-grid-form-field-toolbar [gridFormField]="this.GridFormFieldComponent"></app-grid-form-field-toolbar>
    </ng-container>
    <div class="mat-table-div">
        <mat-table class="mat-elevation-z4 half-page-height-mat-table"
                   [dataSource]="this.AllModesDataSource"
                   multiTemplateDataRows
                   matSort
                   (matSortChange)="this.userTriggeredColumnSort($event)">
            <!-- Define grid data columns. -->
            <ng-container *ngFor="let hshColumnDef of this.GridColumnDefs; let iColIndex=index"
                          matColumnDef="{{hshColumnDef.name}}"
                          [sticky]="this.ColumnIsSticky(hshColumnDef.name)">

                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <ng-container>
                        <p class="header-cell-p"
                           matTooltip="{{this.getColumnTooltip(iColIndex,hshColumnDef)}}"
                           [matTooltipPosition]="this.TooltipPosition">{{this.GridColumnDisplayName(hshColumnDef)}}</p>
                    </ng-container>
                </mat-header-cell>

                <mat-cell *matCellDef="let gridRow; "
                          [ngClass]="gridRow.isSelected ? 'selected-grid-row-background' : 'unselected-grid-row-background'"
                          style="{{this.getGridRowStyle(gridRow)}}">
                    <ng-container *ngIf="true">
                        <div *ngFor="let wrapper of this.getFormInstanceElementWrapper(hshColumnDef,gridRow); let iCol=index;"
                             class="instance-and-preview-form-field-mat-cell-div">
                            <span *ngIf="!gridRow.IsSelected"
                                  class="unselected-grid-row-cell-span"
                                  (click)="this.unselectedGridRowClicked(gridRow,wrapper)">
                                <!-- TEAMS-894: KLW - -->
                                <span *ngIf="!this.ShowColumnAsURLLink(hshColumnDef); else URL">
                                    <span *ngIf="wrapper.allowHtml()" [innerHTML]="wrapper.standinDisplayValue | safe: 'html'"></span>
                                    <span *ngIf="!wrapper.allowHtml()">{{wrapper.standinDisplayValue}}</span>
                                </span>
                                <ng-template #URL>
                                    <a class="html-display"
                                       href=""
                                       disabled
                                       (click)="this.openLink(wrapper.standinDisplayValue)">{{wrapper.standinDisplayValue}}</a>
                                </ng-template>
                                <!-- <span *ngIf="wrapper.allowHtml()" [innerHTML]="wrapper.standinDisplayValue | safe: 'html'"></span>
                                <span *ngIf="!wrapper.allowHtml()">{{wrapper.standinDisplayValue}}</span> -->
                            </span>
                            <!-- pharv - 4/12/2022 for VNEXT-181 -- adding validation messages in grids -->
                            <div class="form-field-div">
                                <div style="width: 100%;">

                                    <!--<ng-template dynamic-component-host name="t-{{gridRow.RowIndex}}-{{hshColumnDef.fieldOrder}}"></ng-template>-->
                                    <ng-template dynamic-component-host name="{{this.DynamicComponentHostNameFor(gridRow,hshColumnDef, iColIndex)}}"></ng-template>
                                </div>
                                <div>
                                    <ng-container *ngIf="gridRow.IsSelected">
                                        <mat-error *ngIf="this.getValidationErrorsForCell(gridRow.RowIndex, hshColumnDef.fieldOrder).length > 0" class="child-error-message">
                                            <mat-icon class="error-icon" [ngbPopover]="errorTemplate" popoverTitle="Errors" container="body" title="Click to view error details">error</mat-icon>
                                            <ng-template #errorTemplate>
                                                <ul class="error-list">
                                                    <ng-container *ngFor="let msg of this.getValidationErrorsForCell(gridRow.RowIndex, hshColumnDef.fieldOrder)">
                                                        <li>{{msg}}</li>
                                                    </ng-container>
                                                </ul>
                                            </ng-template>
                                        </mat-error>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </mat-cell>

                <!-- Define a footer cell for showing numeric column totals. -->
                <!--[ngClass]="iColIndex == 0 ? 'first-footer-cell' : 'footer-cell'"-->
                <mat-footer-cell *matFooterCellDef
                                 class="{{this.FooterCellClass(iColIndex)}}">
                    <span>{{this.getNumericTotalValue(iColIndex,hshColumnDef)}}</span>
                </mat-footer-cell>
            </ng-container>

            <!-- Define a grid actions column. -->
            <ng-container matColumnDef="Actions">
                <mat-header-cell *matHeaderCellDef class="actions-header noprint">
                    <p class="header-cell-p">Actions</p>
                </mat-header-cell>

                <mat-cell *matCellDef="let gridRow" class="actions-cell" style="{{this.getGridRowStyle(gridRow)}}">
                    <ng-container>
                        <button mat-stroked-button
                                class="remove-column-button noprint"
                                [disabled]="this.DeleteGridRowDisabled"
                                (click)="this.removeGridRowClicked(gridRow)"
                                style="margin-top: 2px;">
                            <mat-icon class="row-action" title="delete row">delete</mat-icon>
                        </button>

                        <button mat-stroked-button
                                *ngIf="gridRow.IsSelected"
                                class="remove-column-button noprint"
                                [disabled]="this.ReadOnly"
                                (click)="unSelectRow(gridRow)">
                            <mat-icon class="row-action" title="cancel">cancel</mat-icon>
                        </button>

                        <button mat-stroked-button
                                *ngIf="gridRow.IsSelected && this.SiteIsInAlphaMode"
                                class="remove-column-button noprint"
                                [disabled]="false"
                                (click)="toggleExpandGridRow(gridRow)">
                            <ng-container *ngIf="!this.elementIsExpanded(gridRow)">
                                <mat-icon class="row-action" title="expand">expand_more</mat-icon>
                            </ng-container>
                            <ng-container *ngIf="this.elementIsExpanded(gridRow)">
                                <mat-icon class="row-action" title="collapse">expand_less</mat-icon>
                            </ng-container>
                        </button>
                    </ng-container>
                </mat-cell>

                <!-- Display a 'Totals' label in the following footer cell. -->
                <mat-footer-cell *matFooterCellDef
                                 class="numeric-column-totals-footer-cell noprint">
                    <span class="numeric-column-totals-label">Totals</span>
                </mat-footer-cell>
            </ng-container>

            <!--
            <ng-container matColumnDef="expandedDetail">
                <mat-cell *matCellDef="let element"
                          [attr.colspan]="this.ExpandedDetailColSpan">
                    <div class="expanded-element-detail"
                         [@detailExpand]="this.elementIsExpanded(element) ? 'expanded': 'collapsed'">
                        <p>Expanded detail!</p>
                    </div>
                </mat-cell>
            </ng-container>
            -->
            <ng-container matColumnDef="expandedDetail">
                <mat-cell *matCellDef="let gridRow"
                          class="expanded-element-detail-cell"
                          [attr.colspan]="this.ExpandedDetailColSpan">
                    <div class="displayed-expanded-grid-row">
                        <div *ngFor="let columnDef of this.ExpandableGridColumnDefs; let iColIndex=index">
                            <span class="expanded-grid-column-name-span">{{columnDef.displayName != null ? columnDef.displayName : columnDef.name}}</span>
                            <div class="expanded-grid-column-div">
                                <div *ngFor="let wrapper of this.getFormInstanceElementWrapper(columnDef,gridRow); let iCol=index;"
                                     class="expanded-grid-column-wrapper-div">
                                    <app-form-field-wrapper [formField]="columnDef"
                                                            [mode]="this.Mode"
                                                            [readOnly]="this.ReadOnly"
                                                            [(ngModel)]="wrapper.formInstanceElement"
                                                            (ngModelChange)="this.handleNgModelChangeLogic(gridRow, columnDef, wrapper)">
                                    </app-form-field-wrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-cell>
            </ng-container>



            <!-- Define a paginator footer. -->
            <ng-container matColumnDef="MatPaginatorFooter">

                <!--colspan="{{this.InstanceModePaginatorColCount}}"-->
                <mat-footer-cell *matFooterCellDef
                                 class="mat-paginator-mat-footer-cell">
                    <div class="loading-progress-indicator-footer-div">
                        <!--<span *ngIf="this.IsLoadingGridData">Loading ...</span>-->
                        <ng-container *ngIf="this.IsLoadingGridData">
                            <h6 class="progress-title-h">{{this.LoadingDataText}}</h6>

                            <section class="loading-data-progress-section">
                                <mat-progress-bar class="loading-data-progress-bar"
                                                  [mode]="this.LoadDataProgressMode"
                                                  [value]="this.LoadDataProgressValue"
                                                  [bufferValue]="this.LoadDataProgressBufferValue"
                                                  [color]="this.LoadDataProgressColor">
                                </mat-progress-bar>
                            </section>
                        </ng-container>
                    </div>

                    <div class="spacer-footer-div"></div>

                    <div class="mat-paginator-footer-div">
                        <mat-paginator #matPaginatorMatFooter
                                       [length]="this.TotalRowCount"
                                       [pageSize]="this.PageSize"
                                       [pageSizeOptions]="this.PageSizeOptions"
                                       [disabled]="this.PaginatorDisabled"
                                       [showFirstLastButtons]="this.ShowFirstLastButtons"
                                       (page)="this.handlePageEvent($event)">
                        </mat-paginator>
                    </div>

                </mat-footer-cell>
            </ng-container>

            <!-- Define the grid's content. -->
            <!-- Define the grid's row header. -->
            <mat-header-row *matHeaderRowDef="this.GridColumnNamesWithActions; sticky: this.HeaderRowIsSticky"></mat-header-row>

            <!-- Define the grid's row data. -->
            <!--
            <mat-row *matRowDef="let row; columns: GridColumnNamesWithActions"
                     class="grid-row"
                     [class.expanded-grid-row]="this.elementIsExpanded(row)"
                     (click)="this.expandGridRow(row)">
            </mat-row>
                -->
            <mat-row *matRowDef="let row; columns: GridColumnNamesWithActions"
                     class="grid-row">
            </mat-row>
            <mat-row *matRowDef="let row; columns: ['expandedDetail']"
                     style="{{this.expandedDetailRowStyle(row)}}">

            </mat-row>

            <!-- Has FormField.showFooter been checked? -->
            <mat-footer-row *matFooterRowDef="this.GridColumnNamesWithActions; sticky: true"
                            [ngClass]="this.ShowNumericTotalsFooter ? 'shown-instance-mat-footer-row' : 'hidden-instance-mat-footer-row'"
                            style="background: lightgrey;"></mat-footer-row>
            <!-- MatPaginator footer. -->
            <mat-footer-row *matFooterRowDef="['MatPaginatorFooter']; sticky: this.ShowPaginator"
                            [ngClass]="this.ShowPaginator ? 'shown-mat-paginator-mat-footer-row' : 'hidden-mat-paginator-mat-footer-row'">

            </mat-footer-row>
        </mat-table>
    </div>
</ng-container>
