import { Type } from 'class-transformer';
import { FormControl } from '@angular/forms'; // Used for Reactive Forms

import { IViewModel } from '../../interfaces/view-model.interface';
import { FormLayout } from './form-layout.model';
import { FormField } from './form-field.model';
import { FormFieldExpression } from '../field-conditional-logic/form-field-expression.model';
import { Workflow } from '../site-content/workflow.model';
import { ICloneAndCopy } from '../../interfaces/clone-and-copy';
import { IHasIdAndName } from '../../interfaces/has-id-and-name.interface';
import { IListItem } from '../../interfaces/ilist-item.interface';
import { ItemTypeEnum } from '../../enums/item-type.enum';
import { IListItemBase } from '../ilist-item-base.model';
import { IconFormatEnum } from '../../enums/icon-format.enum';
import { ModelEventEnum, IModelEventHandler } from '../../interfaces/imodel-event-handler.interface';
import { LayoutRow } from './layout-row.model';
import { LayoutCell } from './layout-cell.model';
import { FormSettings } from './form-settings.model';

// Define a type-safe hash for storing
// form fields by one of their Ids values.
export interface IFormFieldIdToFormField {
    [clientId: number]: FormField;
}
// Define a type-safe set type.
export type ISetOfStrings = Set<string>;
// Define a type-safe hash type.
export interface IFieldNameValues {
    [fieldName: string]: string;
}

export class Form extends IListItemBase implements IViewModel, ICloneAndCopy, IHasIdAndName, IListItem {
    public id: number;
    public getId(): number { // This method is part of interface IHasIdAndName
        return (this.id);
    }

    public deletableField: string;

    public getOriginalVersionId(): number {
        return -1;
    }

    public dataCollectionId: number;
    //public folderId: number; // Disabled 04-24-2020 as forms no longer reside in folders.
    public workflowId: number; // Added 04-24-2020
    public readonly siteWorkflows: Workflow[];

    //VNEXT-297 KLW: Put in a property for the workflow name
    public workflowName: string;

    //public name: string;
    public getName(): string { // This method is part of interface IHasIdAndName
        return (this.name);
    }
    public setName(nameParam: string): void { // This method is part of interface IHasIdAndName
        this.name = nameParam;
    }

    public childCount: number;
    public childCountTitle: string;

    public static readonly TypeName: string = 'Form';
    public typeName(): string { // This method is part of interface IHasIdAndName
        return (Form.TypeName);
    }

    hasDescriptionField(): boolean {
        return true;
    }
    getDescription(): string {
        return this.description;
    }
    setDescription(val: string) {
        this.description = val;
    }

    // Define HasIdAndName interface methods that have no meaning for this class.
    public getChangeWorkflowStateDialogTitle(): string { return null; }
    public setChangeWorkflowStateDialogTitle(value: string): void { }

    public getTransitionConfirmationDialogMessage(): string { return null; }
    public setTransitionConfirmationDialogMessage(value: string): void { }

    public getTakeUserToSiteHomePageAfterTransitionApplied(): boolean { return false; }
    public setTakeUserToSiteHomePageAfterTransitionApplied(value: boolean): void { }

    public getPropertiesDrawerTitle(): string {
        return null;
    }
    // End HasIdAndName interface methods that have no meaning for this class.

    public description: string;

    public helpText: string;
    public helpTextFormat: string;

    public jsonConfig: string;

    public isDefaultForFolder: boolean;

    public currentUserCanBuildForms: boolean;
    // pjh - 2/5/2020 - quick fix to allow new forms to be built. 
    public get CurrentUserCanBuildForms(): boolean {
        return this.id == 0 || this.currentUserCanBuildForms;
    }

    // 02-24-2021 note:  added the following property.
    public enableCellSelection: boolean = true;
    public alwaysShowCellSelection: boolean = false;

    public hideSaveButton: boolean = false;
    public saveButtonCaption: string = '';
    public confirmSavePopup: boolean = false;

    // 04-19-2021 note:  added the following property.
    public wasDeletedFromUI: boolean;

    // 07-11-2024 note:  begin properties related to auto-numbering.
    public suggestAutonumberFormNames: boolean;
    public prefixFormNameWithFolderName: boolean;
    public suggestedFormNamePrefix: string; // e.g. 'Submission #' which would become 'Submission #1', 'Submission #2', and so on.
    public numTemplateFormInstancesInFolder: number;
    public transient_suggestedFormNamePrefix_formControl: FormControl;
    // 07-11-2024 note:  end properties related to auto-numbering.

    // Define, for now, a single, optional event handler for
    // this form(could be a list of zero or more in the future).
    public eventHandler: IModelEventHandler = null;
    public notifyEventHandler(event: ModelEventEnum): void {
        if (this.eventHandler) {
            this.eventHandler.eventCompleted(event, this);
        }
    }

    @Type(() => FormLayout)
    public formLayout: FormLayout;

    @Type(() => FormSettings)
    public formSettings: FormSettings;

    @Type(() => FormField)
    public formFields: FormField[] = [];

    @Type(() => FormField)
    public formFieldsDeleted: FormField[] = [];

    //@Type(() => FormConditionalLogicRule)
    //public conditionalLogicRules: FormConditionalLogicRule[] = [];
    @Type(() => FormFieldExpression)
    public conditionalLogicRules: FormFieldExpression[] = [];

    //history
    public createdBy: string;
    public createdByUserName: string;
    public createdDate: Date;
    public modifiedBy: string;
    public modifiedByUserName: string;
    public modifiedDate: Date;

    public formInstanceCount: number; //number of form instances which use this form template

    // Constructor.
    public constructor(formToCopy: Form = null) {
        super();

        this.formFieldsDeleted = [];

        if (formToCopy != null) {
            // Copy primitives properties.
            this.id = formToCopy.id;

            this.dataCollectionId = formToCopy.dataCollectionId;
            this.workflowId = formToCopy.workflowId;

            this.siteWorkflows = formToCopy.siteWorkflows;

            this.name = formToCopy.name;
            this.description = formToCopy.description;

            this.helpText = formToCopy.helpText;
            this.helpTextFormat = formToCopy.helpTextFormat;

            this.currentUserCanBuildForms = formToCopy.currentUserCanBuildForms;

            this.enableCellSelection = formToCopy.enableCellSelection;

            // 10-07-2022:  begin handling fields that were not being copied prior.
            // formLayout,createdBy,createdDate,modifiedBy,modifiedDate
            this.workflowName = formToCopy.workflowName;

            this.isDefaultForFolder = formToCopy.isDefaultForFolder;
            // 10-07-2022:  end handling fields that were not being copied prior.

            // Copy object properties.
            this.formLayout = null;
            if (formToCopy.formLayout != null) {
                let layoutCopy: FormLayout = <FormLayout>formToCopy.formLayout.clone();
                this.formLayout = layoutCopy;
            }

            this.formSettings = null;

            if (formToCopy.jsonConfig) {
                let fs: FormSettings = JSON.parse(formToCopy.jsonConfig);
                if (fs) {
                    this.formSettings = fs;
                    formToCopy.hideSaveButton = fs.hideSaveButton;
                    formToCopy.saveButtonCaption = fs.saveButtonCaption;
                    formToCopy.confirmSavePopup = fs.confirmSavePopup;
                }
            }

            this.formFields = [];
            if (formToCopy.formFields && (formToCopy.formFields.length > 0)) {
                for (let iFF: number = 0; iFF < formToCopy.formFields.length; iFF++) {
                    let formFieldToCopy: FormField = formToCopy.formFields[iFF];

                    let formFieldCopy: FormField = <FormField>formFieldToCopy.clone();
                    this.formFields.push(formFieldCopy);
                }
            }

            this.conditionalLogicRules = [];
            if (formToCopy.conditionalLogicRules && (formToCopy.conditionalLogicRules.length > 0)) {
                for (let iExpr: number = 0; iExpr < formToCopy.conditionalLogicRules.length; iExpr++) {
                    let exprToCopy: FormFieldExpression = formToCopy.conditionalLogicRules[iExpr];

                    let exprCopy: FormFieldExpression = <FormFieldExpression>exprToCopy.clone();
                    this.conditionalLogicRules.push(exprCopy);
                }
            }
        }

        return;
    }

    // Additional methods.
    public assignFrom(cloneObj: any): any {
        for (let attribut in cloneObj) {
            this[attribut] = cloneObj[attribut];
        }

        return this;
    }

    public getFormFieldsByIdHash(): IFormFieldIdToFormField {
        let hshFormFieldsByClientId: IFormFieldIdToFormField = {};

        if ((this.formFields) && (this.formFields.length > 0)) {
            for (let iField: number = 0; iField < this.formFields.length; iField++) {
                let formField: FormField = this.formFields[iField];

                if (formField.id == 0) {
                    let errorMsg: string =
                        `Form.getFormFieldsByIdHash():  encountered form field ` +
                        `'${formField.name}' with an id value of zero.`;
                    throw (errorMsg);
                }

                hshFormFieldsByClientId[formField.id] = formField;
            }
        }

        return (hshFormFieldsByClientId);
    }

    public findFormField(formFieldNumberOnClient: number): FormField {
        let result: FormField = Form.doFindFormField(this.formFields, formFieldNumberOnClient);

        return result;
    }

    private static doFindFormField(formFields: FormField[], formFieldNumberOnClient: number): FormField {
        let result: FormField = null;

        if (formFields != null) {
            for (let index: number = 0; index < formFields.length; index++) {
                let formField: FormField = formFields[index];

                if (formField.formFieldNumberOnClient == formFieldNumberOnClient) {
                    result = formField;
                    break;
                } else if (formField.childFormFields != null) {
                    result = Form.doFindFormField(formField.childFormFields, formFieldNumberOnClient);

                    if (result != null)
                        break;
                }
            }
        }

        return result;
    }

    // Implement ICloneAndCopy methods.
    public clone(): ICloneAndCopy {
        let formCopy: Form = new Form(this);

        return (formCopy);
    }

    public copy(objectToCopy: ICloneAndCopy): void {
        let formToCopy = <Form>objectToCopy;

        // Copy primitive properties.
        this.id = formToCopy.id;

        this.dataCollectionId = formToCopy.dataCollectionId;
        this.workflowId = formToCopy.workflowId;

        // TO DO:  find out why property 'siteWorkflows' has to be marked as read only.
        //this.siteWorkflows = formToCopy.siteWorkflows;

        this.name = formToCopy.name;
        this.description = formToCopy.description;

        this.helpText = formToCopy.helpText;
        this.helpTextFormat = formToCopy.helpTextFormat;

        this.currentUserCanBuildForms = formToCopy.currentUserCanBuildForms;

        this.enableCellSelection = formToCopy.enableCellSelection;

        // Copy object properties.
        this.formLayout = null;
        if (formToCopy.formLayout != null) {
            let layoutCopy: FormLayout = <FormLayout>formToCopy.formLayout.clone();

            this.formLayout = layoutCopy;
        }

        this.formSettings = null;
        if (formToCopy.formSettings != null) {
            let settingsCopy: FormSettings = <FormSettings>formToCopy.formSettings.clone();
            this.formSettings = settingsCopy;
        }

        this.formFields = [];
        if (formToCopy.formFields && (formToCopy.formFields.length > 0)) {
            for (let iFF: number = 0; iFF < formToCopy.formFields.length; iFF++) {
                let formFieldToCopy: FormField = formToCopy.formFields[iFF];

                let formFieldCopy: FormField = <FormField>formFieldToCopy.clone();
                this.formFields.push(formFieldCopy);
            }
        }

        this.conditionalLogicRules = [];
        if (formToCopy.conditionalLogicRules && (formToCopy.conditionalLogicRules.length > 0)) {
            for (let iExpr: number = 0; iExpr < formToCopy.conditionalLogicRules.length; iExpr++) {
                let exprToCopy: FormFieldExpression = formToCopy.conditionalLogicRules[iExpr];

                let exprCopy: FormFieldExpression = <FormFieldExpression>exprToCopy.clone();
                this.conditionalLogicRules.push(exprCopy);
            }
        }

        return;
    }

    // IListItem methods.
    public setId(idParam: number): void {
        this.id = idParam;

        return;
    }

    /*
    public getTitle(): string {
        return (this.name);
    }
    */

    public userCanDelete(): boolean {
        return (false); // Note:  delete permissions are based on the 'siteIsAdministerable'
        //        flag in model class DataCollecdtion.
    }

    public getStatus(): string {
        return ('na');
    }

    public getExtraInfo(): string {
        return "";
    }

    public getValue(property: string): string {
        return "";
    }

    public getType(): string {
        return ItemTypeEnum.FORM;
    }

    public getParentId(): number {
        return (0); // Note:  "parent" has no meaning for form templates.
    }

    public getPosition(): number {
        return (this.id); // id forms a default attribute for ordering 
    }

    public canBeDeleted(): boolean { // Note:  this will have to be fixed to use
        //        a value returned by the server.
        return (true);
    }

    public getIconType(): IconFormatEnum {
        return IconFormatEnum.MAT_ICON;
    }
    public getIcon(): string {
        return ('library_books'); // Returns the default icon for a site/data collection.
    }

    public getUniqueId(): string { // For interface IListItem
        let uniqueId: string = `${this.id}-${this.getType()}`;

        return (uniqueId);
    }

    public getChildCount(): number {
        return this.childCount;
    }

    public getModifiedBy(): string {
        return this.modifiedBy;
    }
    public getModifiedDate(): Date {
        return this.modifiedDate;
    }

    public update(obj: any, type: string, icon?: string, position?: number): void {
        // TO DO:  DISCUSS WITH PAUL.
        //
        // NOTE:  I BELIEVE THIS CAN BE A NOOP SINCE
        //        THE OBJECT WILL ALWAYS BE CONSISTENT
        //        WITH ITSELF/NO NEED TO UPDATE/SYNC.

        return;
    }

    public findAndUpdateFrom(items, obj: any): void {
        throw ('Form.findAndUpdateFrom():  this method is not implemented.');
    }

    // Added 09-27-2021:  methods related to form field uniqueness.
    public findDupFieldNames(formFieldsWithDupNames: FormField[] = null): ISetOfStrings {
        let error: boolean = false;

        let mapOfNameValues: IFieldNameValues = {};
        let setOfDupFieldNames: Set<string> = new Set();
        for (let iField: number = 0; iField < this.formFields.length; iField++) {
            let formField: FormField = this.formFields[iField];
            let existingFieldName: string = mapOfNameValues[formField.name];

            if (existingFieldName == undefined) {
                mapOfNameValues[formField.name] = formField.name;
            } else {
                error = true;
                //break;
                setOfDupFieldNames.add(existingFieldName);
                if (formFieldsWithDupNames != null) {
                    formFieldsWithDupNames.push(formField);
                }
            }
        }

        //return (error);
        return (setOfDupFieldNames.size > 0 ? setOfDupFieldNames : null)
    }

    public formFieldNameIsNotUnique(formFieldToTest: FormField): boolean {
        let bIsNotUnique: boolean = false;

        let mapOfNameValues: IFieldNameValues = {};
        let otherFormFields: FormField[] = this.formFields.filter(ff => ff != formFieldToTest);
        for (let iField: number = 0; iField < otherFormFields.length; iField++) {
            let otherFormField: FormField = this.formFields[iField];
            mapOfNameValues[otherFormField.name] = otherFormField.name;
        }

        if (mapOfNameValues[formFieldToTest.name] != null)
            bIsNotUnique = true;

        return bIsNotUnique;
    }
    public nameForFormFieldNotUnique(formFieldToTest: FormField, nameToTest: string): boolean {
        let bIsNotUnique: boolean = false;

        let mapOfNameValues: IFieldNameValues = {};
        let otherFormFields: FormField[] = this.formFields.filter(ff => ff != formFieldToTest);
        for (let iField: number = 0; iField < otherFormFields.length; iField++) {
            let otherFormField: FormField = this.formFields[iField];
            mapOfNameValues[otherFormField.name] = otherFormField.name;
        }

        if (mapOfNameValues[nameToTest] != null)
            bIsNotUnique = true;

        return bIsNotUnique;
    }

    // Begin methods related to Show To/Hide From functionality.
    public clearTransientProperties(): void {
        // Note:  for the time being, this method only calls method
        //        clearShowToHideFromTransientProperties(), below,
        //        but it acts as a placeholder for future, similar
        //        functionality.
        this.clearShowToHideFromTransientProperties();
    }
    public clearShowToHideFromTransientProperties(): void {
        if (this.formFields != null) {
            for (let index: number = 0; index < this.formFields.length; index++) {
                let formField: FormField = this.formFields[index];

                if (formField.transientMatchingBeginOrEndField != null) {
                    // Note:  the following two lines avoid a show to or hide from
                    // end field changing its display name during a form save.
                    if (formField.isConditionalEndElement)
                        formField.transientBeginFieldDisplayName =
                            (formField.transientMatchingBeginOrEndField.displayName != null ?
                                formField.transientMatchingBeginOrEndField.displayName :
                                formField.transientMatchingBeginOrEndField.name);

                    formField.transientMatchingBeginOrEndField = null;
                }
            }
        }
    }
    public restoreTransientProperties(): void {
        // Note:  for the time being, this method only calls method
        //        restoreShowToHideFromTransientProperties(), below,
        //        but it acts as a placeholder for future, similar
        //        functionality.
        this.restoreShowToHideFromTransientProperties();
    }
    public restoreShowToHideFromTransientProperties(): void {
        if (this.formFields != null) {
            let conditionalFieldNestingLevel = 0;

            for (let rowIndex: number = 0; rowIndex < this.formLayout.rows.length; rowIndex++) {
                let layoutRow: LayoutRow = this.formLayout.rows[rowIndex];
                for (let cellIndex: number = 0; cellIndex < layoutRow.cells.length; cellIndex++) {
                    let layoutCell: LayoutCell = layoutRow.cells[cellIndex];

                    if (layoutCell.hasContent) {
                        let formField: FormField = this.formFields.find(ff => ff.name == layoutCell.name);

                        if ((formField != null) && (formField.isConditionalBeginElement || formField.isConditionalEndElement)) {
                            // Temporarily associate the current name of the field's matching field.
                            let matchingFieldName: string = formField.matchingBeginOrEndFieldName;
                            formField.transientMatchingBeginOrEndField = this.formFields.find(ff => ff.name == matchingFieldName);
                            if (formField.transientMatchingBeginOrEndField == null)
                                console.log(`Form:  cannot find a matching field with name '${matchingFieldName}'.`);
                            formField.transientBeginFieldDisplayName = null;

                            // Update conditional nesting levels.
                            if (formField.isConditionalBeginElement) {
                                conditionalFieldNestingLevel++;
                                formField.transientConditionalNestingLevel = conditionalFieldNestingLevel;
                            } else {
                                formField.transientConditionalNestingLevel = conditionalFieldNestingLevel;
                                conditionalFieldNestingLevel--;
                            }

                            // Save an indication that the cell is occupied by a full width field
                            // (as all conditional fields occupy an entire row width).
                            //
                            // We could probably make this code cleaner by passing in an instance
                            // of class FieldDefinitionService so we would use FieldDefinition
                            // metadata properties ... something to consider.
                            layoutCell.transientCellOccupiedByFullRowWidthField = true;
                        }
                    }
                }
            }
        }
    }
    // End methods related to Show To/Hide From functionality.

    public removeAnyCircularDependencies(): void {
        if (this.formFields != null) {
            for (let index: number = 0; index < this.formFields.length; index++) {
                let formField: FormField = this.formFields[index];
                formField.removeAnyCircularDependencies();
            }
        }
    }

    // 07-26-2024 note:  begin methods related to suggesting auto-numbered form instance names.
    public hasFormInstanceAutoNumberConfig(): boolean {
        let hasAutoNumberConfig: boolean = (this.suggestAutonumberFormNames && (this.suggestedFormNamePrefix != null) && (this.suggestedFormNamePrefix.trim() != ''));

        return hasAutoNumberConfig;
    }
    public proposeAutoNumberName(numTemplateInstancesInCurrentFolder: number, folderName: string): string {
        let proposedName: string = ''

        if ((this.suggestAutonumberFormNames) && (this.suggestedFormNamePrefix != null) && (this.suggestedFormNamePrefix.trim() != '')) {
            if (this.prefixFormNameWithFolderName)
                proposedName = `${folderName} `;

            proposedName += this.suggestedFormNamePrefix;
            let nextFormNumberInFolder = numTemplateInstancesInCurrentFolder + 1;
            proposedName += nextFormNumberInFolder;
        }

        return proposedName;
    }

    // Note:  the following method need some additional work before it can be used.
    /*
    public fixDupFieldNames(setOfDupFieldNames: ISetOfStrings, formFieldsWithDupNames: FormField[]): void {
        // Create a set of field names, not worrying about dups yet.
        let setOfUniqueFieldNames: ISetOfStrings = new Set();
        for (let iFormField: number = 0; iFormField < formFieldsWithDupNames.length; iFormField++) {
            let formField: FormField = formFieldsWithDupNames[iFormField];

            if (!setOfDupFieldNames.has(formField.name)) {
                setOfDupFieldNames.add(formField.name);
            }
        }

        // Fix dup names.
        for (let iFormField: number = 0; iFormField < formFieldsWithDupNames.length; iFormField++) {
            let formField: FormField = formFieldsWithDupNames[iFormField];
            let dupFieldName: string = formField.name;

            let bDupFixed: boolean = false;
            let iFixIteration: number = 1;
            let fixFieldName: string = null;
            while (!bDupFixed) {
                fixFieldName = `${dupFieldName}${iFixIteration}`;

                if (!setOfDupFieldNames.has(fixFieldName)) {
                    setOfDupFieldNames.add(fixFieldName);
                    bDupFixed = true;
                }

                iFixIteration++;
            }

            formField.name = fixFieldName;
        }
    }
    */
}
