//TEAMS-835: KLW - Create the defninition logic for the HTML Link form field
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormField } from '../form-field.model';
import { FormInstanceElement } from '../form-instance-element.model';
import { TextFieldDefinitionLogicBase } from './text-logic-base';

export class HtmlLinkFieldDefLogic extends TextFieldDefinitionLogicBase {
    // Constructor.
    public constructor() {
        super();
    };

    public isURLLink(): boolean {
        return true;
    }

    //VNEXT-938: KLW - Use the getDisplayValue to create a URL link for when the HTML field is used on the site homepage.
    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[] = null): string {
        let url = formInstanceElementParam.textValue;
        //VNEXT-1096: KLW - URL field doesn't need this anymore
        // return `<a target="_blank" href="${url}">${url}</a>`;
        return url;
    }
}
