<mat-sidenav-container class="app-sidenav-container">
    <mat-sidenav *ngIf="this.ShowAppChrome"
                 #sidenav mode="side" opened class="{{AppSideNavClasses}}" role="navigation" id="navigation-menu"
                 (drop)="this.blockDrop($event)"
                 (dragover)="this.blockDrop($event)">
        <div mat-subheader (click)="toggleMenu()" tabindex="0" (keyup.enter)="toggleMenu()" class="menu-header {{siteWorkflowState}}">
            <span *ngIf="opened">{{ menuTitle() }} <span *ngIf="showStatusInTitle()">({{uppercaseSiteWorkflowState}})</span></span>
            <mat-icon *ngIf="opened" class="menu-close" title="Click to collapse this Menu bar">
                arrow_left
            </mat-icon>
            <mat-icon *ngIf="!opened" class="menu-open" title="{{menuTitle()}} (Click to expand)">
                menu
            </mat-icon>
        </div>
        <mat-divider></mat-divider>

        <mat-nav-list>
            <ng-container *ngFor="let item of menu.navItems">
                <mat-list-item *ngIf="item.text != 'Go Back' && userClaims.has(item.claim) && ShowHomePageMenuItem(item)"
                               [routerLink]="item.route"
                               routerLinkActive="active"
                               [routerLinkActiveOptions]="{exact: true}"
                               (keyup.enter)="navigateTo(item.route)"
                               title="{{item.text}}">
                    <mat-icon *ngIf="this.HasMatIcon(item)"
                              mat-list-icon>{{item.icon}}</mat-icon>
                    <mat-icon *ngIf="this.HasSvgIcon(item)"
                              svgIcon="{{item.icon}}"
                              mat-list-icon></mat-icon>

                    <span *ngIf="opened">{{item.text}}</span>
                </mat-list-item>

                <mat-list-item *ngIf="item.text == 'Go Back'"
                               (click)="navigateTo(item.route, true)"
                               (keyup.enter)="navigateTo(item.route, true)"
                               title="{{item.text}}"
                               tabindex="0">
                    <mat-icon *ngIf="this.HasMatIcon(item)"
                              mat-list-icon>{{item.icon}}</mat-icon>
                    <mat-icon *ngIf="this.HasSvgIcon(item)"
                              svgIcon="{{item.icon}}"
                              mat-list-icon></mat-icon>

                    <span *ngIf="opened">{{item.text}}</span>
                </mat-list-item>
            </ng-container>
        </mat-nav-list>

    </mat-sidenav>
    <mat-sidenav-content [ngClass]="this.ShowAppChrome ? AppSideNavContentClasses : 'app-sidenav-content-no-chrome'" role="main" id="main-content" tabindex="0">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
