import { AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { MatomoTracker } from 'ngx-matomo';
import { environment } from '../../../environments/environment';
import { CurrentUserService } from '../../security/current-user.service';
import { LogInOutComponent } from '../../security/loginout/loginout.component';
import { ScriptableBaseComponent } from '../../shared/components/scriptable-base/scriptable-base.component';
import { RouterLinkComponent, RouterLinkData } from '../../shared/components/wrappers/router-link/router-link.component';
import { ConfirmationDialogComponent, ConfirmationDialogModel } from '../../shared/dialogs/confirmation/confirmation-dialog.component';
import { VnextReleaseFeaturesDialog } from '../../shared/dialogs/vnext-release-features/vnext-release-features.dialog';
import { ConfirmationDialogEnums } from '../../shared/enums/confirmation-dialog.enum';
import { AppBuildInfo } from '../../shared/models/admin/app-build-info.model';
import { AdminService } from '../../shared/services/admin.service';
import { ClaimService } from '../../shared/services/claim.service';
import { VnextReleaseFeaturesService } from '../../shared/services/vnext-release-features.service';

declare let $: any; // jQuery


// Define a class used by the landing page component.
class LandingPageLinkData extends RouterLinkData {
    public constructor(relativeURL: string, title: string, public requiresSystemAdminFlag: boolean = false, public callbackMethod: string = null) {
        super();

        this.relativeURL = relativeURL;
        this.title = title;
    }

    public get hasCallbackMethod(): boolean {
        return this.callbackMethod != null;
    }
}

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']

    //providers: [{ provide: ParentComponentFinder, useExisting: forwardRef(() => ScriptableBaseComponent) }]
})
export class LandingPageComponent extends ScriptableBaseComponent implements OnInit, AfterContentInit, AfterViewInit {
    // Properties.
    private readonly landingPageLinkData: LandingPageLinkData[] =
        [
            new LandingPageLinkData('/home/recentlyAccessedSites', 'View My Recently Accessed Sites'),
            new LandingPageLinkData('/home/favoriteSites', 'View Favorite Sites'),
            new LandingPageLinkData('/home/sites', 'View All My Sites'),
            new LandingPageLinkData('/home/adminAllSites', 'All Sites (Admin View)', true)
        ];

    @ViewChildren(RouterLinkComponent, { read: RouterLinkComponent }) myRouterLinks: QueryList<RouterLinkComponent>;
    @Output() loginEmitter: EventEmitter<any> = new EventEmitter<any>();

    //VNEXT-1054: KLW - Changes for the interim homepage
    public appBuildInfo: AppBuildInfo;

    // Constructor.
    public constructor(
        public dialog: MatDialog,
        public currentUserService: CurrentUserService,
        public claimService: ClaimService,
        private vnextReleaseFeaturesService: VnextReleaseFeaturesService,
        private matomoTracker: MatomoTracker,
        elForBaseClass: ElementRef,
        private AdminAboutSvc: AdminService, //VNEXT-1054: KLW - Changes for the interim homepage
        private readonly titleService: Title
    ) {
        super(elForBaseClass);
        this.element('#landingPageLinksDiv').hasLinks();

        // PJH - VNEXT-591 - added isForcedLogout check to prevent call to
        // backend when the user has been logged out
        if (!this.currentUserService.isForcedLogout) {
            this.AdminAboutSvc.appBuildInfo.then(result => {
                this.appBuildInfo = result;
            });
        }

    }

    // Life cycle methods.
    public ngAfterContentInit(): void {
        if (this.currentUserService.isIdleLogout) {

            // PJH - 6/28/2024 - commenting out the dialog below as part of VNEXT-591
            // The immediate concern of VNEXT-591 is to send users back to the last page they were on when they login after being logged out
            // but I'm taking the opportunity to rationalize the whole logout thing as it was overly complicated and had different paths
            // of execution resulting in different screens. The goal now is to always go to the login page for any logout however it was triggered
            // The addition of the below dialog didn't add anything, since the user only sees it on the login screen, in which case it's obvious
            // they got logged out

            //let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            //    data: new ConfirmationDialogModel(`Session Expired`, `Your previous session timed out, please log back in`, { hideNo: true, showOk: true, dialogType: ConfirmationDialogEnums.ERROR })
            //});

            //dialogRef.disableClose = true;

            //dialogRef.afterClosed().subscribe(Ok => {
            //    if (Ok) {
            //        this.currentUserService.setForcedLogout(true);
            //        this.logInOutComponent.logOut();
            //    }
            //});
        }
    }

    public ngOnInit() {
        super.ngOnInit();

        this.matomoTracker.trackPageView(`Landing Page`);

        this.titleService.setTitle(environment.sitename);
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    // Define methods called by my HTML code.
    public get UserIsSystemAdmin(): boolean {
        let retVal: boolean = false;

        if (this.currentUserService.user)
            if (this.currentUserService.user.isSystemAdmin)
                retVal = true;

        return retVal;
    }

    public get LandingPageLinkData(): LandingPageLinkData[] {
        return this.landingPageLinkData;
    }

    //VNEXT-1054: KLW - Changes for the interim homepage
    public get AppBuildInfoApiBuildInfoBuildTime(): string {
        let retVal: string = '';

        if (this.appBuildInfo)
            if (this.appBuildInfo.apiBuildInfo)
                if (this.appBuildInfo.apiBuildInfo.buildTime)
                    retVal = this.appBuildInfo.apiBuildInfo.buildTime;

        return retVal;
    }

    public get AppBuildInfoApiBuildInfoChangeSet(): string {
        let retVal: string = '';

        if (this.appBuildInfo)
            if (this.appBuildInfo.apiBuildInfo)
                if (this.appBuildInfo.apiBuildInfo.changeSet)
                    retVal = this.appBuildInfo.apiBuildInfo.changeSet;

        return retVal;
    }

    public get AppBuildInfoApiBuildInfobuildNumber(): string {
        let retVal: string = '';

        if (this.appBuildInfo)
            if (this.appBuildInfo.apiBuildInfo)
                if (this.appBuildInfo.apiBuildInfo.buildNumber)
                    retVal = this.appBuildInfo.apiBuildInfo.buildNumber;

        return retVal;
    }

    public get AppBuildInfoWebBuildInfoBuildTime(): string {
        let retVal: string = '';

        if (this.appBuildInfo)
            if (this.appBuildInfo.webBuildInfo)
                if (this.appBuildInfo.webBuildInfo.buildTime)
                    retVal = this.appBuildInfo.webBuildInfo.buildTime;

        return retVal;
    }

    public get AppBuildInfoWebBuildInfoChangeSet(): string {
        let retVal: string = '';

        if (this.appBuildInfo)
            if (this.appBuildInfo.webBuildInfo)
                if (this.appBuildInfo.webBuildInfo.changeSet)
                    retVal = this.appBuildInfo.webBuildInfo.changeSet;

        return retVal;
    }

    public get AppBuildInfoWebBuildInfobuildNumber(): string {
        let retVal: string = '';

        if (this.appBuildInfo)
            if (this.appBuildInfo.webBuildInfo)
                if (this.appBuildInfo.webBuildInfo.buildNumber)
                    retVal = this.appBuildInfo.webBuildInfo.buildNumber;

        return retVal;
    }



    public IsNotLastLink(index: number): boolean {
        return (index < this.landingPageLinkData.length - 1);
    }

    //VNEXT-1054: KLW - Changes for the interim homepage
    public loginFromLandingPage() {
        this.loginEmitter.emit();
    }

    public emailHtml(subject: string, body: string): string {
        return `<a href="mailto:maxsupport@max.gov?subject=${environment.sitename} ${subject}&body=${body}">maxsupport@max.gov</a>`;

    }

    // Handle control events.
    public editNewComingSoonFeatures(): void {
        let dialogConfig: MatDialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = true;
        dialogConfig.width = '800px';
        dialogConfig.data = null; // No data yet.

        const dialogRef = this.dialog.open(VnextReleaseFeaturesDialog, dialogConfig);

        dialogRef.afterClosed().subscribe(updatedReleaseFeatures => {
            if (updatedReleaseFeatures != null) {

            }
        });
    }

    // Methods related to UI testing.
    public onLinkWithCallbackClick(linkData: LandingPageLinkData): void {
        this[linkData.callbackMethod]();
    }

    //VNEXT-1066: KLW - Property to get site name
    public get SiteName() {
        return environment.sitename;
    }

    /*
    public openUITestCenter(): void {
        let manager: UITestCenterDialogManager = new UITestCenterDialogManager();

        const dialogRef = manager.openDialogUsing(this.dialog);
    }
    */
}
