
export class TargetFieldConditionalLogicInstructions {
    public targetFieldId: number;
    public targetFieldName: string;
    public targetFieldDisplayName: string;

    public childFieldName: string; // Added 06-25-2024.
    //public applyChildFieldAttributes: boolean = false; // Added 08-06-2024.
    public applyChildFieldAttributes: boolean = true; // Added 08-06-2024.

    public showTargetField: boolean;
    public makeTargetFieldEditable: boolean;
    public makeTargetFieldRequired: boolean;

    public filterValue: string; // Added 06-25-2024.
    public filterOperator: string; // Added 06-25-2024.
}
