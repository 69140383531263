<div class="confirm-main">
    <h1 mat-dialog-title
        class="draggable-dialogue-header"
        cdkDrag
        cdkDragRootElement=".cdk-overlay-pane"
        cdkDragHandle>
        {{title}}
    </h1>

    <div>
        <mat-icon *ngIf="dialogOptions?.dialogType" class="confirm-icon">{{dialogOptions?.dialogType}}</mat-icon>
    </div>

    <div mat-dialog-content>
        <p>{{message}}</p>
        <span *ngIf="dialogOptions?.minutes || dialogOptions?.seconds">Time Remaining: {{dialogOptions?.minutes}} {{dialogOptions?.minutes != 1 ? 'Minutes' : 'Minute'}} {{dialogOptions?.seconds}} {{dialogOptions?.seconds != 1 ? 'Seconds' : 'Second'}}</span>
    </div>

    <div mat-dialog-actions class="confirm-buttons {{dialogOptions?.hideNo ? 'yes-no-buttons' : 'yes-button-only'}}">
        <button mat-raised-button color="primary" class="theme-primary-btn" (click)="onConfirm()">{{ConfirmButtonText}}</button>
        <button *ngIf="!dialogOptions?.hideNo" mat-button (click)="onDismiss()">{{DismissButtonText}}</button>
    </div>
</div>
