import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CollectApiServiceBase } from './collect-api-base.service';
//import { Form } from '../models/form-builder/form.model';
import { FormInstance } from '../models/site-content/form-instance.model';
import { FormInstanceHistory } from '../models/form-instance-history.model';
import { Diff } from '../models/diff.model';

//import { plainToClass } from "class-transformer";
//import { Logging } from '../logging';
import { ProgressIndicatorService, ProgressConfig } from './progress-indicator.service';
import { FormInstanceElementRequest } from '../models/form-instance-element-request.model';
import { FindByMechanism } from '../enums/find-by-mechanism.enum';
//import { FormInstanceElement } from '../models/form-builder/form-instance-element.model';
import { FormInstanceElementForHistory } from '../models/form-builder/form-instance-element-for-history.model';
//import { ListViewEnum } from '../components/list-view/list-view.helper';
import { ItemTypeEnum } from '../enums/item-type.enum';
import { AsyncJob } from '../models/async-job.model';

import { FormInstancesAndFoldersUsingWorkflows } from '../../shared/models/site-content/form-instance-using-workflowstate.model';
import { Logging } from '../logging';

@Injectable({
    providedIn: 'root'
})
export class FormInstanceService extends CollectApiServiceBase<FormInstance> {
    constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'formInstance', FormInstance)
    }

    public toggleIsFavorite(id: number): Promise<FormInstance> {
        let url = `${this.url}/${this.endpoint}/ToggleIsFavorite/${id}`;
        return this.http.put<FormInstance>(url, {}).toPromise().then(x => {
            return x;
        });
    }

    public updateFormInstanceElementVersionComment(item: FormInstanceElementForHistory) {
        let url = `${this.url}/${this.endpoint}/updateFormInstanceElementVersionComment`;
        return this.http.put<FormInstance>(url, item).toPromise().then(x => {
            return x;
        });
    }

    formatResponse(data: FormInstance): FormInstance {
        let obj: FormInstance = FormInstance.loadFromDeserializedNetworkData(data);

        return (obj);
    }

    public deleteAll(formInstanceIds: string) {
        let url = `${this.url}/${this.endpoint}/delete/${formInstanceIds}`;
        return this.http.delete(url).toPromise();
    }

    // pharv - 1/31/2023 - VNEXT-562 - need to pass the id of the form instance currently being viewed
    // to the server in case it's a FormINstane ref in which case its Id cannot be inferred
    public compareVersions(entity: string, formInstanceId: number, id1: number, entityOneIsVersion: boolean, id2: number, entityTwoIsVersion: boolean): Promise<Diff> {
        let path = entity == ItemTypeEnum.FORM_INSTANCE_ELEMENT_FOR_HISTORY ? `compareVersions/${formInstanceId}/elements` : `compareVersions/${formInstanceId}`;
        let url = `${this.url}/${this.endpoint}/${path}/${id1}/${entityOneIsVersion}/${id2}/${entityTwoIsVersion}`;
        return this.http.get<Diff>(url).toPromise();
    }

    // note that this doesn't respond to or handle an editing conflict itself,
    // but if someone is currently editing the form, they'll get a conflict.
    public restoreVersion(entity: string, id: number): Promise<FormInstance> {
        this.updateProgress(50, 75, `Restoring ...`);
        let path = entity == ItemTypeEnum.FORM_INSTANCE_ELEMENT_FOR_HISTORY ? 'restoreVersion/element' : 'restoreVersion';
        let url = `${this.url}/${this.endpoint}/${path}/${id}`;
        return this.http.put<FormInstance>(url, {}).toPromise().then(x => {

            this.updateProgress(100, 100, `Restored form instance`);
            return x;
        });
    }

    public getHistory(id: number): Promise<FormInstanceHistory> {
        let url = `${this.url}/${this.endpoint}/gethistory/${id}`;
        this.updateProgress(25, 75, 'Loading history...');
        let hist: FormInstanceHistory;
        return this.http.get<FormInstanceHistory>(url)
            .toPromise().then(res => {
                this.hideProgressIndicator();
                return Object.assign(new FormInstanceHistory(), res);
            });
    }

    public getFormInstanceElementHistory(id: number): Promise<FormInstanceElementForHistory[]> {
        let url = `${this.url}/${this.endpoint}/getFormInstanceElementHistory/${id}`;
        return this.http.get<FormInstanceElementForHistory[]>(url)
            .toPromise()
            .then(res => {
                let result: FormInstanceElementForHistory[] = [];
                for (let el of res) {
                    result.push(Object.assign(new FormInstanceElementForHistory(), el));
                }
                return result;
            })
            .catch(this.handleError);
    }

    public rename(item: FormInstance): Promise<FormInstance> {
        let url = `${this.url}/${this.endpoint}/rename/${item.id}`;

        this.updateProgress(50, 75, `Renaming form instance ...`);
        return this.http.put<FormInstance>(url, item)
            .toPromise()
            .then(response => {
                let obj = this.formatResponse(response);
                this.updateProgress(100, 100, `Renamed form instance`);
                return obj;
            })
            .catch(this.handleError);
    }

    public discardEditsAndReloadContent(id: number, messageWhileLoading: string = "Discarding Edits and Reloading Form..."): Promise<FormInstance> {
        let url = `${this.url}/${this.endpoint}/discardEdits/${id}`;

        this.updateProgress(50, 75, messageWhileLoading);
        return this.http.get<FormInstance>(url)
            .toPromise()
            .then(response => {
                let obj = this.formatResponse(response);
                this.updateProgress(100, 100, messageWhileLoading);
                return obj;
            })
            .catch(this.handleError);
    }

    // Returns a string of HTML representing the given FormInstance, which can then be embedded
    // in a page
    public getPrintableHtml(id: number, messageWhileLoading: string = "Loading Printable Version of Form"): Promise<any> {
        let url = `${this.url}/${this.endpoint}/printable/${id}`;
        return this.http.get(url, { responseType: 'text' }).toPromise().then(html => {
            return html;
        });
    }

    public getContent(id: number, isVersion: boolean = false, messageWhileLoading: string = "Loading Form..."): Promise<FormInstance> {
        let url = `${this.url}/${this.endpoint}`;
        if (isVersion) {
            url = `${url}/version/${id}`;
        } else {
            url = `${url}/getcontent/${id}`;
        }

        this.updateProgress(50, 75, messageWhileLoading);
        return this.http.get<FormInstance>(url)
            .toPromise()
            .then(response => {
                let obj = this.formatResponse(response);
                this.updateProgress(100, 100, messageWhileLoading);
                return obj;
            })
            .catch(this.handleError);
    }

    public updateContent(item: FormInstance): Promise<FormInstance> {
        let url = `${this.url}/${this.endpoint}/content/${item.id}`;
        this.updateProgress(50, 75, this.progressMsg('Updating'));

        // VNEXT-407 - attempting a fix by nulling out cascading dropdown json which, when large, causes a 413
        for (let ff of item.form.formFields) {
            ff.cascadingDropdownConstraintValue = null;
            ff.jsonConfig = null;
            for (let cff of ff.childFormFields) {
                cff.cascadingDropdownConstraintValue = null;
                cff.jsonConfig = null;
            }
        }
        let startTime = performance.now();
        return this.http.put<FormInstance>(url, item)
            .toPromise()
            .then(response => {
                let endTime = performance.now();
                console.log(`Update form instance content completed in ${endTime - startTime} milliseconds`);

                let obj = this.formatResponse(response);
                this.updateProgress(100, 100, this.progressMsg('Updated'));
                return obj;
            })
            .catch(this.handleErrorWithConflict);
    }


    private handleErrorWithConflict(error: Response | any): Promise<any> {
        Logging.log(error);
        if (this != null) {
            this.hideProgressIndicator();
        }
        if (error.status == 409) {
            // Editing Conflict
            // The caller is expecting the full error object, not just the message.
            // The error object contains the updated form instance, to be used
            // for conflict resolution.
            return Promise.reject(error);
        }
        else {
            return Promise.reject(error.message || error);
        }
    }

    public transitionWorkflowState(item: FormInstance): Promise<FormInstance> {
        let url = `${this.url}/${this.endpoint}/transition/${item.id}`;
        this.updateProgress(50, 75, 'Updating Workflow Action...');

        return this.http.put<FormInstance>(url, item)
            .toPromise()
            .then(response => {
                let obj = this.formatResponse(response);
                this.updateProgress(100, 100, 'Updated Workflow Action.');
                return obj;
            })
            .catch(this.handleErrorWithConflict);
    }

    public save(data: FormInstance): Promise<FormInstance> {
        //console.log(data.id);
        if (data.id > 0) {
            return (this.update(data));
        } else
            return (this.create(data));
    }

    public create(item: FormInstance): Promise<FormInstance> {
        let result = super.create(item);
        return result;
    }

    // Note:  though the following method is not presently being used, it will be in the future (so please do not delete it).
    public getFormInstanceElement(request: FormInstanceElementRequest): Promise<FormInstance> {
        let url: string =
            `${this.url}/${this.endpoint}/GetFormInstanceElement/` +
            `${request.formInstanceElementId}/For/${request.formInstanceId}/` +
            `Page/${request.pageIndex}/PageSize/${request.pageSize}`;

        return this.http.get<FormInstance>(url)
            .toPromise()
            .then(response => {
                let obj = this.formatResponse(response)
                return obj;
            })
            .catch(this.handleError);
    }

    public findFormFormInstances(formId: number): Promise<FormInstance[]> {
        let url = `${this.url}/${this.endpoint}/findFormInstances/${formId}`;

        this.updateProgress(50, 75, this.progressMsg('Updating'));

        return this.http.get<FormInstance[]>(url)
            .toPromise()
            .then(response => {
                let formInstances: FormInstance[] = [];
                if (response != null) {
                    for (let iFormInstance: number = 0; iFormInstance < response.length; iFormInstance++) {
                        let formInstance = this.formatResponse(response[iFormInstance]);
                        formInstances.push(formInstance);
                    }
                }

                this.updateProgress(100, 100, this.progressMsg('Updated'));
                return formInstances;
            })
            .catch(this.handleError);
    }

    public findBy(dataCollectionId: number, findByMechanism: FindByMechanism, findByValue: string): Promise<FormInstance> {
        this.updateProgress(50, 75, this.progressMsg('Finding'));

        let url = `${this.url}/${this.endpoint}/findBy/${dataCollectionId}/using/${findByMechanism}/value/${findByValue}`;

        return this.http.get<FormInstance>(url)
            .toPromise()
            .then(response => {
                let obj = this.formatResponse(response);

                this.updateProgress(100, 100, this.progressMsg('Found'));

                return obj;
            })
            .catch(this.handleError);
    }

    //VNEXT-372: KLW - Web service call to get forms and folders using a workflow state
    public getFormInstancesUsingWorkflowState(currentWorkflowStateId: number): Promise<FormInstancesAndFoldersUsingWorkflows> {
        this.updateProgress(50, 75, this.progressMsg('Finding Form and Folder Instances Using Workflow State'));

        let url = `${this.url}/${this.endpoint}/fiusingws/${currentWorkflowStateId}`;

        return this.http.get<FormInstancesAndFoldersUsingWorkflows>(url)
            .toPromise()
            .then(response => {
                this.updateProgress(100, 100, this.progressMsg('Done'));

                return response;
            })
            .catch(this.handleError);
    }

    public getFormInstancesUsingWorkflows(workflowIds: number[]): Promise<FormInstancesAndFoldersUsingWorkflows> {
        this.updateProgress(50, 75, this.progressMsg('Finding Form Instances and Folder Instances Using Workflow'));

        let url = `${this.url}/${this.endpoint}/fiusingworkflows`;

        return this.http.post<FormInstancesAndFoldersUsingWorkflows>(url, workflowIds)
            .toPromise()
            .then(response => {
                this.updateProgress(100, 100, this.progressMsg('Done'));

                return response;
            })
            .catch(this.handleError);
    }

    public getFormInstancesUsingForm(formId: number): Promise<number> {
        var url = `${this.url}/${this.endpoint}/fiusingform/${formId}`;
        return this.http.get<FormInstance[]>(url)
            .toPromise()
            .then(response => {
                return response;
            })
            .catch(this.handleError);
    }


    // 07-29-2022 note:  commenting out the following method that, while surely useful, is not presently being used.
    /*
    public copyAs(formId: number, copyName: string, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<FormInstance> {
        let msg = progressConfig.msgDuring || this.progressMsg('Copying');
        this.updateProgress(50, 75, msg);

        let url = `${this.url}/${this.endpoint}/copy/${formId}/as/${copyName}`;

        return this.http.get<FormInstance>(url)
            .toPromise()
            .then(response => {
                let obj = this.formatResponse(response);

                let msg = progressConfig.msgOnComplete || this.progressMsg('Copied');
                this.updateProgress(100, 100, msg);

                return obj;
            })
            .catch(this.handleError);
    }
    */

    // Protected methods.
    protected progressMsg(verb: string): string {
        return super.progressMsg(verb, 'form');
    }

    protected operationCompleted(operationName: string): void {
        // Notify any registered callbacks that the save operation has completed.
        this.notifyAnyCallbacksOperationCompleted(operationName);
    }
}
