import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { DropEvent } from 'ng-drag-drop';
import { Location } from '@angular/common';

import { IListItem } from '../../interfaces/ilist-item.interface';
import { ButtonConfig, IButtonConfig } from './list-view-button-config.model';
import { Logging } from '../../logging';
import { BrowserStorageService } from '../../services/browserstorage.service';
import { ListViewPerspective } from './list-view-perspective-config.model';
import {
    ListItemDragActionEnum,
    ListItemDragActionData
} from './list-view-drag-action.model';
import { TrashConfig, TrashDeleteEvent } from './list-view-trash-config.model';
import { GridViewComponent } from './grid-view/grid-view.component';
import { TileViewComponent } from './tile-view/tile-view.component';
import { FilesDroppedOnStructureEvent, IFilesDroppedOnStructure } from '../../../views/site/structure/structure.file-drop.event';
import { ListItemMoveData } from './list-view-item-move-action';
import { Folder } from '../../models/site-content/folder.model';
import { ListViewHelper } from './list-view.helper';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { UtilityHelper } from '../../utility.helper';
import { ItemTypeEnum } from '../../enums/item-type.enum';
import { DragDropEnum } from '../../enums/drag-drop.enum';
import { IListViewLiaison } from './ilist-view-liaison.interface';
import { StructureFieldConfig, ConfigureStructureFields } from '../../models/structure/configure-structure-fields.model';

@Component({
    selector: 'app-list-view',
    templateUrl: './list-view.component.html',
    styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit, OnChanges {
    @Input() list: IListItem[];

    @Input() title: string;
    @Input() listType: string;
    @Input() heading: string;
    @Input() selectedId: number;
    @Input() selectedType: string;
    @Input() selectedRolesId: number;
    @Input() selectedRolesType: string;
    @Input() buttonConfig: any;
    @Input() showGoUpOneLevel: boolean;
    @Input() showTreeButton: boolean = false;
    @Input() columnsConfig: string[];
    @Input() typeSortOrderConfig: string[];
    @Input() currentFolder: Folder;
    @Input() enableInfiniteScrolling: boolean = false; // this enables/disables infinite scrolling for a specific use of list-view
    //@Input() listResetAt: boolean;
    @Input() listResetAt: Date;
    @Input() throttleFilterChange: boolean = true;
    @Input() listItemsAreDraggable: boolean = false;

    @Input() totalListCount: number;

    @Input() hasCheckboxes: boolean = true;
    @Input() hideActionButtons: boolean = false;
    @Input() defaultSortColumn: string = 'position';

    @Input() maxSelection: number = 0; // zero means no limit
    @Input() viewOverride: string; // enables parent to force use of grid or tile only with no ability to switch
    @Input() renderNameAsLink: boolean = true;

    @Input() configureStructureFields: ConfigureStructureFields;

    @Input() listViewLiaison: IListViewLiaison = null; // Added 04-27-2023.

    @Output() editClick: EventEmitter<any> = new EventEmitter();
    @Output() onTree: EventEmitter<any> = new EventEmitter();
    @Output() buttonClick: EventEmitter<any> = new EventEmitter();
    @Output() itemActionClick: EventEmitter<any> = new EventEmitter();
    @Output() itemContextMenu: EventEmitter<any> = new EventEmitter();

    @Output() itemDeleted: EventEmitter<any> = new EventEmitter<any>();
    @Output() perspectiveChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() exceededMaxSelection: EventEmitter<any> = new EventEmitter<any>();

    // for vNEXT-104 -- to support infinite scrolling
    private observer: IntersectionObserver;

    @ViewChild('infiniteScrollSentinel') infiniteScrollSentinel: ElementRef;

    @Output() loadMore: EventEmitter<any> = new EventEmitter<any>();
    @Output() sortChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() filterTermChange: EventEmitter<any> = new EventEmitter<any>();

    @Output() itemMoved: EventEmitter<any> = new EventEmitter<any>();
    @Output() filesDropped: EventEmitter<FilesDroppedOnStructureEvent> = new EventEmitter<FilesDroppedOnStructureEvent>();

    @Output() dragged: EventEmitter<ListItemDragActionData> = new EventEmitter<ListItemDragActionData>();
    @Output() selectionsSet: EventEmitter<IListItem[]> = new EventEmitter<IListItem[]>();

    @ViewChild(GridViewComponent) gridViewComponent: ListViewComponent;
    @ViewChild(TileViewComponent) tileViewComponent: ListViewComponent;

    public filterTerm: string; // used to filter the list
    private listIsFiltered: boolean;
    private filterTermFormGroup: FormGroup = null;
    private listLengthWhenLoadMoreEmitted: number = -1;

    public view: string;
    sortDirection: string;

    private listViewPerspective: ListViewPerspective = null;
    private perspectiveName: string = null;

    private bShowTrashCan: boolean = false;
    private itemDragData: ListItemDragActionData = null;

    private trashConfig: TrashConfig = null;

    private allItemsLoaded: boolean = false;
    private loadMoreCounter: number = 0;
    private viewInitialized: boolean = false;

    //Kevin icon directive
    iconType = ItemTypeEnum.SVG_ICON;
    
    // Constructor.
    constructor(private location: Location,
        private browserStorageService: BrowserStorageService,
        private _formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        // If I have a liaison, let it now that this component is initializing.
        if (this.listViewLiaison != null)
            this.listViewLiaison.listViewInitializing(this);

        this.filterTermFormGroup = this._formBuilder.group({
            filterTerm: ['']
        });
        this.initializeFilterTermControl();

        this.initView(this.browserStorageService.get(this.viewCacheKey()));

        // 07-07-2021: re-added the following two code blocks which got overwritten by a commit
        if (this.buttonConfig && this.buttonConfig.perspectives) {
            this.listViewPerspective =
                <ListViewPerspective>this.buttonConfig.perspectives;
            let perspectives: string[] = this.listViewPerspective.PerspectiveNames;
            let iSelectedIndex: number = this.listViewPerspective.InitialPerspectiveIndex;

            this.perspectiveName = perspectives[iSelectedIndex]; // The initially selected perspective.
        }

        if (this.buttonConfig && this.buttonConfig.trash) {
            let trashConfig: TrashConfig = <TrashConfig>this.buttonConfig.trash;

            this.bShowTrashCan = trashConfig.CanDelete;
        }
    }

    private initializeFilterTermControl(): void {
        this.filterTermFormGroup.controls['filterTerm'].valueChanges.pipe(
            debounceTime(350),
        ).subscribe((val) => {
            this.filterTerm = val;
            this.listIsFiltered = !!this.filterTerm;
            if (this.parentNeedsToFilterOnServer()) {
                this.filterTermChange.emit(val);
            }
        });
    }

    // Only want to emit the event when there's need to hit the server in which case
    // the parent component needs to handle it. filterTerm needs to be empty (signally we need to fetch everything)
    // or > 2 characters so we don't execute too wide of a search 
    private parentNeedsToFilterOnServer() {
        let pageSizeCondition = this.totalListCount > ListViewHelper.PAGE_SIZE;
        let listLengthCondition = this.totalListCount > this.list.length;
        let filterTermCondition = (this.filterTerm.length == 0 || this.filterTerm.length > 2 || !this.throttleFilterChange);

        return pageSizeCondition && listLengthCondition && filterTermCondition;
    }

    // ADDED FOR VNEXT-104 - infinite scrolling
    public ngAfterViewInit() {
        this.viewInitialized = true;
        this.initializeInfiniteScroll();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.title && this.viewInitialized) {
            this.initializeInfiniteScroll();
        }
        else if (changes.listResetAt) {
            this.initializeInfiniteScroll();
        }

        if (this.list != null) {
            this.setSortDirection(this.browserStorageService.get(this.sortCacheKey())); }
    }

    initView(view: string): void {
        if (this.viewOverride) {
            this.view = this.viewOverride;
        } else {
            //TEAMS-827: KLW - Default the structure to Grid View instead of Tile View 
            if (!view) view = 'grid';
            let cacheKey = this.viewCacheKey();
            this.browserStorageService.set(cacheKey, view);
            this.view = view;
        }
    }

    setView(view: string): void {
        this.initView(view);
        this.viewChange.emit(view);
    }

    public handleSortChange(event: any) {
        if (this.doInfiniteScrolling()) {
            this.sortChange.emit(event);
        }
    }

    public doInfiniteScrolling(): boolean {
        let done = this.totalListCount == this.list.length;
        let ret =
            ListViewHelper.APP_WIDE_INFINITE_SCROLL_ENABLED
            && this.enableInfiniteScrolling
            && !done;
        return ret;
    }

    public get DisplaySearch(): boolean {
        return this.list != null && (this.list.length > 5 || this.totalListCount > 5);
    }

    // Methods accessed by my .html code.
    public IsMaterialIconWithDefaultStyle(btn: ButtonConfig): boolean {
        return (btn.icon && btn.iconFormat == 'MatIcon') &&
            (btn.iconDisplayStyle == 'Default') &&
            (btn.inlineStyles == null);
    }
    public IsMaterialIconWithCustomStyle(btn: ButtonConfig): boolean {
        return (btn.icon && btn.iconFormat == 'MatIcon') &&
            (btn.iconDisplayStyle != 'Default') &&
            (btn.inlineStyles == null);
    }
    public IsMaterialIconWithInlineStyle(btn: ButtonConfig): boolean {
        return (btn.icon && btn.iconFormat == 'MatIcon')
            && (btn.inlineStyles != null);
    }

    public IsSvgIconWithNoInlineStyles(btn: ButtonConfig): boolean {
        return (btn.icon != null) && (btn.iconFormat == 'SvgIcon') && (btn.inlineStyles == null);
    }

    public IsSvgIconWithInlineStyles(btn: ButtonConfig): boolean {
        return (btn.icon != null) && (btn.iconFormat == 'SvgIcon') && (btn.inlineStyles != null);
    }

    public get Perspective(): ListViewPerspective {
        return (this.listViewPerspective);
    }

    public get PerspectiveLabel(): string {
        let label: string =
            (this.listViewPerspective ? this.listViewPerspective.Label : '');

        return (label);
    }

    public get FilterTermFormGroup(): FormGroup {
        return this.filterTermFormGroup;
    }

    //TEAMS-424: KLW - Moved from ngOnInit to the logic of the property to account for ButtonConfig being null when ngOnInit is called
    public get PerspectiveName(): string {
        if (this.buttonConfig && this.buttonConfig.perspectives) {
            if (!this.listViewPerspective) {
                this.listViewPerspective =
                    <ListViewPerspective>this.buttonConfig.perspectives;
                let perspectives: string[] = this.listViewPerspective.PerspectiveNames;
                let iSelectedIndex: number = this.listViewPerspective.InitialPerspectiveIndex;

                this.perspectiveName = perspectives[iSelectedIndex]; // The initially selected perspective.
            }

            return this.perspectiveName;
        }
    }
    public set PerspectiveName(perspectiveNameParam: string) {
        this.perspectiveName = perspectiveNameParam;

        return;
    }

    public get PerspectiveNames(): string[] {
        let names: string[] =
            (this.listViewPerspective ? this.listViewPerspective.PerspectiveNames : []);

        return (names);
    }

    public get FilterTerm(): string {
        return (this.filterTerm);
    }
    public set FilterTerm(filterTermParam: string) {
        this.filterTerm = filterTermParam;
    }

    public get View(): string {
        return (this.view);
    }
    public set View(viewParam: string) {
        this.view = viewParam;
    }

    public get SortDirection(): string {
        return (this.sortDirection);
    }
    public set SortDirection(sortDirectionParam: string) {
        this.sortDirection = sortDirectionParam;
    }

    //TEAMS-424: KLW - Moved from ngOnInit to the logic of the property to account for ButtonConfig being null when ngOnInit is called
    public get HasTrashCan(): boolean {
        if (this.buttonConfig && this.buttonConfig.trash) {
            if (!this.trashConfig) {
                this.trashConfig = <TrashConfig>this.buttonConfig.trash;
                this.bShowTrashCan = this.trashConfig.CanDelete;
            }
        }

        return this.bShowTrashCan;
    }

    public get TrashCanTooltip(): string {
        let tooltip: string = "Drag an item here to delete it";

        return (tooltip);
    }

    public get TooltipPosition(): TooltipPosition {
        let position: TooltipPosition = 'below';

        return (position);
    }

    public get ItemIsBeingDragged(): boolean {
        return (this.itemDragData !== null);
    }

    //TEAMS-424: KLW - Have the MatTable source be a property so it can be updated easily
    public get ListItems(): IListItem[] {
        return this.list;
    }

    public get GetSortDirection(): string {
        return this.sortDirection;
    }

    public getMatIconCssClasses(sourceCssClass: string): string {
        return `${sourceCssClass} ${sourceCssClass}-enhancements`;
    }

    // Handle drag events.
    public onDragAction(event: ListItemDragActionData): void {
        if (event.dragAction === ListItemDragActionEnum.DRAG_START) {
            this.itemDragData = event;
        } else {
            this.itemDragData = null;
        }

        this.dragged.emit(event);

        return;
    }

    public onItemContextMenu(event: any): void {
        this.itemContextMenu.emit(event);

        return;
    }

    public setSelections(items: IListItem[]) {
        this.selectionsSet.emit(items);

        return;
    }

    public onItemMoved(event: ListItemMoveData): void {
        this.itemMoved.emit(event)

        return;
    }

    public onFileDropped(event: IFilesDroppedOnStructure): void {
        let toEmit: FilesDroppedOnStructureEvent = new FilesDroppedOnStructureEvent(event.Files);
        toEmit.setToDropFolderId(event.FolderId);
        toEmit.setObjectType(DragDropEnum.FORM);
        toEmit.setReload(event.Reload);

        this.filesDropped.emit(toEmit);

        return;
    }

    //TEAMS-424: KLW - Pass in the selection of items to be handled if they exist
    public onItemDroppedOnTrash(event: DropEvent): void {
        // Allow my parent to handle this.
        let eventData: TrashDeleteEvent = null;

        eventData = new TrashDeleteEvent(event, this.itemDragData.items);

        this.itemDeleted.emit(eventData);

        return;
    }

    //TEAMS-424: KLW - Have a way to update the MatTable source
    public updateTableSource(passedList: any): void {

        this.list = passedList;

        if (this.view == 'tile') {
            this.tileViewComponent.updateTableSource(passedList);
        }
        else if (this.view == 'grid') {
            this.gridViewComponent.updateTableSource(passedList);
        }
    }

    // Handle control events.
    public perspectiveNameChanged(): void {
        // Output a perspective changed event.
        this.perspectiveChanged.emit(this.perspectiveName);

        return;
    }

    // This sets up initial sort order for both TileView and GridView
    // From that point, GridView handles its own sorting using MatTable
    setSortDirection(direction: string): void {
        if (!direction) direction = 'none';
        this.browserStorageService.set(this.sortCacheKey(), direction);
        this.sortDirection = direction;
    }

    emitButtonClick(event: any, buttonConfig: IButtonConfig) {
        this.buttonClick.emit({ originalEvent: event, button: buttonConfig.value, buttonConfig: buttonConfig });
    }

    emitOnTree(entity) {
        this.onTree.emit(entity);
    }

    private viewCacheKey(): string {
        return `${this.listType}_listView`;
    }

    private sortCacheKey(): string {
        return `${this.listType}_sort`;
    }

    private initializeInfiniteScroll() {
        if (this.enableInfiniteScrolling) {
            // See https://www.smashingmagazine.com/2018/01/deferring-lazy-loading-intersection-observer-api/
            // I did a lot of experimenting with config options in trying to make infinite scrolling work across different resolutions
            const options = {
                root: null, // root will be viewport
                rootMargin: '0px 0px 0px 0px',
                threshold: [0.1, 1] // trigger intersection event when sentinel is 10% or 100% intersecting the viewport (may need to play with these values)
            };
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && this.okToEmitLoadMore()) {
                        this.listLengthWhenLoadMoreEmitted = this.list?.length;
                        this.loadMore.emit(entry);
                    }
                });
            }, options);
            if (this.infiniteScrollSentinel) {
                // I don't know why it's necessary to wait until the stack is clear, but without this, reinitialization of the observer
                // would sometimes have no effect, especially when the parent of the ListView instance was the Structure screen
                UtilityHelper.runWhenStackClear(() => {
                    this.observer.observe(this.infiniteScrollSentinel.nativeElement);
                });
            }
        }
    }

    public forceLoadMore() {
        this.loadMore.emit();
    }

    private okToEmitLoadMore() {
        // The IntersectionObserver governing infinite scrolling can fire multiple times and we want to ensure that
        // we only proess an intersections once, hence the check on loadMoreEmittedFor
        if (this.listLengthWhenLoadMoreEmitted != this.list?.length) {
            return !this.listIsFiltered && this.totalListCount > this.list?.length;
        }
    }

    public get DisplayInfiniteScrollSentinel() {
        return !this.listIsFiltered && this.totalListCount > this.list?.length;
    }
}
