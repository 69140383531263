import { Type as AngularCoreType } from '@angular/core';

import { Injectable, Renderer2 } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { plainToClass } from 'class-transformer';

import { environment } from '../../../environments/environment';
import { CollectApiServiceBase } from './collect-api-base.service';
import { FormFieldBaseComponent } from '../components/form-fields/form-field-base/form-field-base.component';
import { IViewModel } from '../interfaces/view-model.interface';
import {
    ShortTextFieldType,
    ParagraphFieldType,
    RichTextFieldType,
    DateFieldType,
    CheckboxFieldType,
    RadioButtonsFieldType,
    IntegerFieldType,
    DecimalFieldType,
    CurrencyFieldType,
    LabelFieldType,
    DropDownFieldType,
    CascadingDropDownFieldType,
    AttachmentFieldType,
    RawHTMLFieldType,
    InlineContentFieldType,
    FormulaFieldType,
    GridCascadingDropDownFieldType,
    MultiCheckboxFieldType,
    MultiDropDownFieldType,
    GridFieldType,
    InstructionsFieldType,
    FootnoteFieldType,
    ShowToFieldType,
    HideFromFieldType,
    ContactsFieldType,
    CommentsFieldType,
    DevOnlyFieldType,
    HTMLLinkFieldType,
    FlexibleSelectionFieldType,
    FormTemplateInstancesFieldType
} from '../models/form-builder/form-field-types';
// Note:  import primitive form field classes.
import { ShortTextFormFieldComponent } from '../components/form-fields/short-text-form-field/short-text-form-field.component';
import { ParagraphFormFieldComponent } from '../components/form-fields/paragraph-form-field/paragraph-form-field.component';
import { CheckboxFormFieldComponent } from '../components/form-fields/checkbox-form-field/checkbox-form-field.component';
import { RadioButtonsFormFieldComponent } from '../components/form-fields/radio-buttons-form-field/radio-buttons-form-field.component';
import { DateFormFieldComponent } from '../components/form-fields/date-form-field/date-form-field.component';
import { IntegerFormFieldComponent } from '../components/form-fields/integer-form-field/integer-form-field.component';
import { DecimalFormFieldComponent } from '../components/form-fields/decimal-form-field/decimal-form-field.component';
import { CurrencyFormFieldComponent } from '../components/form-fields/currency-form-field/currency-form-field.component';
import { LabelFormFieldComponent } from '../components/form-fields/label-form-field/label-form-field.component';
import { DropDownFormFieldComponent } from '../components/form-fields/drop-down-form-field/drop-down-form-field.component';
import { CascadingDropDownFormFieldComponent } from '../components/form-fields/cascading-drop-down-form-field/cascading-drop-down-form-field.component';
import { GridCascadingDropDownFormFieldComponent } from '../components/form-fields/grid-cascading-dropdown/grid-cascading-dropdown.component';
import { AttachmentFormFieldComponent } from '../components/form-fields/attachment-form-field/attachment-form-field.component';
import { FormulaFormFieldComponent } from '../components/form-fields/formula-form-field/formula-form-field.component';
import { RawHtmlFormFieldComponent } from '../components/form-fields/raw-html-form-field/raw-html-form-field.component';
import { RichTextFormFieldComponent } from '../components/form-fields/rich-text-form-field/rich-text-form-field.component';
//import { DevOnlyFormFieldComponent } from '../dev-only-form-field/dev-only-form-field.component';
import { MultiCheckboxFormFieldComponent } from '../components/form-fields/multi-checkbox-form-field/multi-checkbox-form-field.component';
import { MultiDropDownFormFieldComponent } from '../components/form-fields/multi-drop-down-form-field/multi-drop-down-form-field.component';
import { InlineContentFormFieldComponent } from '../components/form-fields/inline-content-form-field/inline-content-form-field.component';
import { GridFormFieldComponent } from '../components/form-fields/grid-form-field/grid-form-field.component';
import { InstructionsFormFieldComponent } from '../components/form-fields/instructions-form-field/instructions-form-field.component';
import { FootnoteFormFieldComponent } from '../components/form-fields/footnote-form-field/footnote-form-field.component';
import { ContactsFormFieldComponent } from '../components/form-fields/contacts-form-field/contacts-form-field.component';
import { CommentsFormFieldComponent } from '../components/form-fields/comments-form-field/comments-form-field.component';
import { ShowToHideFromComponent } from '../components/form-fields/show-to-hide-from/show-to-hide-from.component';
import { AttachmentService } from './attachment.service';
import { CurrentUserService } from '../../security/current-user.service';
import { FieldDefinition } from '../models/form-builder/field-definition.model';
//import { IFieldDefinitionLogic } from '../interfaces/ifield-definition-logic.interface';
import { FieldDefinitionService } from './field-definition.service';
import { IconFormatEnum } from '../enums/icon-format.enum';
import { HtmlLinkFieldComponent } from '../components/form-fields/html-link-field/html-link-field.component';
import { FlexibleSelectionFormFieldComponent } from '../components/form-fields/flexible-selection-form-field/flexible-selection-form-field.component';
import { FormFieldService } from './form-field.service';
import { FlexibleSelectionFieldService } from './flexible-selection-field.service';
import { CurrentSiteService } from './current-site.service';

export class FieldTypeAndName implements IViewModel {
    public id: number = -1;

    public fieldType: string;
    public fieldName: string;

    public IconName: string;
    public iconType: IconFormatEnum;

    public canAddToNewGrids: boolean;

    public componentRepresentative: FormFieldBaseComponent; // One instance of the component to represent others.
    public formFieldClass: AngularCoreType<any>;

    // 05-16-2023 note:  we want to replace the use of property 'componentRepresentative' with uses of new property 'fieldDefinition'.
    public fieldDefinition: FieldDefinition; 
}

export interface IFieldTypeToFieldInfo {
    [fieldType: string]: FieldTypeAndName;
}

// Implement this file's service class.
@Injectable({
    providedIn: 'root'
})
export class FormFieldTypeAndNameService extends CollectApiServiceBase<FieldTypeAndName> {
    // Properties.
    private readonly arrFieldTypesAndNames: FieldTypeAndName[] =
        [
            {
                fieldType: ShortTextFieldType,
                fieldName: 'Short Text',

                IconName: 'short_text',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: true,

                componentRepresentative: null,
                formFieldClass: ShortTextFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: ParagraphFieldType,
                fieldName: 'Paragraph',

                IconName: 'subject',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: true,
                componentRepresentative: null,
                formFieldClass: ParagraphFormFieldComponent,

                fieldDefinition: null,

                id: -1
            },
            {
                fieldType: RichTextFieldType,
                fieldName: 'Rich Text',

                IconName: 'font_download',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: false,

                componentRepresentative: new RichTextFormFieldComponent(),
                formFieldClass: RichTextFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: DateFieldType, 
                fieldName: 'Date',

                IconName: 'calendar_today',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: true,

                componentRepresentative: null,
                formFieldClass: DateFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: CheckboxFieldType, 
                fieldName: 'Check Box',

                IconName: 'check_box',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: true,

                componentRepresentative: null,
                formFieldClass: CheckboxFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: RadioButtonsFieldType, 
                fieldName: 'Radio Buttons',

                IconName: 'radio_button_checked',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: true,

                componentRepresentative: null,
                formFieldClass: RadioButtonsFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: IntegerFieldType, 
                fieldName: 'Integer',

                IconName: 'looks_one',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: true,

                componentRepresentative: null,
                formFieldClass: IntegerFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: DecimalFieldType, 
                fieldName: 'Decimal',

                IconName: 'material-decimal-increase-24x24',
                iconType: IconFormatEnum.SVG_ICON,

                canAddToNewGrids: true,

                componentRepresentative: null,
                formFieldClass: DecimalFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: CurrencyFieldType,
                fieldName: 'Currency',

                IconName: 'attach_money',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: true,

                componentRepresentative: null,
                formFieldClass: CurrencyFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: LabelFieldType, 
                fieldName: 'Label',

                IconName: 'font_download',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: true,

                componentRepresentative: null,
                formFieldClass: LabelFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: DropDownFieldType, 
                fieldName: 'Drop-down',

                IconName: 'arrow_drop_down_circle',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: true,

                componentRepresentative: null,
                formFieldClass: DropDownFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: CascadingDropDownFieldType, 
                fieldName: 'Cascading Drop-down',

                IconName: 'dynamic_feed',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: false,

                componentRepresentative: null,
                formFieldClass: CascadingDropDownFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: GridCascadingDropDownFieldType,
                fieldName: 'Grid Cascading Drop-down',

                IconName: 'dynamic_feed',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: true,

                componentRepresentative: null,
                formFieldClass: GridCascadingDropDownFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: FlexibleSelectionFieldType,
                fieldName: 'Flexible Selection Field',

                IconName: 'arrow_drop_down_circle',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: true,

                componentRepresentative: null,
                formFieldClass: FlexibleSelectionFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: FormulaFieldType, 
                fieldName: 'Formula',

                IconName: 'functions',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: true,

                componentRepresentative: null,
                formFieldClass: FormulaFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: MultiCheckboxFieldType, 
                fieldName: 'Multi-select Check Box',

                IconName: 'check_box', // Need to change this?
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: true,

                componentRepresentative: null,
                formFieldClass: MultiCheckboxFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: MultiDropDownFieldType, 
                fieldName: 'Multi-select Drop-down',

                IconName: 'arrow_drop_down_circle',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: true,

                componentRepresentative: null,
                formFieldClass: MultiDropDownFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: HTMLLinkFieldType, 
                fieldName: 'HTML Link',

                IconName: 'link',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: true,

                componentRepresentative: null,
                formFieldClass: HtmlLinkFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },
            {
                fieldType: RawHTMLFieldType,
                fieldName: 'HTML',

                IconName: 'code',
                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: false,

                componentRepresentative: null,
                formFieldClass: RawHtmlFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            },

            // NOTE:  need to test adding an attachment to a grid, if this is a requirement.
            //KLW - Adding attachment type so the form field loads correctly 
            {
                fieldType: AttachmentFieldType, 
                fieldName: 'Attachment',
                IconName: 'attach_file',

                iconType: IconFormatEnum.MAT_ICON,

                canAddToNewGrids: false,

                componentRepresentative: null,
                formFieldClass: AttachmentFormFieldComponent,

                fieldDefinition: null,

                id: -1 // NA
            }
            
        ];
    private hshFieldTypeToFieldInfo: IFieldTypeToFieldInfo = {};

    private mapFormFieldTypesToClasses = {};

    // Constructor.
    public constructor(http: HttpClient,
        private currentUserService: CurrentUserService,
        private currentSiteService: CurrentSiteService,
        private fieldDefinitionService: FieldDefinitionService,
        private formFieldService: FormFieldService
        //,
        //private flexibleSelectionService: FlexibleSelectionFieldService
    )
    {
        super(http, null, environment.apiUrl, 'gridFieldTypeAndName', FieldTypeAndName);

        this.initFormFieldTypesToClassNames();
        //this.initializeFieldTypesAndNames(this.renderer, dateAdapter, attachmentService, dialog);
    }

    public formatResponse(data: FieldTypeAndName): FieldTypeAndName {
        let obj = plainToClass(FieldTypeAndName, data);

        return (obj);
    }

    // Intialize method.
    // Note:  when we remove property 'componentRepresentative', we will be able to remove all of the parameters to the following method.
    public initializeFieldTypesAndNames(renderer: Renderer2, dateAdapter: DateAdapter<Date>, attachmentService: AttachmentService, dialog: MatDialog): void {
        let keys: string[] = Object.keys(this.hshFieldTypeToFieldInfo);
        if (keys.length == 0)
            this.loadFieldTypesAndNames(renderer, dateAdapter, attachmentService, dialog);
    }

    // getFieldTypeAndName() method.
    public getFieldTypeAndField(fieldDefinitionClassName): FieldTypeAndName {
        let fieldTypeAndName: FieldTypeAndName = this.hshFieldTypeToFieldInfo[fieldDefinitionClassName];

        if (fieldTypeAndName == null) {
            let errorMsg = `FormFieldTypeAndNameService.getFieldTypeAndName():  cannot find a value for field definition class '${fieldDefinitionClassName}'.`;
            throw errorMsg;
        }

        fieldTypeAndName.fieldDefinition = this.fieldDefinitionService.getFieldDefinition(fieldTypeAndName.fieldType);

        return fieldTypeAndName;
    }

    public getAllFieldTypesAndNameFields(): FieldTypeAndName[] {
        for (let index: number = 0; index < this.arrFieldTypesAndNames.length; index++) {
            let fieldTypeAndName: FieldTypeAndName = this.arrFieldTypesAndNames[index];
            fieldTypeAndName.fieldDefinition = this.fieldDefinitionService.getFieldDefinition(fieldTypeAndName.fieldType);
        }

        return this.arrFieldTypesAndNames;
    }
    public getAllGridFieldTypesAndNameFields(): FieldTypeAndName[] {
        let result: FieldTypeAndName[] = this.arrFieldTypesAndNames.filter(ft => ft.canAddToNewGrids);
        for (let index: number = 0; index < result.length; index++) {
            let fieldTypeAndName: FieldTypeAndName = result[index];
            fieldTypeAndName.fieldDefinition = this.fieldDefinitionService.getFieldDefinition(fieldTypeAndName.fieldType);
        }
        return result;
    }

    public getFormFieldClassNamesFrom(fieldDefinitionClassName: string): string {
        return this.mapFormFieldTypesToClasses[fieldDefinitionClassName];
    }

    public getFieldNameFromFieldType(fieldDefinitionClassName: string): string { 

        let retVal: string = '';

        let found = this.arrFieldTypesAndNames.find(ft => ft.fieldDefinition.fieldDefinitionClassName == fieldDefinitionClassName);

        if (found) {
            retVal = found.fieldName;
        }
        else{
            console.log("Kevin " + fieldDefinitionClassName);
        }

        return retVal;
    }

    // Helper methods.
    private loadFieldTypesAndNames(renderer: Renderer2,
        dateAdapter: DateAdapter<Date>,
        attachmentService: AttachmentService,
        dialog: MatDialog): void {
        for (let iCompRep: number = 0; iCompRep < this.arrFieldTypesAndNames.length; iCompRep++) {
            let fieldTypeAndName: FieldTypeAndName = this.arrFieldTypesAndNames[iCompRep];

            switch (fieldTypeAndName.fieldType) {
                case ShortTextFieldType:
                    fieldTypeAndName.componentRepresentative = new ShortTextFormFieldComponent(renderer);                    
                    break;
                // Note:  Per VNEXT-193:  do not allow the user to add a paragraph field to a grid.
                // 03-01-2023 note:  enabling the paragraph form field again.
                case ParagraphFieldType:
                    fieldTypeAndName.componentRepresentative = new ParagraphFormFieldComponent(renderer);
                    break;
                case RichTextFieldType:
                    break;
                case DateFieldType:
                    fieldTypeAndName.componentRepresentative = new DateFormFieldComponent(renderer, dateAdapter);
                    break;
                case CheckboxFieldType:
                    fieldTypeAndName.componentRepresentative = new CheckboxFormFieldComponent(renderer);
                    break;
                case RadioButtonsFieldType:
                    fieldTypeAndName.componentRepresentative = new RadioButtonsFormFieldComponent(renderer);
                    break;
                case IntegerFieldType:
                    fieldTypeAndName.componentRepresentative = new IntegerFormFieldComponent(renderer);
                    break;
                case DecimalFieldType:
                    fieldTypeAndName.componentRepresentative = new DecimalFormFieldComponent(renderer);
                    break;
                case CurrencyFieldType:
                    fieldTypeAndName.componentRepresentative = new CurrencyFormFieldComponent(renderer);
                    break;
                case LabelFieldType:
                    fieldTypeAndName.componentRepresentative = new LabelFormFieldComponent(renderer);
                    break;
                case DropDownFieldType:
                    fieldTypeAndName.componentRepresentative = new DropDownFormFieldComponent(renderer);
                    break;
                case CascadingDropDownFieldType:
                    fieldTypeAndName.componentRepresentative = new CascadingDropDownFormFieldComponent(renderer, this.currentUserService);
                    break;
                case GridCascadingDropDownFieldType:
                    fieldTypeAndName.componentRepresentative = new GridCascadingDropDownFormFieldComponent(renderer, this.currentUserService);
                    break;
                case FlexibleSelectionFieldType:
                    fieldTypeAndName.componentRepresentative = new FlexibleSelectionFormFieldComponent(null, this.formFieldService, this.currentSiteService);
                    break;
                case FormulaFieldType:
                    fieldTypeAndName.componentRepresentative = new FormulaFormFieldComponent(renderer, this.fieldDefinitionService);
                    break;
                case MultiCheckboxFieldType:
                    fieldTypeAndName.componentRepresentative = new MultiCheckboxFormFieldComponent(renderer);
                    break;
                case MultiDropDownFieldType:
                    fieldTypeAndName.componentRepresentative = new MultiDropDownFormFieldComponent(renderer);
                    break;
                case AttachmentFieldType:
                    fieldTypeAndName.componentRepresentative =
                        new AttachmentFormFieldComponent(renderer, attachmentService, dialog); //TEAMS-424: KLW - Need to pass in reference for new constructor for Attachment component
                    break;
                case RawHTMLFieldType:
                    fieldTypeAndName.componentRepresentative = new RawHtmlFormFieldComponent(renderer);
                    break;
                case InlineContentFieldType:
                    fieldTypeAndName.componentRepresentative = new InlineContentFormFieldComponent(renderer, null);
                    break;
                case HTMLLinkFieldType: //TEAMS-835: KLW - Needed for the HTML Link form field
                    fieldTypeAndName.componentRepresentative = new HtmlLinkFieldComponent(renderer);
                    break;
                case DevOnlyFieldType:
                    break;

                default:
                    break;
            }
        }

        // Initialize a field type to field info hash.
        for (let iFieldType: number = 0; iFieldType < this.arrFieldTypesAndNames.length; iFieldType++) {
            let fieldInfo: FieldTypeAndName = this.arrFieldTypesAndNames[iFieldType];

            this.hshFieldTypeToFieldInfo[fieldInfo.fieldType] = fieldInfo;
        }
    }

    private initFormFieldTypesToClassNames(): void {
        this.mapFormFieldTypesToClasses[ShortTextFieldType] = ShortTextFormFieldComponent;
        this.mapFormFieldTypesToClasses[CheckboxFieldType] = CheckboxFormFieldComponent;
        this.mapFormFieldTypesToClasses[RadioButtonsFieldType] = RadioButtonsFormFieldComponent;
        this.mapFormFieldTypesToClasses[DateFieldType] = DateFormFieldComponent;
        this.mapFormFieldTypesToClasses[IntegerFieldType] = IntegerFormFieldComponent;
        this.mapFormFieldTypesToClasses[DecimalFieldType] = DecimalFormFieldComponent;
        this.mapFormFieldTypesToClasses[CurrencyFieldType] = CurrencyFormFieldComponent;
        this.mapFormFieldTypesToClasses[LabelFieldType] = LabelFormFieldComponent;
        this.mapFormFieldTypesToClasses[DropDownFieldType] = DropDownFormFieldComponent;
        this.mapFormFieldTypesToClasses[GridFieldType] = GridFormFieldComponent;
        this.mapFormFieldTypesToClasses[CascadingDropDownFieldType] = CascadingDropDownFormFieldComponent;
        this.mapFormFieldTypesToClasses[GridCascadingDropDownFieldType] = GridCascadingDropDownFormFieldComponent;
        this.mapFormFieldTypesToClasses[AttachmentFieldType] = AttachmentFormFieldComponent;
        this.mapFormFieldTypesToClasses[InstructionsFieldType] = InstructionsFormFieldComponent;
        this.mapFormFieldTypesToClasses[RawHTMLFieldType] = RawHtmlFormFieldComponent;
        this.mapFormFieldTypesToClasses[FormulaFieldType] = FormulaFormFieldComponent;
        this.mapFormFieldTypesToClasses[RichTextFieldType] = RichTextFormFieldComponent;
        //this.mapFormFieldTypesToClasses[DevOnlyFieldType] = DevOnlyFormFieldComponent;
        this.mapFormFieldTypesToClasses[FootnoteFieldType] = FootnoteFormFieldComponent;
        this.mapFormFieldTypesToClasses[MultiCheckboxFieldType] = MultiCheckboxFormFieldComponent;
        this.mapFormFieldTypesToClasses[MultiDropDownFieldType] = MultiDropDownFormFieldComponent;
        this.mapFormFieldTypesToClasses[ShowToFieldType] = ShowToHideFromComponent; // Note:  two field type names map to a single component.  Reconsider this???
        this.mapFormFieldTypesToClasses[HideFromFieldType] = ShowToHideFromComponent; 
        this.mapFormFieldTypesToClasses[ContactsFieldType] = ContactsFormFieldComponent;
        this.mapFormFieldTypesToClasses[CommentsFieldType] = CommentsFormFieldComponent;
        this.mapFormFieldTypesToClasses[HTMLLinkFieldType] = HtmlLinkFieldComponent; //TEAMS-835: KLW - Needed for the HTML Link form field
    }
}
