import { RowClassArgs, CommandColumnComponent } from "@progress/kendo-angular-grid";
interface IKendoGridData {
    count: number;
    gridData: any; 
}

// Define an interface, for which an object implementing the interface can be provided as @Input() 'dataSpy',
// to which this directive class will pass data when it gets loaded by the instance of this.kendoGridService.
export interface IKendoGridDataSpy {
    dataLoaded(loadedData: any[]): void;
}


export class KendoGridHelper {
    // Properties.
    private currentlyLoadedRowCount: number = 0;
    private totalRowCount: number = 0;

    private filters = { logic: 'and', filters: [] };
    private isBeingFiltered = false;

    private contextMenuDataItem: any = null; // Added 07-15-2024.
    private dataSpy: IKendoGridDataSpy = null; // Added 05-02-2024.

    // Constructor.
    public constructor() {
    }

    // Setter/getter methods.
    public get Filters() {
        return this.filters;
    }
    public set Filters(filters: any) {
        this.isBeingFiltered = filters.filters.length > 0;
        this.filters = filters;
    }
    public get IsBeingFiltered() {
        return this.isBeingFiltered;
    }

    public get ContextMenuDataItem(): any {
        return this.contextMenuDataItem;
    }
    public set ContextMenuDataItem(value: any) {
        this.contextMenuDataItem = value;
    }

    public get DataSpy(): IKendoGridDataSpy {
        return this.dataSpy;
    }
    public set DataSpy(value: IKendoGridDataSpy) {
        this.dataSpy = value;
    }

    // Regular methods.
    public handleClearFilters(): void {
        this.filters = { logic: 'and', filters: [] };
        this.isBeingFiltered = false;
    }

    // sets the value of totalRowCount when it has not yet been initialized
    // and the value of currentlyLoadedRowCount. These values can used to determine
    // if a Kendo Grid has been initialzed, and are also used to render a "Displaying X of N" message
    public handleLoaded(event: IKendoGridData) {
        if (event.gridData?.total > 0) {
            this.totalRowCount = event.gridData.total;
        }
        this.currentlyLoadedRowCount = event.count;

        // 05-02-2024:  added the following two lines of code to accommodate a "data spy".
        if (this.dataSpy != null) {
            let rowData: any[] = event.gridData['data'];
            this.dataSpy.dataLoaded(rowData);
        }            
    }

    public reInit(): void {
        this.currentlyLoadedRowCount = 0;
    }

    public get CurrentlyLoadedRowCount(): number {
        return this.currentlyLoadedRowCount;
    }

    public get TotalRowCount(): number {
        return this.totalRowCount;
    }

    public get ShowingXofN() {
        return `${this.currentlyLoadedRowCount} of ${this.totalRowCount} records`;
    }

    private indexOfClickedRow: number = -1;
    // THIS METHOD SHOULD SUPPLIED to the <kendo-grid [rowClass]> @Input
    // It applies a class called "clickedRow" which you can style as needed (for example, setting white-space: normal !important
    // if you want to turn on wrapping when a row is clicked (See it's use on form-template-instances.component.html)
    public applyClickedRowClassToClickedRow = (context: RowClassArgs) => {
        if (context.index == this.indexOfClickedRow) {
            return { clickedRow: true };
        }
    };
    public setIndexOfClickedRow(event: any) {
        if (event.column instanceof CommandColumnComponent || event.rowIndex < 0) {
            //console.log(event.column);
            //console.log(event.rowIndex);
            return;
        }
        if (this.indexOfClickedRow == event.rowIndex) {
            this.indexOfClickedRow = -1;
        } else {
            this.indexOfClickedRow = event.rowIndex;
        }
    }
}
