import { Component, OnInit, Type as AngularCoreType, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, FormGroup, Validators, Validator } from '@angular/forms';
import { ListConstraintColumn } from '../../../models/flexible-selection-fields/list-constraint-column.model';
import { FormFieldListValuesConstraint } from '../../../models/form-builder/field-constraints/form-field-list-values-constraint.model';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement, FormInstanceElementValueTypeEnum } from '../../../models/form-builder/form-instance-element.model';
import { CurrentSiteService } from '../../../services/current-site.service';
import { FlexibleSelectionFieldService, FieldIdToSingleOption } from '../../../services/flexible-selection-field.service';
import { FormFieldService } from '../../../services/form-field.service';
import { ControlType, FormFieldBaseComponent } from '../form-field-base/form-field-base.component';

class ConfiguredOption {
    public OptionId: number;
    public OptionText: string;
}

@Component({
    selector: 'app-flexible-selection-form-field',
    templateUrl: './flexible-selection-form-field.component.html',
    styleUrls: ['./flexible-selection-form-field.component.scss', '../form-fields.scss'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FlexibleSelectionFormFieldComponent,
            multi: true
        }
    ]
})
export class FlexibleSelectionFormFieldComponent extends FormFieldBaseComponent implements OnInit {

    @Output() onInit = new EventEmitter();
    @Output() onPropertyUpdated = new EventEmitter();
    public DefaultDropdownValue: number = -1;
    private defaultText: string = 'No Options Configured';
    private formGroupName: string = 'flexible_single_selection_form';
    private gridRowsImpactedBySelectionChange: boolean = false;
    private dropdownPlaceHolderText: string;

    private configuredOptions: ConfiguredOption[] =
        [
            { OptionId: 0, OptionText: '' },
            { OptionId: 1, OptionText: '' }
        ];

    private readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.NAME,
            FormFieldPropertyEnum.FIELD_GROUP,
            FormFieldPropertyEnum.REQUIRED,
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.BLANK_VALUE,
            FormFieldPropertyEnum.HELP_TEXT,
            FormFieldPropertyEnum.PLACEHOLDER_TEXT,
            FormFieldPropertyEnum.TOOL_TIP,
            FormFieldPropertyEnum.DEFAULT_VALUE,
            FormFieldPropertyEnum.SELECT_OPTIONS,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT
        ];
    private dropDownOptionsFormControl: FormControl = null;
    private _autoCompleteItemDataOptions: string[] = [];
    private derivedDropDownOptions: string[] = [];

    constructor(
        private flexibleSelectionFieldService: FlexibleSelectionFieldService,
        private formFieldService: FormFieldService,
        private currentSiteService: CurrentSiteService) {
        super();
    }

    public ngOnInit(): void {
        //debugger;
        let hshProperties = this.getProperties();

        this.onInit.emit(hshProperties);

        if (this.Mode === 'design') {
            // In case we have selected options, try
            // to make sense of our configuration.
            this.propertyUpdated(this.formField, 'selectOptions');
        }
        //else {
        //    //***Set default selected item if needed
        //    let formInstanceElements: FormInstanceElement[] = this.formInstance.formInstanceElements.filter(x => x.formFieldId == this.formField.id)
        //    if (formInstanceElements?.length > 0) {
        //        let formInstanceElement: FormInstanceElement = formInstanceElements[0];
        //        if (formInstanceElement.textValue == null || formInstanceElement.textValue == "") {
        //            formInstanceElement.textValue = this.formField.defaultValue;
        //            this.selected = formInstanceElement.textValue;
        //            debugger;
        //        }
        //    }
        //}

        this.FormField.fieldHasConfiguration = true;
        this.rebuildDropDownOptionsData();
        this.FormInstanceElement.ValueType = FormInstanceElementValueTypeEnum.TypeMultiText;

        // pharvey - 4/2/2024 - added for VNEXT-1231
        this.flexibleSelectionFieldService.AutoSelectFieldValuesSubject.subscribe(singleOptionsByFieldId => {
            //debugger;
            this.handleUpdateOfFieldsWithSingleOption(singleOptionsByFieldId);
        });

        this.dropdownPlaceHolderText = this.FormField.placeholderText;        
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshProperties =
        {
            component: this,
            formField: this.formField,
            properties: this.formFieldProperties,

            propertyUpdateRequired: true
        };

        return (hshProperties);
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (FlexibleSelectionFormFieldComponent);
    }

    // Event handlers
    public selectChange(event: any) {
        console.log('FlexibleSelectionFormField.selectChange() was called');
    }

    // takes a dictionary of field values by field Id for those
    // fields which only have one option. If a value is found for this field then does the following:
    // 1) sets the value of the flex field's underlying data so the one
    //    option becomes automatically selected
    // 2) calls handleSingleSelectChange() to mimic the behavior of a user
    //    having the selected the value themselves
    private handleUpdateOfFieldsWithSingleOption(singleOptionsByFieldId: FieldIdToSingleOption) {
        //debugger;
        if (singleOptionsByFieldId != null) { // SWH:  added this check on 04/18 after a null value JavaScript exception.
            let value = singleOptionsByFieldId[this.formField.id];
            let controls = this.controlData.formGroup?.controls;
            if (value && controls) {
                this.FormField.selectOptions = ` |${this.FormField.selectOptions}`; // provide a blank option in case options get "stuck"
                if (controls[this.formGroupName].value != value) {
                    //debugger;
                    controls[this.formGroupName].setValue(value);
                    this.handleSingleSelectChange({ value: value });
                }
            }
        }
    }


    public handleSingleSelectChange(event: any): void {
        //debugger;
        this.FormInstanceElement.childFormInstanceElements = [];
        let childElementForSingleValue = new FormInstanceElement();
        childElementForSingleValue.valueType = FormInstanceElementValueTypeEnum.TypeText;
        childElementForSingleValue.textValue = event.value;
        this.FormInstanceElement.childFormInstanceElements.push(childElementForSingleValue);
        this.FormInstanceElement.textValue = null;

        this.handleSelectionFieldValueChanged();
    }

    public handleSelectionFieldValueChanged(): void {

        // use object assign to create a new instance based on the existing properties (so as not change the original object)
        let el = Object.assign(new FormInstanceElement(), this.getFormInstanceElement());
        el.childFormInstanceElements = el.childFormInstanceElements.filter(x => { return !x.isDeleted });
        if (this.FormInstanceElement != null) // 05-08-2024 note:  this if block is necessary to make field conditional logic work properly.
            this.FormInstanceElement.childFormInstanceElements = el.childFormInstanceElements;
        el.formFieldId = this.formField.id;
        if (this.formInstance) {
            el.formInstanceId = this.FormInstance.id;

            // For VNEXT-1259 - look here to see how selected values
            // in a grid row can be sent to the client
            //console.log('el');
            //console.log(el);

            let parentEl = this.formInstance.formInstanceElements.find(x => x.formFieldId == this.formField.dependsOnParentFormFieldId);

            // For further consideration: it may not be a sufficient to send only the parent's field's value
            // can this be done by simply sending this.FormInstance as was done in InlineContentFormFieldComponent.handleOpenContentButtonClick()?
            // and let the server climb "up" the chain of fields?
            // See comment on FlexibleSelectionFieldService.selectionFieldValueChanged() for why the parent value is needed
            let parentValues: FormInstanceElement[] = parentEl ? parentEl.childFormInstanceElements : null;
            this.flexibleSelectionFieldService.selectionFieldValueChanged(this.formField, this.formInstance, el, parentValues, this.GridRowId, this.GridColumnDefs).then(gridRowsImpacted => {
                this.gridRowsImpactedBySelectionChange = gridRowsImpacted;
            });

        }

        // 04-19-2024 note:  need to notify the form renderer of a value change.
        super.handleOnBlur(); // Notifies the form renderer of a value change.
    }

    // Accessors
    public get IsSingleSelect(): boolean {
        return !this.FormField.maxSelections || this.FormField.maxSelections <= 1
    }
    public get IsMultiSelect(): boolean {
        //
        return this.FormField.maxSelections > 1;
    }

    public get GridRowsImpactedBySelectionChange(): boolean {
        return this.gridRowsImpactedBySelectionChange;
    }

    public get DefaultDropdownValueText(): string {
        return this.defaultText;
    }
    public get DropdownPlaceHolderText(): string {
        return this.dropdownPlaceHolderText;
    }
    public get ConfiguredOptions(): ConfiguredOption[] {
        return (this.configuredOptions);
    }

    public get SelectOptions(): string[] {
        return this.formField.selectOptions?.split("|");
    }

    public get ShowRemoveAll(): boolean {
        return false;
    }

    public get DropDownOptions(): string[] {
        return (this.derivedDropDownOptions);
    }

    public get DropDownOptionsFormControl(): FormControl {
        return (this.dropDownOptionsFormControl);
    }

    // Handle getting this field's form instance element.
    protected formInstanceElementReceived(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                if (this.FormInstanceElement.UserUpdatedData != true) {
                    this.setDefaultTextSelectValue();
                }

                // Use a base class method to
                // set up a textual form group.
                this.setupTextFormGroup('flexible_selection_form');
            }
        }
    }

    public onRemove(option: string) {
        const options = this.dropDownOptionsFormControl.value as string[];
        this.removeFirst(options, option);
        this.dropDownOptionsFormControl.setValue(options); // To trigger change detection
    }

    public removeAllChips() {
        this.dropDownOptionsFormControl.setValue([]);
    }

    private removeFirst<T>(array: T[], toRemove: T): void {
        const index = array.indexOf(toRemove);
        if (index !== -1) {
            array.splice(index, 1);
        }
    }

    // Just yanked this out of dropdown component without really understanding it
    protected writeValueTriggered(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                if (this.IsSingleSelect) {
                    this.SetupFormGroupFromWriteValue(this.formGroupName);
                    this.FormInstanceElement.valueType = FormInstanceElementValueTypeEnum.TypeMultiText;
                    // In addition to subscribing to this.flexibleSelectionFieldService.AutoSelectFieldValuesSubject in ngOnInit()
                    // so as to listen for changes, we also need to "get" info auto select fields(those that only have one option)
                    // This is needed for flex fields in GridRows which get initialized lazily and therefore don't get
                    // the opportunity to subscribe in time to get useful updates
                    this.handleUpdateOfFieldsWithSingleOption(this.flexibleSelectionFieldService.AutoSelectFieldValues);
                } else if (this.IsMultiSelect) {
                    // Added these from MultiDropDownFormFieldComponent.writeValueTriggered()
                    // Rebuild my select options data.
                    this.rebuildDropDownOptionsData();

                    // Setup my Reactive Forms data structure.
                    this.setupMultiSelectFormControl();
                }
            }
        }
    }

    // Override notifyValueChanged in order to allow field conditional logic to trigger on a value change.
    protected notifyValueChanged(): void {
        super.notifyValueChanged();

        // Note:  moving the code to super.handleOnBlur() into method handleSelectionFieldValueChanged(),
        //        above, so that when this field notifies the form render of a value change, the field's
        //        child form instance element value(s) have already been updated.
        //super.handleOnBlur();
    }

    public formFieldUpdated(): void {
        // 07-31-2024 note:  added this method so it can be called by the field conditional
        //                   logic to indicate that a component's form field has been updated.

        // Make sure the form control's disabled state agrees with the form field's 'isReadOnly' attribute.
        //
        // Plan to move some of the logic in the following if block into reusable FormFieldBaseComponent methods.
        if (this.FormControl != null) {
            // Togle the required behavior of the form control if it does not reflect this.FormField.required.
            /*
            let formControlIsRequired: boolean = FormFieldBaseComponent.formControlIsRequired(this.FormControl);
            if (formControlIsRequired != this.FormField.required) {
                this.FormControl.setValidators(this.FormField.required ? null : [Validators.required]);
                this.FormControl.updateValueAndValidity();
            }
            */
            this.FormControl.setValidators(this.FormField.required ? null : [Validators.required]);
            this.FormControl.updateValueAndValidity();

            // Set the read-only property.
            if (this.FormField.readOnly)
                this.FormControl.disable();
            else
                this.FormControl.enable();
        }
    }

    private setupMultiSelectFormControl(): void {
        // Parse/package existing data, if any.
        var astrExistingValues: string[] = [];

        if (this.FormInstanceElement) {
            if (this.FormInstanceElement.childFormInstanceElements &&
                (this.FormInstanceElement.childFormInstanceElements.length > 0)) {
                for (let iChild: number = 0; iChild < this.FormInstanceElement.childFormInstanceElements.length; iChild++) {
                    let childFormInstanceElement: FormInstanceElement =
                        this.FormInstanceElement.childFormInstanceElements[iChild];

                    astrExistingValues.push(childFormInstanceElement.textValue);
                    this._autoCompleteItemDataOptions.push(childFormInstanceElement.textValue);
                }
            }
        }

        // Setup/configure my form control.
        let hshControlProperties = {
            value: astrExistingValues,
            disabled: this.ReadOnly //false
        }

        this.setupMultiSelectFormGroup('MultiSelect_Control', hshControlProperties);
        this.dropDownOptionsFormControl = <FormControl>this.controlData.formGroup.get('MultiSelect_Control');

        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            this.dropDownOptionsFormControl.valueChanges
                .subscribe(astrValues => {
                    this.processSelectedValuesFromMutiSelect(astrValues);
                });
        }
    }



    private processSelectedValuesFromMutiSelect(passedValues: string[]) {
        console.log("processSelectedValuesFromMutiSelect");
        console.log(passedValues);

        if (!this.FormInstanceElement.childFormInstanceElements) {
            this.FormInstanceElement.childFormInstanceElements = [];
        }

        // See if any existing child elements
        // need to be marked as deleted.
        for (let iChildElement: number = 0; iChildElement < this.FormInstanceElement.childFormInstanceElements.length; iChildElement++) {
            let childFormInstanceElement: FormInstanceElement = this.FormInstanceElement.childFormInstanceElements[iChildElement];

            let arrFoundValue: string[] = passedValues.filter(v => v == childFormInstanceElement.textValue);
            let bValueFound: boolean = (arrFoundValue && (arrFoundValue.length == 1));

            if (!bValueFound) {
                childFormInstanceElement.isDeleted = true;
            }
        }

        // For any child element marked as deleted
        // that does not have an Id, just remove it.
        this.FormInstanceElement.childFormInstanceElements = this.FormInstanceElement.childFormInstanceElements.filter(fie => (fie.id > 0) || (!fie.isDeleted));

        // Update or add each value in the control.

        if (passedValues && (passedValues.length > 0)) {
            for (let iValue: number = 0; iValue < passedValues.length; iValue++) {
                let strValue: string = passedValues[iValue];

                // See if this value already exists as a child form instance element.
                let arrExistingChildFormInstanceElement: FormInstanceElement[] =
                    this.FormInstanceElement.childFormInstanceElements.filter(fie => fie.textValue == strValue);

                if (arrExistingChildFormInstanceElement && (arrExistingChildFormInstanceElement.length == 1)) {
                    let existingChildFormInstanceElement: FormInstanceElement = arrExistingChildFormInstanceElement[0];

                    existingChildFormInstanceElement.isDeleted = false;
                } else {
                    let childFormInstanceElement = new FormInstanceElement();
                    childFormInstanceElement.ValueType = FormInstanceElementValueTypeEnum.TypeText;
                    childFormInstanceElement.TextValue = strValue;

                    this.FormInstanceElement.childFormInstanceElements.push(childFormInstanceElement);
                } // if-else
            } // for
        } // if

        // Did a user enter this value?
        if (!this.FormInstanceElement.transientInSetupFlag) {
            // A user entered this value.
            //this.FormInstanceElement.transientValueSetFlag = true;
            this.FormInstanceElement.UserUpdatedData = true;

            this.userEnteredValue();
        }

        console.log(this.FormInstanceElement.childFormInstanceElements);

        this.handleSelectionFieldValueChanged();
    }

    //***Populate the dropdown */
    private rebuildDropDownOptionsData(): void {
        this.derivedDropDownOptions = [];
        //debugger;
        
        let placeHolderText = this.formField.placeholderText;

        // Evaulate select options.
        let arrDropDownOptions: string[] = this.SelectOptions;

        //VNEXT-519: KLW - Refinements to the Type ahead functionality
        if (!this.formField.autocomplete) {
            if (arrDropDownOptions && arrDropDownOptions.length > 0) {
                for (let iOption: number = 0; iOption < arrDropDownOptions.length; iOption++) {
                    let dropDownOption: string = arrDropDownOptions[iOption];

                    // If the last value is
                    // a blank, do not add it.
                    let bIsLastValue: boolean = (iOption === arrDropDownOptions.length - 1);

                    if (bIsLastValue &&
                        ((!dropDownOption) || (dropDownOption.trim() === ''))) {
                        continue;
                    }

                    this.derivedDropDownOptions.push(dropDownOption);
                }
            } else {
                // Add a placeholder select option.
                this.derivedDropDownOptions.push('Set up options using properties');
            }
        }

        return;
    }
}
