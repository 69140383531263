import { DefaultFieldDefinitionClientLogic } from './default-field-def-client-logic';
import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
import { FormField } from '../form-field.model';
//import { IFormFieldComponent } from '../../../interfaces/iform-field-component';
import { ConditionalLogicRuleOperator } from '../../field-conditional-logic/rule-operator.model';
import { FormFieldComparisonOperatorNameEnum } from '../../field-conditional-logic/enums';
import { FormInstanceElement } from '../form-instance-element.model';
//import { InstructionsFieldType } from '../form-field-types';
//import { IOperandSymbolToCompareMethodName, allFieldOperators } from '../../field-conditional-logic/conditional-logic-operators.model';
import { FieldConditionalLogicService, IOperandSymbolToCompareMethodName } from '../../../services/field-conditional-logic.service';

export abstract class NumericComparisonTypesFieldDefinitionLogicBase extends DefaultFieldDefinitionClientLogic implements IFieldDefinitionLogic {
    // Static data.
    //private static myFieldOperators: ConditionalLogicRuleOperator[] = allFieldOperators.filter(o => o.appliesToNumericFields);
    private static myFieldOperators: ConditionalLogicRuleOperator[] = FieldConditionalLogicService.AllNumericFieldOperators;

    private static operatorSymbolsToMethodNames: IOperandSymbolToCompareMethodName = null;

    // Constructor.
    public constructor() {
        if (NumericComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames == null) {
            NumericComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames = {};

            NumericComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames[FormFieldComparisonOperatorNameEnum.IsGreaterThan] = 'isGreaterThan';
            NumericComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames[FormFieldComparisonOperatorNameEnum.IsGreaterThanOrEqualTo] = 'isGreaterThanOrEqualTo';
            NumericComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames[FormFieldComparisonOperatorNameEnum.Equals] = 'equals';
            NumericComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames[FormFieldComparisonOperatorNameEnum.DoesNotEqual] = 'doesNotEqual';
            NumericComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames[FormFieldComparisonOperatorNameEnum.IsLessThan] = 'isLessThan';
            NumericComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames[FormFieldComparisonOperatorNameEnum.IsLessThanOrEqualTo] = 'isLessThanOrEqualTo';
            //NumericComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames[FormFieldComparisonOperatorNameEnum.Between] = 'between';
        }

        super();
    }

    // Abstract methods.
    protected abstract formatValueForCompare(value: any): any;

    // IFieldDefinitionLogic methods.
    public getAvailableOperatorsFor(formField: FormField): ConditionalLogicRuleOperator[] {
        return NumericComparisonTypesFieldDefinitionLogicBase.myFieldOperators;
    }
    public compare(leftOperand: any, operatorSymbol: string, rightOperand: any): boolean {
        let leftFormattedOperand: any = this.formatValueForCompare(leftOperand);
        let rightFormattedOperand: any = this.formatValueForCompare(rightOperand);

        let comparisonMethodName = NumericComparisonTypesFieldDefinitionLogicBase.operatorSymbolsToMethodNames[operatorSymbol];
        if (comparisonMethodName == null) {
            let errorMsg = `NumericComparisonTypesFieldDefinitionLogicBase.compare():  this field type does not support comparion operator '${operatorSymbol}'.`;
            throw errorMsg;
        }

        let result: boolean = this[comparisonMethodName](leftFormattedOperand, rightFormattedOperand);
        return result;
    }

    // Helper methods.
    private isGreaterThan(leftOperand: any, rightOperand: any): boolean {
        return leftOperand > rightOperand;
    }
    private isGreaterThanOrEqualTo(leftOperand: any, rightOperand: any): boolean {
        return leftOperand >= rightOperand;
    }
    private equals(leftOperand: any, rightOperand: any): boolean {
        return leftOperand == rightOperand;
    }
    private doesNotEqual(leftOperand: any, rightOperand: any): boolean {
        return leftOperand != rightOperand;
    }
    private isLessThan(leftOperand: any, rightOperand: any): boolean {
        return leftOperand < rightOperand;
    }
    private isLessThanOrEqualTo(leftOperand: any, rightOperand: any): boolean {
        return leftOperand <= rightOperand;
    }
    private between(leftOperand: any, rightOperand: any): boolean {
        let betweenResult: boolean = false;

        if ((leftOperand != null) && (leftOperand.toString() != '') && (rightOperand != null) && (rightOperand.toString() != '')) {
            let listOfValues: string[] = rightOperand.toString().split(',');

            if ((listOfValues != null) && (listOfValues.length == 2)) {
                let valueToCompare = parseFloat(leftOperand.toString());
                let minValue: number = parseFloat(listOfValues[0]);
                let maxValue: number = parseFloat(listOfValues[1]);

                if ((valueToCompare != NaN) && (minValue != NaN) && (maxValue != NaN)) {
                    if ((valueToCompare >= minValue) && (valueToCompare <= maxValue))
                        betweenResult = true;
                }
            }
        }

        return betweenResult;
    }
}

export abstract class NumericFieldDefinitionLogicBase extends NumericComparisonTypesFieldDefinitionLogicBase implements IFieldDefinitionLogic {
    // Constructor.
    public constructor() {
        super();
    }

    // pasteDataForInto():  only allow the pasting of values that are valid for a given form field.
    public pasteDataForInto(formField: FormField, formInstanceElement: FormInstanceElement, pasteValue: string): void {
        formInstanceElement.textValue = pasteValue;

        // TO DO:  ADD VALIDATION TO THIS METHOD.
    }

    // Sorting.
    public compareValuesAscending(value1: any, value2: any): number {
        let compareResult: number = 0;

        if ((value1 != null) && (value2 != null)) {
            let formattedValue1: any = this.formatValueForCompare(value1);
            let formattedValue2: any = this.formatValueForCompare(value2);

            if (formattedValue1 > formattedValue2)
                compareResult = 1;
            else if (formattedValue1 < formattedValue2)
                compareResult = -1;
        } else if (value1 != null) {
            compareResult = 1;
        } else if (value2 != null) {
            compareResult = -1;
        }

        return compareResult;
    }
    public compareValuesDescending(value1: any, value2: any): number {
        let compareResult: number = - this.compareValuesAscending(value1, value2);
        return compareResult;
    }

    public hasNumericData(): boolean {
        return true;
    }

    public filterType(): string {
        return 'numeric';
    }

    public isFilterable(): boolean {
        return true;
    }

    // Abstract method(s).
    public abstract compareAscendingFor(formField: FormField, value1: FormInstanceElement, value2: FormInstanceElement): number;

    protected abstract formatValueForCompare(value: any): any;
}
