export class FormInstanceElementRequest {
    // Keys for the request.
    public formInstanceId: number;

    public formInstanceElementId: number;

    // Options.
    // Grid request options.
    public pageIndex: number;
    public pageSize: number;
}
