<h1 mat-dialog-title
    class="draggable-dialogue-header center-text"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>
    {{this.Title}}
</h1>

<form [formGroup]="NgFormGroup">
    <div class="margin-10" mat-dialog-content>
        <p [innerHtml]="Message"></p>
    </div>

    <div class="margin-10">
        <mat-checkbox *ngIf="!HideRequestAssistance" class="margin-10" (click)="toggleNotifyDevs()">Request assistance</mat-checkbox>
        <div *ngIf="NotifyDevs">
            <div>
                <textarea class="description-textarea" formControlName="tryingToDo" rows="5" maxlength="10000" placeholder="{{Placeholder}}"></textarea>
            </div>
            <mat-checkbox class="margin-10" *ngIf="UserEmail"
                          title="Send copy of error report email to me"
                          (click)="toggleCCtoUser()">
                Send CC to {{UserEmail}}
            </mat-checkbox>
        </div>
    </div>
    <div class="margin-10" *ngIf="ShowTechnicalDetails" [@.disabled]="disableAnimation">
        <mat-expansion-panel [expanded]="false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Technical Details
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="technical-info">
                {{this.TechnicalInfo}}
                <div *ngIf="CorrelationId">
                    <br />
                    <span>Correlation ID</span>
                    <br />
                    <span class="correlation-id-span">
                        <span class="flex-grow-1"></span>
                        <button mat-icon-button class="copy-button" (click)="CopyToClipboard()" title="Copy to Clipboard">
                            <mat-icon class="copy-button-icon" id="copybutton">content_copy</mat-icon>
                        </button>
                        &nbsp;
                        <span class="correlation-id">{{CorrelationId}}</span>
                    </span>
                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <div class="center-button" mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="reloadPage()" mat-dialog-close class="close-btn">Close</button>
        <button *ngIf="NotifyDevs" [disabled]="SendDisabled" mat-raised-button color="accent" (click)="SendErrorReport()">{{SendCaption}}</button>
    </div>
    <br />
    <div>
        {{EmailStatus}}
    </div>
</form>
