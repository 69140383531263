<div class="theme-page" style="padding: 15px; height: 100%;">
    <div class="row center-row">
        <div class="col-md-8">
            <div class="welcome-text">Welcome to {{this.SiteName}}</div>
            <div class="summary-text">
                {{this.SiteName}} is an application for all offices across OMB to gather data from the entire Federal
                Government.
                Anyone at OMB can request a data collection site, and the OMB Collect team will work with them to
                develop forms, workflows, reporting,
                and overall design to meet their needs. For more information, OMB users should contact
                <span class="center-text"
                    [innerHtml]="this.emailHtml('Information Request','I would like more information about this site please.')">
                </span> to request more information
                about the system and/or a demo.
            </div>
        </div>
    </div>

    <div class="row center-row" *ngIf="!this.currentUserService.user">
        <button mat-raised-button (click)="this.loginFromLandingPage()" class="login-button col-md-8">LOG IN</button>
    </div>

    <div class="" id="main-content" role="main" tabindex="0">
        <div class="row center-row">
            <div class="col-md-4 no-padding-left">
                <div class="info-box theme-landing-page-info-box">
                    <div class="info-icon"><span class="material-icons medium-icon">feedback</span></div>
                    <div class="info-header Paragon">Give Feedback</div>

                    <div class="info-text BodyCopy">
                        <p>
                            Help us improve {{this.SiteName}} by providing feedback at <span class="center-text"
                                [innerHtml]="this.emailHtml('Feedback','Enter your feedback here please')"></span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-4 no-padding-right" *ngIf="!this.currentUserService.user">
                <div class="info-box theme-landing-page-info-box login-trouble">
                    <div class="info-icon"><span class="material-icons medium-icon">help</span></div>
                    <div class="info-header Paragon">Log in Trouble?</div>

                    <div class="info-text BodyCopy">
                        <p>
                            Email: <span class="center-text"
                                [innerHtml]="this.emailHtml('Log in trouble','I am having log in trouble.')"></span>
                        </p>
                        <p>
                            Phone: 202-395-6860
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-4" *ngIf="this.currentUserService.user">
                <div class="info-box theme-landing-page-info-box">
                    <div class="info-icon"><span class="material-icons medium-icon">collections</span></div>
                    <div class="info-header Paragon">My Sites</div>
                    <div class="info-text BodyCopy" id="landingPageLinksDiv">
                        <ng-container *ngFor="let linkData of this.LandingPageLinkData; let i=index">
                            <ng-container *ngIf="(!linkData.requiresSystemAdminFlag) || this.UserIsSystemAdmin">
                                <app-router-link *ngIf="!linkData.hasCallbackMethod"
                                    [linkData]="linkData"></app-router-link>
                                <ng-container *ngIf="linkData.hasCallbackMethod">
                                    <a href="javascript:void(0)"
                                        (click)="this.onLinkWithCallbackClick(linkData)">{{linkData.title}}</a>
                                </ng-container>

                                <ng-container *ngIf="this.IsNotLastLink(i)">
                                    <br />
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>


        <div class="row center-row release-info-margins" *ngIf="this.UserIsSystemAdmin">
            <mat-accordion class="col-md-8 release-info-padding">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="right-aligned-header">
                        <div class="info-text BodyCopy">
                            <div>
                                Edit Release Features:
                                <a class="btn add-new-features-button" (click)="this.editNewComingSoonFeatures()"
                                    aria-label="Edit new features" title="Edit new features">
                                    <i class="fa fa-edit edit-icon"></i>
                                </a>

                                Release Info:
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <div>
                        API
                    </div>
                    <div class="release-info-tab">
                        Build Time: {{this.AppBuildInfoApiBuildInfoBuildTime}}
                    </div>
                    <div class="release-info-tab">
                        Changeset: {{this.AppBuildInfoApiBuildInfoChangeSet}}
                    </div>
                    <div class="release-info-tab">
                        Build Number: {{this.AppBuildInfoApiBuildInfobuildNumber}}
                    </div>

                    <div>
                        Web UI
                    </div>
                    <div class="release-info-tab">
                        Build Time: {{this.AppBuildInfoWebBuildInfoBuildTime}}
                    </div>
                    <div class="release-info-tab">
                        Changeset: {{this.AppBuildInfoWebBuildInfoChangeSet}}
                    </div>
                    <div class="release-info-tab">
                        Build Number: {{this.AppBuildInfoWebBuildInfobuildNumber}}
                    </div>

                </mat-expansion-panel>
            </mat-accordion>

        </div>
    </div>
</div>