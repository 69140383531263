import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms'; // Used for Reactive Forms
import { plainToClass } from "class-transformer";
import { environment } from '../../../environments/environment';
import { IModelEventHandler, ModelEventEnum } from '../interfaces/imodel-event-handler.interface';
import { IRegisterOperationCompletedCallback } from '../interfaces/ioperation-completed.intefaces';
import { Scriptable } from '../models/component-scripting/scriptable-function.annotation';
import { Form } from '../models/form-builder/form.model';
import { ImportFormatExamples } from '../models/site-metadata/import-format-examples.model';
import { CollectApiServiceBase } from './collect-api-base.service';
import { ProgressConfig, ProgressIndicatorService } from './progress-indicator.service';

@Injectable({
    providedIn: 'root'
})
export class FormBuilderService extends CollectApiServiceBase<Form> implements IRegisterOperationCompletedCallback {
    // Properties.
    private tempFormEventHandler: IModelEventHandler = null;
    private tempTransient_suggestedFormNamePrefix_formControl: FormControl = null;

    // Constructor.
    public constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'form', Form)
    }

    // Methods.
    public formatResponse(data: Form): Form {
        let obj = plainToClass(Form, data);
        return obj;
    }

    @Scriptable()
    public get(id: number): Promise<Form> {
        this.updateProgress(25, 75, 'Loading form template..')
        return super.get(id).then(result => {
            this.hideProgressIndicator();
            return result;
        });
    }

    public save(data: Form): Promise<Form> {
        let result: Promise<Form> = null;

        // 05-25-2022:  tell the form to notify any 'listening' event handlers, if any.
        //
        // Note:  this logic is a bit messy at this juncture so I will absolutely have
        //        to rework it to be much, much clearner.
        data.notifyEventHandler(ModelEventEnum.MODEL_WILL_SAVE);
        // Save and set to null any properties that will cause a circular dependency error during JSON serialization.
        this.saveFormEventHandlerIfAny(data);
        this.saveTransient_suggestedFormNamePrefix_formControlIfAny(data);
        if (data.id > 0) {
            result = this.update(data);
        } else {
            result = this.create(data);
        }
        // Restore any properties that were saved to prevent a circular dependency error during JSON serialization.
        this.restoreFormEventHandlerIfAny(data);
        this.restoreTransient_suggestedFormNamePrefix_formControlIfAny(data);
        data.notifyEventHandler(ModelEventEnum.MODEL_SAVED);
        return result;
    }

    // Wrap update (can simplify debugging).
    @Scriptable()
    public update(item: Form, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<Form> {
        let result = super.update(item, progressConfig);
        return result;
    }

    // Added 3/9/21 for VNEXT-19
    @Scriptable()
    public newForm(dataCollectionId: number): Promise<Form> {
        let url = `${this.url}/${this.endpoint}/new/${dataCollectionId}`;

        return this.http.get<Form>(url)
            .toPromise()
            .then(response => {
                let obj = this.formatResponse(response);

                return obj;
            })
            .catch(this.handleError);
    }

    public deleteFromUI(id: number, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<number> {
        let msg = progressConfig.msgDuring || this.progressMsg('Deleting');
        this.updateProgress(50, 75, msg);

        return this.http.delete(`${this.url}/${this.endpoint}/fromui/${id}`)
            .toPromise()
            .then(res => {
                let msg = progressConfig.msgOnComplete || this.progressMsg('Deleted');
                this.updateProgress(100, 100, msg);
                return id;
            }).catch(this.handleError);
    }

    @Scriptable()
    public copyAs(formId: number, copyName: string, progressConfig: ProgressConfig = ProgressConfig.default()): Promise<Form> {
        let msg = progressConfig.msgDuring || this.progressMsg('Copying');
        this.updateProgress(50, 75, msg);

        let url = `${this.url}/${this.endpoint}/copy/${formId}/as/${copyName}`;

        return this.http.get<Form>(url)
            .toPromise()
            .then(response => {
                let obj = this.formatResponse(response);

                let msg = progressConfig.msgOnComplete || this.progressMsg('Copied');
                this.updateProgress(100, 100, msg);

                return obj;
            })
            .catch(this.handleError);
    }

    @Scriptable()
    public getImportFormatExample(formId: number): Promise<ImportFormatExamples> {
        let url = `${this.url}/${this.endpoint}/getImportFormatExample/${formId}`;

        return this.http.get<ImportFormatExamples>(url)
            .toPromise()
            .then(responseData => {
                let response: ImportFormatExamples = plainToClass(ImportFormatExamples, responseData);
                return (response);
            })
            .catch(this.handleError);
    }

    protected operationCompleted(operationName: string): void {
        // Notify any registered callbacks that the save operation has completed.
        this.notifyAnyCallbacksOperationCompleted(operationName);
    }

    // Helper methods.
    // The following two methods are related to saving/updating a form while
    // not causing a circular depenency in serializing the form to JSON notation.
    
    private saveFormEventHandlerIfAny(form: Form): void {
        this.tempFormEventHandler = form.eventHandler;
        form.eventHandler = null;
    }
    private restoreFormEventHandlerIfAny(form: Form): void {
        if (this.tempFormEventHandler != null) {
            form.eventHandler = this.tempFormEventHandler;
            this.tempFormEventHandler = null;
        }
    }
    private saveTransient_suggestedFormNamePrefix_formControlIfAny(form: Form): void {
        this.tempTransient_suggestedFormNamePrefix_formControl = form.transient_suggestedFormNamePrefix_formControl;
        form.transient_suggestedFormNamePrefix_formControl = null;
    }
    private restoreTransient_suggestedFormNamePrefix_formControlIfAny(form: Form): void {
        if (this.tempTransient_suggestedFormNamePrefix_formControl != null) {
            form.transient_suggestedFormNamePrefix_formControl = this.tempTransient_suggestedFormNamePrefix_formControl;
            this.tempTransient_suggestedFormNamePrefix_formControl = null;
        }
    }
}
