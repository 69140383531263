import { Folder } from '../site-content/folder.model';
import { Form } from '../form-builder/form.model';
import { Role } from '../role.model';
import { Workflow } from '../site-content/workflow.model';
import { IListItem } from '../../interfaces/ilist-item.interface';
import { ItemTypeEnum } from '../../enums/item-type.enum';
import { IListItemBase } from '../ilist-item-base.model';
import { IconFormatEnum } from '../../enums/icon-format.enum';
import { SiteHomePage } from '../form-builder/site-home-page.model';

export class DataCollection extends IListItemBase implements IListItem {
    // Constants.
    public readonly SETUP_TO_ACTIVE: string = 'Setup to Active';
    public readonly ACTIVE_TO_SETUP: string = 'Active to Setup';
    public readonly ACTIVE_TO_CLOSED: string = 'Active to Closed';
    public readonly CLOSED_TO_ACTIVE: string = 'Closed to Active';

    // Constructor.
    public constructor(siteToCopy: DataCollection = null) {
        super();

        if (siteToCopy != null) {
            this.id = siteToCopy.id;

            this.statuses = siteToCopy.statuses;
            this.currentStatus = siteToCopy.currentStatus;
            this.selectedWorkflowTransition = siteToCopy.selectedWorkflowTransition;

            this.maxPointOfContact = siteToCopy.maxPointOfContact;
            this.maxPointOfContactDspl = siteToCopy.maxPointOfContactDspl;
            this.maxPointOfContactEmail = siteToCopy.maxPointOfContactEmail;

            this.betaFeaturesEnabled = siteToCopy.betaFeaturesEnabled;
            this.useSimplifiedPermissionsModel = siteToCopy.useSimplifiedPermissionsModel;

            this.purpose = siteToCopy.purpose;
            this.description = siteToCopy.description;
            this.frequency = siteToCopy.frequency;
            this.totalDataCollectionsCount = siteToCopy.totalDataCollectionsCount;

            this.activatedDate = siteToCopy.activatedDate;
            this.closedDate = siteToCopy.closedDate;
            this.archivedDate = siteToCopy.archivedDate;
            this.startDate = siteToCopy.startDate;
            this.endDate = siteToCopy.endDate;

            this.myRole = siteToCopy.myRole;
            this.createdFromTemplate = siteToCopy.createdFromTemplate;

            this.folders = siteToCopy.folders;
            this.forms = siteToCopy.forms;
            this.siteHomePage = siteToCopy.siteHomePage;
            this.roles = siteToCopy.roles;
            this.workflows = siteToCopy.workflows;
        }
    }

    // Properties.
    public id: number = 0;

    public statuses: any[];
    public currentStatus: string;
    public selectedWorkflowTransition: string;

    public maxPointOfContact: string;
    public maxPointOfContactDspl: string;
    public maxPointOfContactEmail: string;

    public betaFeaturesEnabled: boolean = false;
    public useSimplifiedPermissionsModel: boolean;

    //VNEXT-1066: KLW - Properties of the Banner
    public leftBannerText: string;
    public centerBannerText: string;
    public rightBannerText: string;

    public backgroundColor: string;
    public textColor: string;

    //public name: string;
    public purpose: string;
    public description: string;
    public frequency: string;
    public totalDataCollectionsCount: number;

    public activatedDate: Date = new Date();
    public closedDate: Date = new Date();
    public archivedDate: Date = new Date();
    public startDate: Date = new Date();
    public endDate: Date = new Date();

    public myRole: string = '<placeholder>';
    public createdFromTemplate: boolean;

    public folders: Folder[];
    public forms: Form[];
    public siteHomePage: SiteHomePage;
    public roles: Role[];
    public workflows: Workflow[];

    public get SiteHomePage(): SiteHomePage {
        return <SiteHomePage>this.siteHomePage;
    }

    //history
    public createdBy: string;
    public createdByUserName: string;
    public createdDate: Date;
    public modifiedBy: string;
    public modifiedByUserName: string;
    public modifiedDate: Date;

    /* Flags used by the UI to govern show/hide logic */

    // siteIsBuildable is used to enable/disable buttons to
    // create/edit site-level entities like contacts, roles etc
    public siteIsBuildable: boolean;

    // siteIsAdministerable is used by the claims checking mechanism to allow/disallow routes
    // and show/hide side-nav menu buttons (like Forms Catalog and Workflow Catalog)
    // It's used on the site list screen to show what sites are administerable to a System Admin.
    // (Could siteIsBuildable and siteIsAdministerable be combined? Is there need for both? Would
    // require analysis and careful testing, but if so keep siteIsAdministerable)
    public siteIsAdministerable: boolean;

    // treeIsBuildable is used to enable/disable "New Folder" and "New Form" buttons
    public treeIsBuildable: boolean;

    // treeIsViewable is used in the claims checking mechanism to guard routes and show/hide nav
    // menu buttons
    public treeIsViewable: boolean;

    public isFavorite: boolean = false; // Added 01-07-2021.
    public favoriteTitle: string; // Added 01-07-2021.

    public currentUserCanSeeStructure: boolean; // Added 01-30-2024.
    public enableStructureForEndUsers: boolean; // Added 01-30-2024.

    // 07-09-2024:  added the following property related to the implementation of https://maxjira.max.gov/browse/VNEXT-1340.
    public hideStructureMetadataFieldsJsonConfig: string; // A JSON string containing an optional configuration of structure metadata fields to hide.

    public currentUserCanSeeSimplePermissions: boolean;

    // Methods.
    // IListItem methods.
    public getId(): number {
        return (this.id);
    }
    public setId(idParam: number): void {
        this.id = idParam;

        return;
    }

    public getOriginalVersionId(): number {
        return -1;
    }

    /*
    public getTitle(): string {
        let title: string =
            (this.description != null ? `${this.name} (${this.description})` : this.name);

        return (title);
    }
    */

    public getRootFolder(): Folder {
        let rootFolder: Folder = null;

        if (this.folders != null) {
            for (let iFolderIndex: number = 0; iFolderIndex < this.folders.length; iFolderIndex++) {
                let folder: Folder = this.folders[iFolderIndex];

                if (folder.parentFolderId == null) {
                    rootFolder = folder;
                    if (this.siteIsAdministerable) {
                        rootFolder.canCreateFolder = true;
                        rootFolder.canCreateForm = true;
                    }

                    break;
                }
            }
        }

        return (rootFolder);
    }

    /*
    public deriveFolderPath(folderId: number): string {
        let folderPath: string = null;

        let ancestorFolders: Folder[] = [];
        let rootFolder: Folder = this.getRootFolder();

        if (rootFolder != null) {
            let foundFolder: Folder = DataCollection.findFolder(folderId, rootFolder, ancestorFolders);

            if (foundFolder != null) {
                folderPath = "/";

                for (let iAncestor: number = 0; iAncestor < ancestorFolders.length; iAncestor++) {
                    let ancestorFolder: Folder = ancestorFolders[iAncestor];

                    folderPath += ancestorFolder.name + "/";
                } // for

                folderPath += foundFolder.name;
            } // if
        } // if

        return (folderPath);
    }

    private static findFolder(folderId: number, parentFolder: Folder, ancestorFolders: Folder[]): Folder {
        let foundFolder: Folder = null;

        if (parentFolder.childFolders != null) {
            for (let iChildIndex: number = 0; iChildIndex < parentFolder.childFolders.length; iChildIndex++) {
                let childFolder: Folder = parentFolder.childFolders[iChildIndex];

                if (childFolder.id == folderId) {
                    foundFolder = childFolder;

                    break;
                } else {
                    ancestorFolders.push(childFolder);

                    foundFolder = DataCollection.findFolder(folderId, childFolder, ancestorFolders);

                    if (foundFolder != null) {
                        break;
                    } else {
                        ancestorFolders.pop();
                    } // if-else
                } // if-else
            } // for 
        } // if

        return (foundFolder);
    }
    */

    public userCanDelete(): boolean {
        return (this.siteIsAdministerable);
    }

    public getStatus(): string {
        return (this.currentStatus);
    }

    public getExtraInfo(): string {
        return "";
    }
    public getValue(property: string): string {
        return "";
    }

    //TEAMS-424: KLW - return the object type
    public getType(): string {
        return ItemTypeEnum.DATACOLLECTION;
    }

    public getPosition(): number {
        return this.id; // When displayed in a list, barring another sort criteria, this forms a default
    }

    public canBeDeleted(): boolean { // Note:  this will have to be fixed to use
        //        a value returned by the server.
        return (true);
    }

    public getIconType(): IconFormatEnum {
        return IconFormatEnum.MAT_ICON;
    }
    public getIcon(): string {
        return ('collections'); // Returns the default icon for a site/data collection.
    }

    public getUniqueId(): string { // For interface IListItem
        let uniqueId: string = `${this.id}-${this.getType()}`;

        return (uniqueId);
    }

    public getChildCount(): number {
        throw new Error('Method not implemented.');
    }
    public getModifiedBy(): string {
        return this.modifiedBy;
    }
    public getModifiedDate(): Date {
        return this.modifiedDate;
    }

    public update(obj: any, type: string, icon?: string, position?: number): void {
        // TO DO:  DISCUSS WITH PAUL.
        //
        // NOTE:  I BELIEVE THIS CAN BE A NOOP SINCE
        //        THE OBJECT WILL ALWAYS BE CONSISTENT
        //        WITH ITSELF/NO NEED TO UPDATE/SYNC.

        return;
    }

    public findAndUpdateFrom(items, obj: any): void {
        throw ('DataCollection.findAndUpdateFrom():  this method is not implemented.');
    }

    // Miscellaneous methods.
    public isValidWorkflowTransition(transitionName: string): boolean {
        return [
            this.SETUP_TO_ACTIVE,
            this.ACTIVE_TO_SETUP,
            this.ACTIVE_TO_CLOSED,
            this.CLOSED_TO_ACTIVE].indexOf(transitionName) > -1;
    }

    public foo() {
        //console.log('foo');
    }

    public getParentId(): number {
        throw new Error('Method not implemented.');
    }
}
