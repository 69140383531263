import { typeofExpr } from '@angular/compiler/src/output/output_ast';

/*
 * Utility class that provides static methods to facilitate keyboard access to all UI features
 * of the application. 
 */

declare var $: any;

export class AccessibilityHelper {

    static readonly MOUSE_EVENT: string = 'mouse';
    static readonly KEYBOARD_EVENT: string = 'keyboard';

    // Use when a third-party library does not have a title attribute or something
    // Usage
    //  AccessibilityHelper.addAttribute('.ui-tree-filter', 'title', 'Filter Tree Input Field');
    public static addAttribute(element: string, attribute: string, value: string) {
        var retries = 0;
        var int = setInterval(() => {
            if ($(element).length > 0) {
                $(element).attr(attribute, value);
                clearInterval(int);
            } 
            retries++;
            if (retries > 100) clearInterval(int);
        }, 5);
    } 

    // It can take a few ms before element can be focused hence the retry logic 
    public static setFocus(element: string, wait: any = false) {
        var retries = 0;
        var int = setInterval(() => {
            if (!$(element).is(':focus')) {
                $(element).focus();
            } else {
                clearInterval(int);
            }
            retries++;
            if (retries > 100) clearInterval(int);
        }, 5);
        return;

        // old but keeping around in case
        var timeout = wait === true ? 250 : ((typeof wait === 'number') ? wait : 0);
        setTimeout(() => {
            if ($(element)) {
                console.log($(element).focus());
            }
        }, timeout);
        return;
    }

    // Focus on the main content div, unless this is the first navigation event in which
    // case focus should remain the default of the top of the page
    public static focusOnMainContent(): void {
        var mainContent = $('#main-content');
        //console.log({
        //    "UtilitityHelper.focusOnMain": mainContent,
        //    "this.isNotFirstNavigationEvent()": this.isNotFirstNavigationEvent(),
        //    "this.originalEventIsKeyboardEvent()": this.originalEventIsKeyboardEvent(),
        //    "history.state.originalEvent": history.state.originalEvent
        //});

        if (mainContent &&
            this.isNotFirstNavigationEvent() &&
            this.originalEventIsKeyboardEvent()) {
            mainContent.focus();
        }
    }

    // The setting of history.state.originalEventType is not automatic, but has to be done
    // on the router when navigating from ComponentA to ComponentB
    // Example usage in ComponentA:
    // ...
    // this.router.navigateByUrl(theUrl, { state: { originalEvent: event.type } });
    // ...
    // where event is the actual event (MouseEvent or KeyboardEvent)
    // See https://netbasal.com/set-state-object-when-navigating-in-angular-7-2-b87c5b977bb
    //
    // TODO: Reimplement to expect an actual MouseEVent or KeyboardEvent object and
    // dig into its attributes -- because when a <button> received an ENTER keyup, it
    // actually gets a MouseEvent
    public static originalEventIsKeyboardEvent() {
        return history.state == null ? false : history.state.originalEventType == this.KEYBOARD_EVENT;
    }

    public static isNotFirstNavigationEvent() {
        return history.state == null ? false : history.state.navigationId > 1;
    }

    // when navigating by keyboard sometimes hitting Enter on an element opens a drawer or dialog
    // and hitting Enter to close the drawer/dialog re-fires on the original element. This method
    // prevents that happening...
    // Usage
    // myEnterKeyHandler(evt: any){
    //      KeyboardAccessHelper.preventDoubleHitOnEvent(evt, $('#optionalElementIWantToFocusOn'));
    //      ... event handling logic ...
    // }
    public static preventDoubleHitOnEvent(event: any, element: string = null) {
        if (event.target != null) {
            event.target.blur();
        }
        if (element != null) this.setFocus(element);
    }

    public static deduceEventType(event: any): string {
        var type: string = null;
        // Pressing Enter when a <button> is focused emits a MouseEvent of type click
        // so need to dig into the Event to determine if it really was triggered by Mouse or Keyboard
        if (event instanceof MouseEvent) {
            if (event.clientX == 0 && event.clientY == 0 && event.pageX == 0 && event.pageY == 0) {
                type = AccessibilityHelper.KEYBOARD_EVENT;
            } else {
                type = AccessibilityHelper.MOUSE_EVENT;
            }
        } else if (event instanceof KeyboardEvent) {
            type = AccessibilityHelper.KEYBOARD_EVENT;
        }
        return type;
    }
}
