import {
    Component,
    OnInit,
    Renderer2,
    Output,
    EventEmitter,
    Type as AngularCoreType,
    ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';

import { FormFieldMode } from '../form-field-mode.enum';
import { ControlType, FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { GridFormInstanceElementWrapper } from '../../../models/grid/grid-form-instance-element-wrapper.model';
import { TextInputFormFieldBaseComponent } from '../input-form-field-base/text-input-form-field-base.component';
import { IAutoCompleteItemData } from '../../../interfaces/iautocomplete-item-data.interface';
import { string } from 'mathjs';
import { AutocompleteComponent } from '../../autocomplete/autocomplete.component';

class ConfiguredOption {
    public OptionId: number;
    public OptionText: string;
}

@Component({
    selector: 'app-drop-down-form-field',
    templateUrl: './drop-down-form-field.component.html',
    styleUrls: ['./drop-down-form-field.component.scss', '../form-fields.scss'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DropDownFormFieldComponent,
            multi: true
        }
    ]
})
export class DropDownFormFieldComponent extends FormFieldBaseComponent implements OnInit {
    // Properties.
    // Note:  several properties are implemented in my base class.
    @Output() onInit = new EventEmitter();
    @Output() onPropertyUpdated = new EventEmitter();

    //VNEXT-538: KLW - Implementing Autocomplete
    @ViewChild('singledropdownauto') autoCompleteObject: AutocompleteComponent;

    private readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.NAME,
            FormFieldPropertyEnum.FIELD_GROUP,
            FormFieldPropertyEnum.REQUIRED,
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.BLANK_VALUE,
            FormFieldPropertyEnum.HELP_TEXT,
            FormFieldPropertyEnum.PLACEHOLDER_TEXT,
            FormFieldPropertyEnum.TOOL_TIP,
            FormFieldPropertyEnum.DEFAULT_VALUE,
            FormFieldPropertyEnum.SELECT_OPTIONS,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT,
            // FormFieldPropertyEnum.AUTOCOMPLETE, //VNEXT-538: KLW - Implementing Autocomplete             
            // FormFieldPropertyEnum.AUTOCOMPLETE_STARTSWITH, //VNEXT-538: KLW - Refinements to the Type ahead functionality
            // FormFieldPropertyEnum.AUTOCOMPLETE_CONTAINS, //VNEXT-538: KLW - Refinements to the Type ahead functionality
        ];

    //public DefaultDropdownValue: string = 'DEFAULT_VALUE';
    public DefaultDropdownValue: number = -1;
    private strDefaultText: string = 'No Options Configured';
    //VNEXT-561: KLW - Needed for form group
    private formGroupName: string = 'dropdown_form';

    private arrConfiguredOptions: ConfiguredOption[] =
        [
            { OptionId: 0, OptionText: '' },
            { OptionId: 1, OptionText: '' }
        ];
    private iNextConfiguredOptionIndex = 1;

    // Constructor.
    constructor(private renderer: Renderer2) {
        super();

        return;
    }

    //VNEXT-538: KLW - Implementing Autocomplete
    public selectChange = (event: any) => {
        if (event.length > 0)
            this.FormControl.setValue(event[0]);
        else
            this.FormControl.setValue(null);
    };

    // Implement abstract methods.
    public getProperties(): any {
        let hshProperties =
        {
            component: this,
            formField: this.formField,
            properties: this.formFieldProperties,

            propertyUpdateRequired: true
        };

        return (hshProperties);
    }

    // Life cycle methods.
    public ngOnInit(): void {
        // Notify my parent of my properties.
        let hshProperties = this.getProperties();

        this.onInit.emit(hshProperties);

        if (this.Mode === 'design') {
            // In case we have selected options, try
            // to make sense of our configuration.
            this.propertyUpdated(this.formField, 'selectOptions');
        }

        return;
    }

    // HTML accessor methods.
    // 02-24-2020 note:  disabled the following code that used to show the default
    //                   drop-down value in design mode as it started generating an
    //                   "value updated before it could be displayed".
    //
    //                   This functionality could be restored by hooking into the
    //                   properties drawer value changes, but it's not worth the
    //                   time/expense.
    /*
    get DefaultDropdownValue(): string {
        //let strDefaultValue: string = 'DEFAULT_VALUE';

        return (this.strDefaultValue);
    }

    set DefaultDropdownValue(strIgnoredValue: string) {
        return;
    }
    */

    public get DefaultDropdownValueText(): string {
        return (this.strDefaultText);
    }

    public get ConfiguredOptions(): ConfiguredOption[] {
        return (this.arrConfiguredOptions);
    }

    // Handle the propertyUpdated() callback
    // that was requested in the onOnit() data:
    //
    //     propertyUpdateRequired: true
    public propertyUpdated(formField: FormField, propertyName: string): void {
        let astrSelectOptions: string[] = this.SelectOptions;
        let strNewOptionText: string = '';

        if ((propertyName === 'selectOptions') ||
            (propertyName === 'defaultValue') ||
            (propertyName === 'all')) {
            if ((astrSelectOptions !== undefined) &&
                (astrSelectOptions !== null) &&
                (astrSelectOptions.length > 0)) {
                if (this.FormField &&
                    (this.FormField.defaultValue !== undefined) &&
                    (this.FormField.defaultValue !== null) &&
                    (this.FormField.defaultValue.trim() !== '')) {
                    let bDefaultIsValid: boolean = false;

                    for (let iSelectOption: number = 0; iSelectOption < astrSelectOptions.length; iSelectOption++) {
                        let strSelectOption: string = astrSelectOptions[iSelectOption];

                        if (strSelectOption === this.FormField.defaultValue) {
                            bDefaultIsValid = true;

                            break;
                        }
                    }

                    if (bDefaultIsValid) {
                        strNewOptionText = this.FormField.defaultValue;
                    } else {
                        strNewOptionText = `Default value '${this.FormField.defaultValue}' is invalid`;
                    }
                } else {
                    strNewOptionText = astrSelectOptions[0];
                }
                this.setDisplayedConfiguredOptionValue(strNewOptionText);
            } else {
                this.setDisplayedConfiguredOptionValue('');
                this.setDisplayedConfiguredOptionValue(''); // Yes, twice called.
                this.DefaultDropdownValue = -1;
            }
        }

        if ((propertyName == 'selectOptions') ||
            (propertyName == 'selectOptionsConstraintName')) {
            this.onPropertyUpdated.emit(this.formField);
        }

        return;
    }

    private setDisplayedConfiguredOptionValue(value: string): void {
        this.iNextConfiguredOptionIndex = (this.iNextConfiguredOptionIndex == 1 ? 0 : 1);
        this.arrConfiguredOptions[this.iNextConfiguredOptionIndex].OptionText = value; //strNewOptionText;
        this.DefaultDropdownValue = this.iNextConfiguredOptionIndex;
    }

    /*
    get DefaulDropdownValueText(): string {
        // Set a timer to recalculate this value.
        setTimeout(() => {
            this.recalculateDefaultDropdownValueText();
        }, 0);

        // Return the value immediately.
        return (this.strDefaultText);
    }
    */

    /*
    private recalculateDefaultDropdownValueText(): void {
        //let strDefaultText: string = 'No Select Options Configured';

        let astrSelectOptions: string[] = this.SelectOptions;

        if ((astrSelectOptions !== undefined) && (astrSelectOptions !== null) && (astrSelectOptions.length > 0)) {
            if (this.FormField &&
                (this.FormField.defaultValue !== undefined) &&
                (this.FormField.defaultValue !== null) &&
                (this.FormField.defaultValue.trim() !== '')) {
                let bDefaultIsValid: boolean = false;

                for (let iSelectOption: number = 0; iSelectOption < astrSelectOptions.length; iSelectOption++) {
                    let strSelectOption: string = astrSelectOptions[iSelectOption];

                    if (strSelectOption === this.FormField.defaultValue) {
                        bDefaultIsValid = true;

                        break;
                    }
                }

                if (bDefaultIsValid) {
                    this.strDefaultText = this.FormField.defaultValue;
                } else {
                    this.strDefaultText = `Default value '${this.FormField.defaultValue}' is invalid`;
                }
            } else {
                this.strDefaultText = astrSelectOptions[0];
            }
        }

        //return (strDefaultText);
        return;
    }
    */

    // Handle getting this field's form instance element.
    protected formInstanceElementReceived(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                if (this.FormInstanceElement.UserUpdatedData != true) {
                    this.setDefaultTextSelectValue();
                }

                // Use a base class method to
                // set up a textual form group.
                this.setupTextFormGroup('dropdown_form');
            }
        }

        return;
    }

    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    protected writeValueTriggered(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                this.SetupFormGroupFromWriteValue(this.formGroupName);

                if (this.autoCompleteObject != null) {
                    var toPass: IAutoCompleteItemData[] = [this.FormControl.value];

                    this.autoCompleteObject.setIsRequired(this.FormField.required);
                    this.autoCompleteObject.setData(toPass, true);
                    this.autoCompleteObject.setFIE_ID(this.formField.id);
                    this.autoCompleteObject.setHasStartsWith(this.formField.autocomplete_StartsWith);
                    this.autoCompleteObject.setHasContains(this.formField.autocomplete_Contains);
                    this.autoCompleteObject.setShowChipList(false);
                }
            }
        }

        return;
    }

    // Override notifyValueChanged in order to allow field conditional logic to trigger on a value change.
    protected notifyValueChanged(): void {
        super.notifyValueChanged();

        super.handleOnBlur();
    }

    // Override the getDisplayValue() base class method.
    // Define a method that allows a component to return its display value.
    public pseudoStatic_getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum): string {
        //if ((!formInstanceElementParam.transientValueSetFlag) ||
        if ((!formInstanceElementParam.UserUpdatedData) ||
            (!formInstanceElementParam.textValue)) {
            // Set a default value.
            formInstanceElementParam.TextValue = '';
        }

        return (formInstanceElementParam.textValue);
    }

    public pseudoStatic_pasteValue(value: string, elementWrapper: GridFormInstanceElementWrapper, formField: FormField): void {
        TextInputFormFieldBaseComponent.pasteValue(value, elementWrapper);
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (DropDownFormFieldComponent);
    }
}
