<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>
<ng-template #tooltipTemplate><ng-content select="[projectedTooltip]"></ng-content></ng-template>

<ng-template #noAutoComplete>

    <div>
        <!-- --------------------------- DESIGN MODE --------------------------------- -->
        <div *ngIf="this.Mode === 'design'" class="flex-row-top-right">
            <div class="fill-remaining-space">
                <ng-container *ngIf="this.HasInstructions">
                    <div class="design-mode-field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                </ng-container>

                <div>
                    <mat-form-field appearance="outline" class="dropdown-mat-form-field">
                        <mat-label class="design-mode-field-label" *ngIf="this.ShowLabel && !HasInstructions">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></mat-label>

                        <mat-select disabled [(ngModel)]="this.DefaultDropdownValue">
                            <mat-option [value]="-1">{{this.DefaultDropdownValueText}}</mat-option>

                            <mat-option *ngFor="let hshOption of this.ConfiguredOptions" [value]="hshOption.OptionId">
                                {{hshOption.OptionText}}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>
                </div>
                <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
        </div>

        <!-- --------------------------- PREVIEW/INSTANCE MODE --------------------------------- -->
        <div *ngIf="(this.Mode === 'preview') || (this.Mode === 'instance')" class="flex-row-top-right">

            <div class="fill-remaining-space">
                <ng-container *ngIf="this.FormGroup !== null">
                    <!-- If there are instructions, display label first, then instructions then field -->
                    <ng-container *ngIf="this.HasInstructions">
                        <div class="field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                        <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                    </ng-container>

                    <!-- SINGLE SELECT -->
                    <form [formGroup]="this.FormGroup">
                        <mat-form-field *ngIf="IsSingleSelect" appearance="outline" [floatLabel]="'always'">
                            <!-- If there are no instructions, display label integrated into the field -->
                            <mat-label *ngIf="this.ShowLabel && !this.HasInstructions && !FieldIsInGrid" class="field-label">
                                {{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span>
                            </mat-label>
                            
                            <mat-select formControlName="flexible_single_selection_form"
                                        [disabled]="this.ReadOnly"
                                        (blur)="this.handleValidationOnBlur()"
                                        (selectionChange)="handleSingleSelectChange($event)">

                                <mat-option value="" *ngIf="this.SelectOptions.length > 1">{{DropdownPlaceHolderText}}</mat-option>
                                <mat-option *ngFor="let strSelectOption of this.SelectOptions"
                                            title="{{strSelectOption}}"
                                            [value]="strSelectOption">
                                    {{strSelectOption}}
                                </mat-option>
                            </mat-select>

                        </mat-form-field>
                        <!--
                            <div *ngIf="GridRowsImpactedBySelectionChange" style="color:red;">
                                This change may have invalidated dependent fields in a Grid! Be sure to set valid values in any dependent fields in Grids to avoid validaton errors.
                            </div>
                        -->
                    </form>

                    <!-- MULTI SELECT TODO: make it support limiting the number of selections that can be made per FormField.MaxSelections-->
                    <mat-form-field *ngIf="IsMultiSelect" appearance="outline" [floatLabel]="'always'" class="drop-down-mat-form-field full-width">
                        <!-- class="{{this.Mode =='design' ? 'design-mode-field-label' : 'field-label'}}" -->
                        <mat-label *ngIf="this.ShowLabel && !this.HasInstructions && !FieldIsInGrid" class="field-label">
                            {{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span>
                        </mat-label>

                        <mat-chip-list [formControl]="this.DropDownOptionsFormControl">
                            <mat-chip *ngFor="let selected of this.DropDownOptionsFormControl.value"
                                      [removable]="true"
                                      (removed)="onRemove(selected)"
                                      class="mat-elevation-z2 regular-chip">
                                {{ selected }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>

                            <mat-chip class="mat-elevation-z3 remove-all-chip" *ngIf="this.ShowRemoveAll" (click)="this.removeAllChips()" (keyup.enter)="this.removeAllChips()">
                                Remove All
                            </mat-chip>
                        </mat-chip-list>

                        <mat-select #multiSelect [formControl]="this.DropDownOptionsFormControl" multiple (blur)="this.handleValidationOnBlur()" [disableOptionCentering]="true" tabindex="0" class="highlight-when-focused">
                            <mat-select-trigger><!-- keep this even though blank --></mat-select-trigger>

                            <mat-option *ngFor="let strSelectOption of this.SelectOptions" [disabled]="this.IsDesign" [value]="strSelectOption">
                                {{strSelectOption}}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>
                </ng-container>
                <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
        </div>
    </div>

</ng-template>

<ng-template #singleDropDown>
    <div *ngIf="this.AutocompleteEnabled && !this.IsDesign; else noAutoComplete">
        <app-autocomplete #singledropdownauto
                          [title]="this.DropDownOptionsTitle"
                          (selectedItems)="selectChange($event)"
                          (onBlur)="this.blurValidation()">
        </app-autocomplete>
    </div>
    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
</ng-template>

<div class="outer-div">
    <div class="flex-row-top-right">

        <div class="fill-remaining-space">
            <ng-template [ngTemplateOutlet]="singleDropDown"></ng-template>
        </div>

        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
    </div>
</div>
