import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface HasUnsavedChanges {
    hasUnsavedChanges: () => boolean; // | Observable<boolean>;
}

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<HasUnsavedChanges> {

    // See https://stackoverflow.com/questions/35922071/warn-user-of-unsaved-changes-before-leaving-page
    canDeactivate(component: HasUnsavedChanges): Observable<boolean> | boolean {
        return component.hasUnsavedChanges() ?
            confirm("It appears you have made changes. Click Cancel to stay on this page, or click OK to navigate away and lose any changes.") :
            true;
    }

}
