import { Component, OnInit, AfterViewInit, AfterContentInit, Output, EventEmitter, ViewChild, ViewChildren, ViewContainerRef, QueryList, ComponentFactoryResolver, Renderer2, ComponentRef, Type as AngularCoreType, ElementRef, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';

import { DateAdapter } from '@angular/material/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { MatMenuTrigger } from '@angular/material/menu';
import { TooltipPosition } from '@angular/material/tooltip';

import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { IFieldNameToFormField, IFormFieldComponent } from '../../../interfaces/iform-field-component';
import { FormField, SecondaryGridCascadingFieldTransientData } from '../../../models/form-builder/form-field.model';
import { GridRowViewModel } from '../../../models/form-builder/grid-row.model';
import { FormInstanceElement, FormInstanceElementValueTypeEnum } from '../../../models/form-builder/form-instance-element.model';
import { IFormFieldDisplayHint } from '../form-field-base/form-field-base.component';
import { DynamicComponentHostDirective } from '../../../directives/dynamic-content-host.directive';
import { FieldDefinition } from '../../../models/form-builder/field-definition.model';
import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
import { FieldDefinitionService } from '../../../services/field-definition.service';
import { FormFieldTypeAndNameService } from '../../../services/form-field-type-and-name.service';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormInstanceElementRequest } from '../../../models/form-instance-element-request.model';
import { ProgressBarConstants } from '../../../enums/progress-bar-constants.enum';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { ComponentAndFormField } from '../../../models/grid/component-and-form-field-model';
import { RuntimeMetadata } from '../../../models/grid/runtime-metadata.model';
import { GridRuntimeData } from '../../../models/grid/runtime-data.model';
import { GridCellContextMenuInfo } from '../../../models/grid/cell-context-menu-info.model';
import { LoadingDataProgressInfo } from '../../../models/grid/loading-data-progress.model';
import { FieldTypeAndName, IFieldTypeToFieldInfo, } from '../../../services/form-field-type-and-name.service';
import { CachedFormFieldService } from '../../../services/form-field-with-cache.service';
import { FormFieldConstraintLiaisonManager } from '../../form-builder/properties/form-field-properties/constraints/field-constraint-liaison-manager';
import { CurrentSiteService } from '../../../services/current-site.service';
import { CurrentUserService } from '../../../../security/current-user.service';
import { CsvOptionsFileData } from '../../../models/csv-data/csv-options-file-data.model';
import { FixedFirstColumnValues } from '../../../models/grid/fixed-first-column-values.model';

import { ShortTextFieldType, RichTextFieldType } from '../../../models/form-builder/form-field-types';

import { GridConfig } from '../../../models/grid/grid-config.model';
import { GridFormInstanceElementWrapper } from '../../../models/grid/grid-form-instance-element-wrapper.model';
import { IGetCellDisplayValue } from '../../../models/grid/grid-interfaces';
import { GridRowDef } from '../../../models/grid/grid-row.model';
import { GridAllModesDataSource } from '../../../models/grid/grid-data-source.model';
import { AttachmentService } from '../../../services/attachment.service';
import { FormFieldOnInitPropertyEnum } from '../../../models/form-builder/form-field-on-init-output-property.enum';
import { UtilityHelper } from '../../../utility.helper';
import { IFormFieldConstraint } from '../../../interfaces/iform-field-constraint.interface';
import { IFormFieldConstraintLiaison } from '../../form-builder/properties/form-field-properties/constraints/ifield-constraint-liason.interface';
import { FormFieldConfigurationSaveReason } from '../../../enums/form-field-save-config-reason.enum';
import { SortDirection } from '../../../enums/sort-direction.enum';
import { GridFieldDesignerComponent } from '../grid-field-designer/grid-field-designer.component';
import { GridFieldEditorComponent } from '../grid-field-editor/grid-field-editor.component';

// Define constants used below.
const GRID_ROW_ID_KEY: string = 'gridRowId';
const GRID_COLUMN_ID_KEY: string = 'gridColumnId';
const DEFAULT_UNSELECTED_ROW_HEIGHT: number = 48;

// Implement the grid form field component.
//
// Note:  please note the 'providers' definition below, as it is needed.
//        Without it, you will get the following exception:
//
//             No value accessor for form control with unspecified name
//
// The above exception gets thrown when a component, in this case our
// base class, implements interface 'ControlValueAccessor' and does not
// provide the 'providers' definition below.  Implementing the
// 'ControlValueAccessor' interface allows a form field component to
// support [(ngMode)], so users of the component can use [(ngModel)].
@Component({
    selector: 'app-grid-form-field',
    templateUrl: './grid-form-field.component.html',
    styleUrls: ['./grid-form-field.component.scss', '../form-fields.scss'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: GridFormFieldComponent,
            multi: true
        }
    ]
})
export class GridFormFieldComponent extends FormFieldBaseComponent implements OnInit, AfterViewInit, AfterContentInit, IGetCellDisplayValue {
    // Properties.
    @Input() showExportToExcelButton: boolean = true;
    @Input() isFootnotesGrid: boolean = false;

    // Note:  several properties are implemented in my base class.
    @Output() onInit = new EventEmitter();
    // Emitted by design mode changes like adding/deleting/reordering a column
    @Output() designChange = new EventEmitter();
    // Request a form instance element load.
    @Output() loadFormInstanceElement = new EventEmitter();
    @Output() onEdit = new EventEmitter(); // For editing a column's properties.

    //TEAMS-894: KLW - Needed for grid validation
    @Output() mainGridValidation = new EventEmitter();

    // Define read only data.
    private readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT,
            FormFieldPropertyEnum.FIXED_FIRST_COLUMN_FILE,
            FormFieldPropertyEnum.FOOTER,
            FormFieldPropertyEnum.EXCEL_IMPORT_EXPORT_TEMPLATE,
            FormFieldPropertyEnum.DISPLAY_KENDO_GRID_ROWS, //VNEXT-980: KLW - Property to set the number of Kendo grid rows to display
            FormFieldPropertyEnum.GRID_KEY_COLUMNS, // Note:  related to environmental justice (EJ) requirements for importing grid data from Excel files.
            FormFieldPropertyEnum.HIDE_GRID_FILTERING, // https://maxjira.max.gov/browse/VNEXT-1252
            FormFieldPropertyEnum.HIDE_GRID_BODY // VNEXT-1378
        ];

    // Column definitions.
    // Note:  the following initialization is now performed in the grid form field's constructor.
    private gridConfig: GridConfig = null;

    // Design mode data structures.
    private allModesDataSource: GridAllModesDataSource = null;

    private runtimeMetadata: RuntimeMetadata = new RuntimeMetadata();
    private runtimeData: GridRuntimeData = new GridRuntimeData();

    private formFieldDisplayHints: IFormFieldDisplayHint = {};

    // This references all of the templates in a row which look like this in the HTML:
    // <ng-template dynamic-component-host name="t-{{gridRow.RowIndex}}-{{hshColumnDef.fieldOrder}}"></ng-template>
    @ViewChildren(DynamicComponentHostDirective, { read: DynamicComponentHostDirective }) dynamicComponentHosts: QueryList<DynamicComponentHostDirective>;
    @ViewChild(GridFieldEditorComponent, { read: GridFieldEditorComponent }) gridFieldEditorComponent: GridFieldEditorComponent;

    // Define instance data used with a MatPaginator.
    @ViewChild('matPaginatorMatFooter', { read: MatPaginator }) matPaginator;
    private loadingDataProgress: LoadingDataProgressInfo = new LoadingDataProgressInfo();
    // 01-20-2021 note:  added the following instance variable.
    //
    //                   Might want to revisit the grid's direct
    //                   use of the service as it is not
    //                   consistent with existing architectural
    //                   norms.
    //
    //                   Will consider other options and revisit.
    //private formInstanceService: FormInstanceService = null;
    // End instance data used with a MatPaginator.

    // Define properties related to a design mode context menu.
    @ViewChild(MatMenuTrigger)
    gridCellContextMenu: MatMenuTrigger;
    // End properties related to a design mode context menu.

    @ViewChildren(FormFieldBaseComponent, { read: FormFieldBaseComponent }) formFieldComponentsQueryList: QueryList<FormFieldBaseComponent>;

    // Constructor.
    public constructor(private resolver: ComponentFactoryResolver,
        private hostElement: ElementRef,
        private renderer: Renderer2,
        private attachmentService: AttachmentService, //TEAMS-424: KLW - Need reference to attachment service
        private dialog: MatDialog, //TEAMS-424: KLW - Need reference to Material Dialog
        private cachedFormFieldConstraintsService: CachedFormFieldService,
        private currentSiteService: CurrentSiteService,
        private currentUserService: CurrentUserService,
        private dateAdapter: DateAdapter<Date>,
        private fieldDefinitionService: FieldDefinitionService,
        private formFieldTypeAndNameService: FormFieldTypeAndNameService) {
        super();

        if (this.currentSiteService.Site.betaFeaturesEnabled) {
            this.formFieldProperties.push(FormFieldPropertyEnum.READ_ONLY);
        }

        // Create a blank config.
        this.createEmptyGridConfig();

        this.formFieldTypeAndNameService.initializeFieldTypesAndNames(this.renderer, this.dateAdapter, this.attachmentService, this.dialog);

        // Define a display hint to pass to contained form fields.
        this.formFieldDisplayHints["showLabel"] = false;

        return;
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshEventProperties = {
            component: this,
            formField: this.FormField,
            properties: this.formFieldProperties,
            displayFormatValues: null,

            saveConfigurationRequired: true,
            saveDataRequired: true,

            propertyUpdateRequired: true
        };

        return (hshEventProperties);
    }

    // Life cycle methods.
    public ngOnInit(): void {
        // Display note to discuss/to do item:
        //
        // Presently a grid form field displays as a toolbar, height 40px,
        // a header row, height 56px, and one or more rows, each with a
        // default height of 84px.  That adds up to 56 + 84 = 140px for a
        // Mat/Material Table with one row.  The enclosing <div/> appears
        // to need 10px more to not show the vertical scroll bar.
        //
        // So if we give the user the choice regarding how many rows to
        // accommodate before showing the table's vertical scroll bar,
        // the <div/> containing the grid's <mat-table/> should have the
        // following height:
        //
        //     56 + (NUM_ROWS * 84) + 10.
        //
        // Will discuss this with the team on Friday before implementing.

        this.removeAnyExistingVirtualColDefs(); // Note:  is this line still needed?  Will plan to test without it enabled.

        // Note:  even though a blank config is created in this class's
        //        constructor, we need to create a blank config here.
        this.createEmptyGridConfig();

        // If we have an existing grid configuration, use it.
        if ((this.FormField.childFormFields) && (this.FormField.childFormFields.length > 0)) {
            if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
                this.createAnyVirtualColDefs();
            }
            this.createGridConfig();

            // Do we have a fixed first column?
            if (this.hasFixedFirstColumnJsonConfig) {
                // Note:  the following line makes it easier to check if a grid has a fixed first column configuration.
                this.FormField.fixedFirstColumnJson = this.gridConfig.getColumnDef(0).fixedFirstColumnJson;
                this.FormField.transientFixedFirstColumnValues = FixedFirstColumnValues.createFromJson(this.gridConfig.getColumnDef(0).fixedFirstColumnJson);
            }
        }

        // Create data structures for our given operating mode.
        this.allModesDataSource = new GridAllModesDataSource(this.gridConfig, this);

        // Let my parent component know my configuration.
        let hshEventProperties = this.getProperties();

        this.onInit.emit(hshEventProperties);

        // 10-06-2020 note:  adding a grid row in 'preview' mode
        //                   is now handled in the large block below.
        if (this.Mode === 'design') {
            this.allModesDataSource.addRow();

            // Set a form field constraint updated callback.
            this.FormField.setConstraintUpdatedCallback(this.formFieldConstraintUpdated);
            this.FormField.setAcceptsConstraintUpdateFrom(this.acceptsConstraintUpdateFrom);
        }

        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            const styles = getComputedStyle(this.hostElement.nativeElement);
            this.runtimeData.gridWidth = parseInt(styles.width);

            // 10-06-2020 new code:  adding code to handle form field value updates
            //                       for cases where a field, e.g.a formula form field,
            //                       needs to 'listen' to value changes in other form fields.
            if (this.FormField.childFormFields != null) {
                // In 'instance'/'preview' modes, only the two component arrays need to be tracked.
                this.runtimeMetadata.allocateAllComponentsList();

                for (let iChildFF: number = 0; iChildFF < this.FormField.childFormFields.length; iChildFF++) {
                    let childFF: FormField = this.FormField.childFormFields[iChildFF];

                    let fieldTypeAndName: FieldTypeAndName = this.formFieldTypeAndNameService.getFieldTypeAndField(childFF.fieldDefinitionClassName);
                    if (!fieldTypeAndName) {
                        let errorMsg: string = `GridFormFieldComponent.ngOnInit():  cannot find a component representative for ` + `field definition class name '${childFF.fieldDefinitionClassName}'.`;
                        super.raiseException(errorMsg);
                    } else if (!fieldTypeAndName.componentRepresentative) {
                        let errorMsg: string = `GridFormFieldComponent.ngOnInit():  the component representative for ` + `field definition class name '${childFF.fieldDefinitionClassName}' is null.`;
                        super.raiseException(errorMsg);
                    }

                    fieldTypeAndName.componentRepresentative.FormField = childFF; // Added 10-16-2020.
                    this.runtimeMetadata.addToAllComponentsList(fieldTypeAndName.componentRepresentative, childFF);

                    // Note:  presently only the formula form field uses properties
                    //        'formFieldNamesRequired' and 'formFieldValueUpdatesRequired',
                    //        so it might make sense to consolidate the lists for now.
                    let hshChildFFProperties = fieldTypeAndName.componentRepresentative.getProperties();

                    // Grid row height
                    if (hshChildFFProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT]) {
                        let iHeightRequired: number = hshChildFFProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT];

                        if (iHeightRequired > this.runtimeData.iMaxComponentPreviewInstanceHeightRequired) {
                            this.runtimeData.iMaxComponentPreviewInstanceHeightRequired = iHeightRequired;
                        }
                    }

                    // pharv - 01/28/2022 - new property that allows fields to specify height of unselected rows
                    // (Introduced for cascading dropdowns which need one row of text per level of cascades)
                    if (hshChildFFProperties[FormFieldOnInitPropertyEnum.ROWS_OF_TEXT_TO_DISPLAY_IN_UNSELECTED_GRID_ROW]) {
                        let rowsOfText: number = hshChildFFProperties[FormFieldOnInitPropertyEnum.ROWS_OF_TEXT_TO_DISPLAY_IN_UNSELECTED_GRID_ROW];

                        if (rowsOfText > this.runtimeMetadata.MaxRowsOfTextToDisplayOnUnselectedGridRows) {
                            this.runtimeMetadata.MaxRowsOfTextToDisplayOnUnselectedGridRows = rowsOfText;
                        }
                    }
                } // for

                // Do we have a footer for numeric totals?
                this.trackColumnTotalsIfSoConfigured();

                // Do we have a fixed first column?
                if (this.FormField.transientFixedFirstColumnValues != null) {
                    this.FormField.transientFixedFirstColumnValues = FixedFirstColumnValues.createFromJson(this.gridConfig.getColumnDef(0).fixedFirstColumnJson);

                    if (this.allModesDataSource.GridRowCount == 0) {
                        this.createFixedGridRows();
                    }
                }

                // A Minzy ask
                if (this.allModesDataSource.GridRowCount == 0) {
                    this.allModesDataSource.addRow();
                }
            } // if
        } // else-if

        return;
    }

    public ngAfterContentInit(): void {
        return;
    }

    // Override base class/FormFieldComponent methods.
    public isCompoundObjectComponent(): boolean {
        // Note:  this method is also used in apply conditional logic.
        return true;
    }
    public applyChildFieldAttributes(childFieldName: string, showChildField: boolean, childFieldIsReadOnly: boolean): void {
        // Try to pass this on to my grid field editor component.
        if (this.gridFieldEditorComponent != null)
            this.gridFieldEditorComponent.applyChildFieldAttributes(childFieldName, showChildField, childFieldIsReadOnly);
    }

    // Define methods called by my HTML code.
    public get FormField(): FormField {
        return this.formField;
    }
    public get GridConfig(): GridConfig {
        return this.gridConfig;
    }
    public get RuntimeMetadata(): RuntimeMetadata {
        return this.runtimeMetadata;
    }
    public get RuntimeData(): GridRuntimeData {
        return this.runtimeData;
    }

    public get AllModesDataSource(): GridAllModesDataSource {
        return this.allModesDataSource;
    }
    public get SelectedGridRowIndex(): number {
        return this.runtimeData.selectedGridRowIndex;
    }
    public get SectedGridColumnIndex(): number {
        return this.runtimeData.selectedGridColumnIndex;
    }

    public ColumnIsSticky(colName: string): boolean {
        // Note:  this does not need to be a hard code value, but it is.
        return true;
    }
    public get HeaderRowIsSticky(): boolean {
        return true;
    }

    public get ShowNumericTotalsFooter(): boolean {
        return (this.FormField ? this.FormField.showFooter === true : false);
    }
    public getNumericFooterTotalValueFor(colIndex: number, columnDef: FormField): string {
        // TO DO:  CODE THIS METHO PROPERLY.
        return 'Footer Value';
    }

    public getFormFieldFor(row: number, col: number): FormField {
        let cellName: string = this.getCellNameFor(row, col);
        let component: FormFieldBaseComponent = this.runtimeData.dynamicallyCreatedFormFieldsByName[cellName];
        return component.FormField;
    }
    public getCellNameFor(row: number, col: number): string {
        return `t-${row}-${col}`;
    }

    public get DeleteGridRowDisabled(): boolean {
        return (this.FormField.transientFixedFirstColumnValues != null);
    }

    public DynamicComponentHostNameFor(gridRow: GridRowDef, hshColumnDef: FormField, columnIndex: number): string {
        // From the previously inline HTML:

        let name = this.getCellNameFor(gridRow.RowIndex, hshColumnDef.fieldOrder);

        return name;
    }

    public getValidationErrorsForCell(row: number, col: number): string[] {
        let cellName = this.getCellNameFor(row, col);
        let rowErrors: string[] = this.runtimeData.invalidGridRows[row];
        if (rowErrors) {
            let currentFieldErrors = rowErrors[cellName];
            return currentFieldErrors ?? [];
        } else {
            return [];
        }
    }

    public get UseAlphaFeatures(): boolean {
        return (this.currentSiteService.Site.betaFeaturesEnabled && this.currentUserService.user.isSystemAdmin);
    }
    public get UseBetaFeatures(): boolean {
        return this.currentSiteService.Site.betaFeaturesEnabled;
    }

    public set FieldHasValidationError(value: boolean) {
        this.fieldHasValidationError = value;
    }

    public get UseGridDesignComponent(): boolean {
        // Note:  we can revert the following line to "return false" if we find problems with the grid design component.
        //return false;
        return true;
    }
    public get UseGridEditorComponent(): boolean {
        // Note:  we can revert the following line to "return false" if we find problems with the grid editor component.
        //return false;
        return true;
    }

    // Adds/Removes validation error messages to/from the invalidGridRows property
    private updateValidationErrors(row: number, col: number) {
        let cellName = this.getCellNameFor(row, col);
        let field: IFormFieldComponent = this.runtimeData.dynamicallyCreatedFormFieldsByName[cellName];
        let currentFieldErrors = field.getValidationErrors(true);
        let selectedGridRow: GridRowDef = this.allModesDataSource.getGridRow(this.runtimeData.selectedGridRowIndex);

        let rowErrors: string[] = this.runtimeData.invalidGridRows[row];
        if (currentFieldErrors.length > 0) {
            if (rowErrors == null) {
                rowErrors = [];
            }
            rowErrors[cellName] = currentFieldErrors;
            this.runtimeData.invalidGridRows[row] = rowErrors;
            selectedGridRow.IsInvalid = true;
        } else {
            // Delete validation errors that no longer apply
            if (rowErrors != null) {
                if (rowErrors.hasOwnProperty(cellName)) {
                    delete rowErrors[cellName];
                }
            }
            if (this.runtimeData.invalidGridRows.hasOwnProperty(row) && Object.keys(this.runtimeData.invalidGridRows[row]).length == 0) {
                delete this.runtimeData.invalidGridRows[row];
            }
            selectedGridRow.IsInvalid = false;
        }

        // Was getting ExpressionChangedAfterItHasBeenCheckedError
        //so bump this binding update to the next change detection loop
        UtilityHelper.runWhenStackClear(() => {
            this.fieldHasValidationError = Object.keys(this.runtimeData.invalidGridRows).length > 0; // note: the field being marked imvalid here is the grid as a whole
        });
        return currentFieldErrors;
    }

    // This overrides the method in the formfield base class
    // Note: this is gathering all validation errors for all fields in the whole grid
    public getValidationErrors(revealValidationErrors: boolean = false): string[] {
        let errorMessages: string[] = [];
        if (this.fieldHasValidationError && revealValidationErrors) {
            errorMessages.push("The following rows have validation errors. Please check and correct them.");
            for (let row of Object.keys(this.runtimeData.invalidGridRows)) {
                let r = parseInt(row) + 1;
                errorMessages.push('Row ' + r);
            }
        }
        return errorMessages;
    }

    private rowHasValidationErrors(row: number) {
        return this.runtimeData.invalidGridRows[row] != null
    }

    public getNumericTotalValue(iColIndex: number, colDef: FormField): string {
        // NOTE:  THIS METHOD RETURNS A BLANK FOR NON - NUMERIC COLUMNS BY DESIGN.
        let totalValue: string = '';

        if (this.runtimeMetadata && (this.runtimeMetadata.AllComponentsCount > 0)) {
            if ((iColIndex >= 0) && (iColIndex < this.runtimeMetadata.AllComponentsCount)) {
                let component: IFormFieldComponent = this.runtimeMetadata.getAllComponentsFormFieldComponent(iColIndex);

                if (component.hasNumericData()) {
                    let colTotal: number = this.allModesDataSource.getColumnTotal(colDef);

                    totalValue = `${colTotal}`;
                }
            }
        }

        return (totalValue);
    }

    public get ShowPaginator(): boolean {
        // Until the paginator is ready, return false.
        let show: boolean = false;
        if (this.allModesDataSource.GridRows != null) {
            if (this.allModesDataSource.GridRows.length >= this.loadingDataProgress.arrPageSizeOptions[0])
                show = true;
        }
        return show;
    }

    public get InstanceModePaginatorColCount(): number {
        let allColNames: string[] = this.GridColumnNamesWithActions;

        return (allColNames.length);
    }

    public get HasStickyHeaders(): boolean {
        return (this.ShowPaginator);
    }

    public get TotalRowCount(): number {
        let iTotalRowCount: number = 0;

        if (this.mode === 'instance') {
            if ((this.FormInstanceElement.totalChildGridRows !== undefined) &&
                (this.FormInstanceElement.totalChildGridRows !== null)) {
                iTotalRowCount = this.FormInstanceElement.totalChildGridRows;
            }
        }

        return (iTotalRowCount);
    }

    public get PageSize(): number {
        return (this.loadingDataProgress.iPageSize);
    }

    public get PageSizeOptions(): number[] {
        return (this.loadingDataProgress.arrPageSizeOptions);
    }

    public get ShowFirstLastButtons(): boolean {
        return (true);
    }

    public get IsLoadingGridData(): boolean {
        return (this.loadingDataProgress.isLoadingGridData);
    }

    public get LoadingDataText(): string {
        return (this.loadingDataProgress.loadingDataMessage);
    }

    public get LoadDataProgressMode(): ProgressBarMode {
        return (ProgressBarConstants.BUFFER_MODE);
    }

    public get LoadDataProgressValue(): number {
        return (this.loadingDataProgress.iLoadingDataProgressValue);
    }

    public get LoadDataProgressBufferValue(): number {
        return (this.loadingDataProgress.iLoadingDataProgressBufferValue);
    }

    public get LoadDataProgressColor(): ThemePalette {
        return (ProgressBarConstants.THEME_PALETTE_PRIMARY);
    }

    public get PaginatorDisabled(): boolean {
        return (this.loadingDataProgress.isLoadingGridData && (this.loadingDataProgress.iLoadingDataProgressValue != 100));
    }

    public FooterCellClass(colIndex: number): string {
        let cellClass: string = 'footer-cell';

        if (colIndex == 0)
            cellClass = 'first-footer-cell';

        return cellClass;
    }
    // End methods called by my HTML code.

    public handleColumnOrderUpdate() {
        this.designChange.emit();
    }

    // Handle MatPaginator events.
    public handlePageEvent(eventData: PageEvent): void {
        this.loadingDataProgress.isLoadingGridData = true;

        let request: FormInstanceElementRequest = new FormInstanceElementRequest();
        request.formInstanceElementId = this.FormInstanceElement.id;
        request.formInstanceId = this.FormInstanceElement.formInstanceId;
        request.pageSize = eventData.pageSize;
        request.pageIndex = eventData.pageIndex;

        let iLastRecordToLoad: number = ((request.pageIndex + 1) * request.pageSize);
        if (iLastRecordToLoad > this.TotalRowCount) {
            iLastRecordToLoad = this.TotalRowCount;
        }

        this.loadingDataProgress.loadingDataMessage = `Loading records ${(request.pageIndex * request.pageSize) + 1} ` + `to ${iLastRecordToLoad} ...`;
        this.loadingDataProgress.iLoadingDataProgressValue = 25;
        this.loadingDataProgress.iLoadingDataProgressBufferValue = 50;

        let loadRequest = {
            request: request,
            component: this
        };
        this.loadFormInstanceElement.emit(loadRequest);

        return;
    }
    // End handling MatPaginator events.

    // Implement base class method getDisplayValue().
    public pseudoStatic_getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum): string {
        let fieldTypeAndName: FieldTypeAndName = this.formFieldTypeAndNameService.getFieldTypeAndField(formFieldParam.fieldDefinitionClassName);

        let strValue: string = '';
        if (fieldTypeAndName != null) {
            let fieldDefinition: FieldDefinition = this.fieldDefinitionService.getFieldDefinition(fieldTypeAndName.fieldType);
            let fieldLogicHandler: IFieldDefinitionLogic = fieldDefinition.customLogicHandler;
            strValue = fieldLogicHandler.getDisplayValue(formFieldParam, formInstanceElementParam, gridRow, processingPhase);
        }

        return (strValue);
    }

    // Implement the IGetDisplayValue interface method.
    public isCalculatedField(iColumnIndex: number, formField: FormField): boolean {
        let bIsCalculatedField: boolean = false;

        if ((iColumnIndex >= 0) && (iColumnIndex < this.runtimeMetadata.AllComponentsCount)) {
            let component: FormFieldBaseComponent = this.runtimeMetadata.getAllComponentsFormFieldComponent(iColumnIndex);

            bIsCalculatedField = component.hasCalculatedValue();
        }

        return (bIsCalculatedField);
    }

    public getCellDisplayValue(iColIndex: number, formFieldParam: FormField, formInstanceElementWrapperParam: GridFormInstanceElementWrapper, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum): string {
        let formInstanceElement: FormInstanceElement = formInstanceElementWrapperParam.formInstanceElement;
        let strValue: string = this.pseudoStatic_getDisplayValue(formFieldParam, formInstanceElement, gridRow, processingPhase);
        return (strValue);
    }

    // Handle getting/receiving my form instance element.
    protected formInstanceElementReceived(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            // Ask my data source to deserialize any JSON grid data (might not be any).
            if (this.FormInstanceElement.childGridRows) {
                this.createConfigFromChildGridRows(this.FormInstanceElement.childGridRows);
                if (this.gridFieldEditorComponent != null)
                    this.gridFieldEditorComponent.parentReceivedFormInstanceElement(this.FormInstanceElement);
            }
        } // if 

        return;
    }

    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    protected writeValueTriggered(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            // Ask my data source to deserialize any JSON grid data (might not be any).
            if (this.FormInstanceElement.childGridRows) {
                this.createConfigFromChildGridRows(this.FormInstanceElement.childGridRows);
            }
            if (this.gridFieldEditorComponent != null) {
                this.gridFieldEditorComponent.parentReceivedFormInstanceElement(this.FormInstanceElement);
            }
        } // if 

        return;
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (GridFormFieldComponent);
    }

    public formInstanceElementLoaded(formInstanceElement: FormInstanceElement): void {
        if (formInstanceElement.childGridRows) {
            this.createConfigFromChildGridRows(formInstanceElement.childGridRows);
        }
    }

    // HTML accessor methods.
    public get DisplayName(): string {
        let displayName: string = null;

        if (this.FormField && this.FormField.displayName && (this.FormField.displayName.trim() !== '')) {
            displayName = this.FormField.displayName;
        } else {
            displayName = this.FormField.name;
        }

        return displayName;
    }

    public get FormFieldDisplayHints(): IFormFieldDisplayHint {
        return this.formFieldDisplayHints;
    }

    public get GridColumnDefs(): FormField[] {
        let colDefs = this.gridConfig.CachedGridColumnDefs;

        if (colDefs == null)
            colDefs = this.gridConfig.ColumnDefs;

        return colDefs;
    }

    public set GridColumnDefs(value: FormField[]) {
        this.gridConfig.CachedGridColumnDefs = value;
    }

    public GridColumnNames(bIncludeAddColumn: boolean = false): string[] {
        let astrColumnNames: string[] = this.gridConfig.GridColumnNames;
        astrColumnNames = this.GridColumnDefs.map(ff => ff.name);

        if (bIncludeAddColumn) {
            astrColumnNames.push("Add Grid Column");
        }

        return (astrColumnNames);
    }

    public GridColumnDisplayName(columnDef: FormField): string {
        let strDisplayName: string = columnDef.name;

        if ((columnDef !== undefined) &&
            (columnDef !== null) &&
            (columnDef.displayName !== undefined) &&
            (columnDef.displayName !== null) &&
            (columnDef.displayName.trim() !== '')) {
            strDisplayName = columnDef.displayName;
        }

        return (strDisplayName);
    }

    public get GridColumnNamesWithActions(): string[] {
        let astrColumnNames: string[] = this.GridColumnNames(false);

        astrColumnNames.push('Actions');

        return (astrColumnNames);
    }

    public get FieldTypesAndNames(): FieldTypeAndName[] {
        let fieldTypesAndNameFields = this.formFieldTypeAndNameService.getAllGridFieldTypesAndNameFields();
        return fieldTypesAndNameFields;
    }

    public getColumnTooltip(iColIndex: number, hshColumnDef: FormField): string {
        let columnTooltip: string = ''; // A default value.

        if (hshColumnDef.toolTip && (hshColumnDef.toolTip.trim() !== '')) {
            columnTooltip = hshColumnDef.toolTip;
        }

        return (columnTooltip);
    }

    public get TooltipPosition(): TooltipPosition {
        let position: TooltipPosition = 'below';

        return (position);
    }

    public getColumnTypeIconName(hshColumnDef: FormField): string {
        let strIconName: string = null;

        let fieldTypeAndName: FieldTypeAndName = this.formFieldTypeAndNameService.getFieldTypeAndField(hshColumnDef.fieldDefinitionClassName);
        if (!fieldTypeAndName) {
            let errorMsg: string =
                `GridFormFieldComponent.getColumnTypeIconName():  cannot find a field definition ` +
                `for field class '${hshColumnDef.fieldDefinitionClassName}'.`;
            this.raiseException(errorMsg);
        }
        strIconName = fieldTypeAndName.IconName;

        return (strIconName);
    }

    // pharv - 01/28/2022 - returns the standard class for all cells, plus adds additional ones based
    // on any particular needs of a field type
    public getUnselectedGridRowCellClass(gridRow: any, wrapper: GridFormInstanceElementWrapper): string {
        let cls = 'unselected-grid-row-cell-span';
        if (wrapper.fieldClass == RichTextFieldType) {
            cls += ' rich-text';
        }
        return cls;
    }

    public getGridRowStyle(gridRow: GridRowDef): string {
        // If style has already been set for this gridRow, simply return it, otherwise calculate it
        // This is needed because this method is called from the template per cell, not per row
        // and we don't want to repeat the same calculation for every cell in a row
        if (gridRow.IsSelected && gridRow.RowSelectedStyle != null)
            return gridRow.RowSelectedStyle;
        else if (!gridRow.IsSelected && gridRow.RowUnselectedStyle != null)
            return gridRow.RowUnselectedStyle;

        let strStyle: string = '';
        if (gridRow.IsSelected) {
            if (this.runtimeData.iMaxComponentPreviewInstanceHeightRequired > 0) {
                strStyle = `height: ${this.runtimeData.iMaxComponentPreviewInstanceHeightRequired}px;`;
                strStyle += 'align-items: flex-start;'; // Needed to override a .mat-cell style.
            }
            gridRow.RowSelectedStyle = strStyle;
        } else {
            let rowsOfText = this.calulateRowsOfText(gridRow);

            if (rowsOfText > 2) {
                let lineHeight = 28;
                let rowHeight = Math.min((rowsOfText * lineHeight), 200);
                strStyle = `height: ${rowHeight}px;`;
                strStyle += 'align-items: flex-start;';
            } else {
                strStyle = `height: ${DEFAULT_UNSELECTED_ROW_HEIGHT}px`;
            }
            gridRow.RowUnselectedStyle = strStyle;
        }
        return (strStyle);
    }

    private calulateRowsOfText(gridRow: GridRowDef) {
        let rowsOfText: number = 0;
        for (let el of gridRow.FormInstanceElementWrappers) {
            let rows = this.rowsOfText(gridRow.getTotalColumnCount(), el);
            if (rows > rowsOfText) {
                rowsOfText = rows;
            }
        }
        rowsOfText = Math.max(this.runtimeMetadata.MaxRowsOfTextToDisplayOnUnselectedGridRows, rowsOfText);
        return rowsOfText;
    }

    // pharv - 01/28/2022 - the goal here is calculate an estimate of how many rows of text
    // a cell contains. The calculation takes into account current column width.
    private rowsOfText(columnCount: number, el: GridFormInstanceElementWrapper): number {
        let characterPixelHeight = 32;
        let avgCharacterPixelWidth = 7;
        let colWidth = this.runtimeData.gridWidth / columnCount;
        let lengthOfTextInPixels = characterPixelHeight;
        if (el && el.standinDisplayValue) lengthOfTextInPixels = el.standinDisplayValue.length * avgCharacterPixelWidth;
        let rowsOfText = lengthOfTextInPixels / colWidth;
        return rowsOfText;
    }

    // Handle control events.
    public formFieldEdit(hshEventData: any, strColumnName: string): void {
        // Pass this column's edit event to the parent form field wrapper.
        this.onEdit.emit(hshEventData);

        return;
    }

    private unselectSelectedRowIfAny(): void {
        // If an existing row is selected, unselect it now.
        if (this.runtimeData.selectedGridRowIndex >= 0) {
            let selectedGridRow: GridRowDef = this.allModesDataSource.getGridRow(this.runtimeData.selectedGridRowIndex);

            if (selectedGridRow == null) {
                let errorMsg: string = `GridFormFieldComponent.unselectedGridRowClicked():  could not un-select ` + `grid row ${this.runtimeData.selectedGridRowIndex} as that index does not exist.`;
                this.raiseException(errorMsg);
            }

            // Remove any controls from the previously selected row.
            this.removeFieldControlsFromGridRow(selectedGridRow);
            selectedGridRow.IsSelected = false;
        }
    }

    // 05-12-2023 note:  disabling the following method for now, and will plan to
    //                   remove it once the grid field designer component is fully tested.
    //
    // Note:  once the grid field editor is better tested and more methods are moved into
    //        that class, then we can first disable then eventually delete the following method.
    public gridColumnInit(hshEventData: any, hshUnusedColumnDef: FormField): void {
        let component: FormFieldBaseComponent = hshEventData['component'];

        if (component != null) {
            // Increment the grid form field count.
            this.runtimeData.iNumRowFormFieldsInitialized++;

            // Save this component.  Replace any prior component that used this form field.
            let componentFormField: FormField = component.FormField;

            this.runtimeMetadata.addOrReplaceAllComponents(component, componentFormField);
        }

        // Also pass this event on to my parent.
        // Since this component is responsible for passing on
        // notifications to child / column components, replace
        // the component in the event with this component.

        // Have all row form field components been created?
        if (this.runtimeData.iNumRowFormFieldsInitialized == this.gridConfig.ColumnCount) {
            if (true) {
                // Need to notify any calculated form fields of the other field values.
                let colDefs: FormField[] = this.gridConfig.ColumnDefs;

                if (this.runtimeData.selectedGridRowIndex >= 0) { // This condition should always be true
                    // as a row click led the individual for field component classes to register.
                    let selectedGridRow: GridRowDef = this.allModesDataSource.getGridRow(this.runtimeData.selectedGridRowIndex);
                    if (!selectedGridRow) {
                        let errorMsg: string = `GridFormFieldComponent.gridColumnInit():  could not find selected` + `row ${this.runtimeData.selectedGridRowIndex}.`;
                        this.raiseException(errorMsg);
                    }

                    let wrappers: GridFormInstanceElementWrapper[] = selectedGridRow.FormInstanceElementWrappers;

                    for (let iCol: number = 0; iCol < colDefs.length; iCol++) {
                        let colDef: FormField = colDefs[iCol];
                        let wrapper: GridFormInstanceElementWrapper = wrappers[iCol];

                        this.handleNgModelChangeLogic(selectedGridRow, colDef, wrapper, FormFieldProcessingPhaseEnum.LOADING_DATA);
                    } // for
                } // if
            } // if

            this.runtimeData.iNumRowFormFieldsInitialized = -1; // This instance variable is irrelevant until another row is selected.
        } // if
    }

    public handlingEditPropertiesClickEvent(): void {
        // Note:  this is the only notification this grid will
        //        get that it's own properties are being edited.
        this.runtimeData.componentBeingEdited = this;
    }

    public resetFormField(formField: FormField): void {
        if (this.runtimeData.componentBeingEdited != null) {
            if (this.runtimeData.componentBeingEdited != this) {
                this.runtimeData.componentBeingEdited.resetFormField(formField);
            }
            else {
                if (!this.currentSiteService.Site.betaFeaturesEnabled) {
                    super.resetFormField(formField);
                }

                // Reload my configuration.
                if (this.Mode == 'design') {
                    if ((this.FormField.childFormFields) && (this.FormField.childFormFields.length > 0))
                        this.createGridConfig();
                    else {
                        this.createEmptyGridConfig();
                    }
                }
            }

            this.runtimeData.componentBeingEdited = null;
        }
    }

    public gridCellContextMenuItemClicked(eventData: any, menuItemName: string): void {
        // Get my menu data.
        let menuData: GridCellContextMenuInfo =
            (this.gridCellContextMenu && this.gridCellContextMenu.menuData ?
                <GridCellContextMenuInfo>this.gridCellContextMenu.menuData : null);

        let bSuccess: boolean = false;

        switch (menuItemName) {
            case 'Properties ...':
                {
                    if (menuData && menuData.component) {
                        let hshComponentProperties: any = menuData.component.getProperties();

                        hshComponentProperties['component'] = this;

                        this.onEdit.emit(hshComponentProperties);

                        bSuccess = true;
                    }
                }
                break;

            case 'Delete':
                {
                    if (menuData && menuData.columnDef) {
                        let gridConfigColDef: FormField = this.gridConfig.getColumnDefByName(menuData.columnDef.name);

                        if (gridConfigColDef) {
                            bSuccess = this.gridConfig.removeGridColumn(gridConfigColDef.gridColClientId);
                            if (bSuccess)
                                this.designChange.emit();
                        }
                    }
                }
                break;

            default:
                // Unknown menu item.
                break;
        }

        return;
    }

    public get ContextMenuPositionX(): string {
        let menuData: GridCellContextMenuInfo = (this.gridCellContextMenu && this.gridCellContextMenu.menuData ? <GridCellContextMenuInfo>this.gridCellContextMenu.menuData : null);
        return (menuData ? menuData.contextMenuXPos : '');
    }

    public get ContextMenuPositionY(): string {
        let menuData: GridCellContextMenuInfo = (this.gridCellContextMenu && this.gridCellContextMenu.menuData ? <GridCellContextMenuInfo>this.gridCellContextMenu.menuData : null);
        return (menuData ? menuData.contextMenuYPos : '');
    }

    public onEditSave = (formField: FormField): void => {
        // Note:  deleted some code that was commented out but that can be found in source control.
    }

    public userTriggeredColumnSort(eventData: any): void {
        let childFieldName: string = eventData.active;
        let childFormField: FormField = this.GridColumnDefs.find(ff => ff.name == childFieldName);

        if (childFormField != null) {
            let fieldDefinition: FieldDefinition = this.fieldDefinitionService.getFieldDefinition(childFormField.fieldDefinitionClassName);
            let fieldLogic: IFieldDefinitionLogic = (fieldDefinition != null ? fieldDefinition.customLogicHandler : null);

            if (fieldLogic != null) {
                let gridRows: GridRowDef[] = this.allModesDataSource.GridRows;

                let sortDirection: string = eventData.direction;
                if ((sortDirection == SortDirection.Ascending) || (sortDirection == SortDirection.Descending)) {
                    this.AllModesDataSource.sortRowsByColumn(childFormField, fieldLogic, sortDirection == SortDirection.Ascending);
                } else {
                    this.AllModesDataSource.sortRowsByRowId();
                }
            }
        }
    }

    private static copyFormFieldUpdatesFromTo(formField: FormField, columnDef: FormField): void {
        // Copy constraint-related fields for the time being.
        columnDef.selectOptions = formField.selectOptions;
        columnDef.selectOptionsConstraintName = formField.selectOptionsConstraintName;
        columnDef.selectOptionsConstraintValue = formField.selectOptionsConstraintValue;

        columnDef.regularExpressionConstraintName = formField.regularExpressionConstraintName;
        columnDef.regularExpressionConstraintValue = formField.regularExpressionConstraintValue;

        columnDef.cascadingDropdownConstraintName = formField.cascadingDropdownConstraintName;
        columnDef.cascadingDropdownConstraintValue = formField.cascadingDropdownConstraintValue;

        columnDef.numericRangeConstraintMinValue = formField.numericRangeConstraintMinValue;
        columnDef.numericRangeConstraintMaxValue = formField.numericRangeConstraintMaxValue;
        columnDef.numericRangeConstraintName = formField.numericRangeConstraintName;

        columnDef.dateRangeConstraintMinDate = formField.dateRangeConstraintMinDate;
        columnDef.dateRangeConstraintMaxDate = formField.dateRangeConstraintMaxDate;
        columnDef.dateRangeConstraintName = formField.dateRangeConstraintName;
    }

    public saveConfiguration(form: any, reasonForSave: FormFieldConfigurationSaveReason): void {
        // Cache form fields during the save configuration operation.
        this.gridConfig.CachedGridColumnDefs = this.gridConfig.ColumnDefs;

        // Call form fields' saveConfiguration() method.
        let allComponentsAndFormFields: ComponentAndFormField[] = this.runtimeMetadata.AllComponents;
        for (let iComponent: number = 0; iComponent < allComponentsAndFormFields.length; iComponent++) {
            let component: FormFieldBaseComponent = allComponentsAndFormFields[iComponent].Component;

            component.saveConfiguration(form, reasonForSave);
        }

        // Save my column definitions as JSON text.
        let currentSite = this.currentSiteService.Site;
        this.FormField.childFormFields = [];

        for (let iCol: number = 0; iCol < this.gridConfig.ColumnCount; iCol++) {
            let gridColDef: FormField = this.gridConfig.getColumnDef(iCol);

            let childFormField: FormField = new FormField();

            childFormField.assignFrom(gridColDef);
            //childFormField.fieldOrder = iCol + 1; // COMMENTED OUT FOR VNEXT-1312 (column reordering)
            if (childFormField.id < 0)
                childFormField.id = 0;
            FormFieldConstraintLiaisonManager.synchronousUpdateFormFieldWithLatestConstraintValues(this.cachedFormFieldConstraintsService, currentSite.id, childFormField);

            this.FormField.childFormFields.push(childFormField);
        }

        return;
    }
    public saveConfigurationCompleted(form: any): void {
        this.gridConfig.CachedGridColumnDefs = null;

        let allComponentsAndFormFields: ComponentAndFormField[] = this.runtimeMetadata.AllComponents;
        for (let iComponent: number = 0; iComponent < allComponentsAndFormFields.length; iComponent++) {
            let component: FormFieldBaseComponent = allComponentsAndFormFields[iComponent].Component;

            component.saveConfigurationCompleted(form);
        }
    }

    public saveData(formInstance: any): void {
        // Save grid data as view model objects (to replace the JSON serialization just above).
        this.FormInstanceElement.childGridRows = this.allModesDataSource.saveGridDataToViewModelElements();

        // 'Remember' the selected grid row, if any.
        if (this.SelectedGridRowIndex >= 0) {
            this.FormField.transientSelectedGridRow = this.SelectedGridRowIndex;
        }

        // If an existing row is selected, unselect it now.
        this.unselectSelectedRowIfAny();

        return;
    }

    //Create a form instance element, fill it up with the Grid data, put into array for the Web API
    public saveDataTo(formInstanceElementParam: FormInstanceElement): void {
        // Note:  this method can be used by callers to save data to a different
        //        FormInstanceElement object than the one assigned to this form field.
        formInstanceElementParam.childGridRows = this.allModesDataSource.saveGridDataToViewModelElements();

        return;
    }

    public saveCompleted(formInstance: any): void {
    }

    public getFormInstanceElementWrapper(hshColumnDef: FormField, gridRow: GridRowDef): GridFormInstanceElementWrapper[] {
        let wrapper: GridFormInstanceElementWrapper = gridRow.getFormInstanceElementWrapper(hshColumnDef);

        if (wrapper && wrapper.formInstanceElement) {
            if (!wrapper.formInstanceElement.transientValuesHash) {
                wrapper.formInstanceElement.transientValuesHash = {};
            }

            wrapper.formInstanceElement.transientValuesHash[GRID_ROW_ID_KEY] = gridRow.ClientId;
        } else if (!wrapper) {
            let errorMsg: string = "GridFormFieldComponent.getFormInstanceElementWrapper():  cannot get a form instance element wrapper.";
            super.raiseException(errorMsg);
        } else {
            let errorMsg: string = "GridFormFieldComponent.getFormInstanceElementWrapper():  cannot get a form instance element.";
            super.raiseException(errorMsg);
        }

        let arrWrapper: GridFormInstanceElementWrapper[] = [wrapper];

        return (arrWrapper);
    }

    public propertyChanged(e: any): void {
        let formField: FormField = e.formField;
        let propertyName: string = e.propertyName;

        this.propertyUpdated(formField, propertyName);

        return;
    }

    public propertyUpdated(formField: FormField, propertyName: string): void {
        let componentAndFormField: ComponentAndFormField = this.runtimeMetadata.AllComponents.find(cff => cff.FormField.name == formField.name);
        if (componentAndFormField != null) {
            if (propertyName == FormFieldPropertyEnum.FORMULA)
                this.notifyComponentOfFieldNames(componentAndFormField.Component);
            componentAndFormField.Component.propertyUpdated(formField, propertyName);
        }

        // Do we need to handle this property?
        if (propertyName === FormFieldPropertyEnum.FIXED_FIRST_COLUMN_FILE) {
            if (formField.csvOptionsData != null)
                this.processFixedFirstColumnFile(formField.csvOptionsData);
            else {
                // Clear any fixed first column configuration data.
                this.clearAnyFixedFirstColumnConfig();
            }
        }

        return;
    }

    private processFixedFirstColumnFile(csvOptionsData: CsvOptionsFileData): void {
        if ((csvOptionsData != null) && (csvOptionsData.headers != null) && (csvOptionsData.headers.length >= 1)) {
            let fixedValues: FixedFirstColumnValues = FixedFirstColumnValues.createFromHeaderValuePairs(csvOptionsData.headers[0], csvOptionsData.linesOfHeaderValuePairs);

            this.setFirstGridColAsFixedValues(fixedValues);
        }
    }

    private setFirstGridColAsFixedValues(fixedValues: FixedFirstColumnValues): void {
        // Create the fixed column form field.
        let fixedColDef: FormField = (this.gridConfig.ColumnCount == 0 ? this.gridConfig.addGridColumn(ShortTextFieldType) : this.gridConfig.getColumnDef(0));
        fixedColDef.valuesAreFixed = true;
        fixedColDef.displayName = fixedValues.columnName;
        fixedColDef.defaultValue = `${fixedValues.values.length} values`;
        fixedColDef.readOnly = true;
        fixedColDef.fixedFirstColumnJson = JSON.stringify(fixedValues);
        this.FormField.fixedFirstColumnJson = fixedColDef.fixedFirstColumnJson; // Saving this in two places is merely a convenience.
        this.FormField.transientFixedFirstColumnValues = FixedFirstColumnValues.createFromJson(this.gridConfig.getColumnDef(0).fixedFirstColumnJson);
    }

    private clearAnyFixedFirstColumnConfig(): void {
        // Clear any fixed first column configuration data.
        if (this.FormField.fixedFirstColumnJson != null)
            this.FormField.fixedFirstColumnJson = null;
        if (this.FormField.transientFixedFirstColumnValues != null)
            this.FormField.transientFixedFirstColumnValues = null;
        if ((this.FormField.childFormFields != null) && (this.FormField.childFormFields.length >= 1)) {
            let firstChildField: FormField = this.FormField.childFormFields[0];

            if (firstChildField.fixedFirstColumnJson != null)
                firstChildField.fixedFirstColumnJson = null;
            if (firstChildField.valuesAreFixed)
                firstChildField.valuesAreFixed = false;
            if (firstChildField.defaultValue != null)
                firstChildField.defaultValue = null;
        }

        // Recreate my config.
        if ((this.FormField.childFormFields != null) && (this.FormField.childFormFields.length > 0))
            this.createGridConfig();
        else {
            this.createEmptyGridConfig();
        }
    }

    public handleNgModelChangeLogic(gridRow: GridRowDef, formField: FormField, wrapper: GridFormInstanceElementWrapper, processingPhase: FormFieldProcessingPhaseEnum): void {
        let formInstanceElement: FormInstanceElement = wrapper.formInstanceElement;

        // Update the wrapper's 'stand-in' value.
        let fieldTypeAndName: FieldTypeAndName = this.formFieldTypeAndNameService.getFieldTypeAndField(formField.fieldDefinitionClassName)
        let fieldLogicHandler: IFieldDefinitionLogic = fieldTypeAndName != null ? this.fieldDefinitionService.getFieldDefinition(fieldTypeAndName.fieldType).customLogicHandler : null;

        if ((fieldTypeAndName != null) && (fieldLogicHandler != null)) {
            wrapper.standinDisplayValue = fieldLogicHandler.getDisplayValue(formField, wrapper.formInstanceElement, gridRow, processingPhase);
        }

        // If any fields are listening for value changes, let them know about this update.
        let iColIndex: number = this.gridConfig.ColumnIndex(formField);
        let affectedComponents: IFormFieldComponent[] = null;

        if (iColIndex >= 0)
            affectedComponents = this.notifyComponentsOfValueChanges(iColIndex, formField, formInstanceElement, gridRow);

        // If we have a numeric totals footer.
        if (this.ShowNumericTotalsFooter && (fieldTypeAndName != null) && fieldTypeAndName.fieldDefinition.isNumeric) {
            let numericValue: number = fieldTypeAndName.componentRepresentative.getNumericValue(formField, formInstanceElement, gridRow, FormFieldProcessingPhaseEnum.CALCULATING_COLUMN_TOTAL)

            this.allModesDataSource.cellValueChanged(gridRow, formField, fieldLogicHandler, numericValue);

            // Were other components affected by this change?
            if ((affectedComponents != null) && (affectedComponents.length > 0)) {
                for (let iComp: number = 0; iComp < affectedComponents.length; iComp++) {
                    let comp: IFormFieldComponent = affectedComponents[iComp];
                    let compFormField: FormField = comp.getFormField();
                    let compFormInstanceElement: FormInstanceElement = comp.getFormInstanceElement();

                    let affectedComponentNumericValue: number = comp.getNumericValue(compFormField, compFormInstanceElement, gridRow, FormFieldProcessingPhaseEnum.CALCULATING_COLUMN_TOTAL);
                    this.allModesDataSource.cellValueChanged(gridRow, compFormField, fieldLogicHandler, affectedComponentNumericValue);
                }
            }
        }

        return;
    }

    // Implement private helper methods.
    private notifyComponentOfFieldNames(component: FormFieldBaseComponent): void {
        // Assemble a hash of all component names.
        let astrFieldNames: string[] = [];
        let hshColNameToFormField: IFieldNameToFormField = {};

        for (let iComponent: number = 0; iComponent < this.runtimeMetadata.AllComponentsCount; iComponent++) {
            let component: FormFieldBaseComponent = this.runtimeMetadata.getAllComponentsFormFieldComponent(iComponent);

            let columnFormField: FormField = this.runtimeMetadata.getAllComponentsFormField(iComponent);
            component.FormField = columnFormField;

            let iCol: number = this.gridConfig.getColumnIndex(columnFormField) + 1;

            let colName: string = `col${iCol}`;
            astrFieldNames.push(colName);

            hshColNameToFormField[colName] = columnFormField;
        }

        component.receiveFormFieldNames(astrFieldNames, hshColNameToFormField);
    }

    private notifyComponentsOfValueChanges(iColIndex: number, formField: FormField, formInstanceElement: FormInstanceElement, gridRow: IGridRow): IFormFieldComponent[] {
        let result: IFormFieldComponent[] = [];

        if (formInstanceElement.transientValuesHash && formInstanceElement.transientValuesHash[GRID_ROW_ID_KEY]) {
            let iGridRowId: number = formInstanceElement.transientValuesHash[GRID_ROW_ID_KEY];

            // Note:  since the optimization that only one row of controls can be active at a give time,
            //        we know that any dynamic/user-entered value change will affect the any active calculated
            //        form field.  Hence, we do not need to compare row id values anymore.
            if (iGridRowId != 0) {
                for (let iComponent: number = 0; iComponent < this.runtimeMetadata.AllComponentsCount; iComponent++) {
                    let componentAndFormField: ComponentAndFormField = this.runtimeMetadata.AllComponents[iComponent];
                    let component: FormFieldBaseComponent = componentAndFormField.Component;

                    if (component.requiresFieldValueUpdate()) {
                        this.notifyComponentOfFieldNames(component);

                        let bComponentAffected: boolean = component.formFieldValueUpdated(iColIndex, formField, formInstanceElement, gridRow);
                        if (bComponentAffected)
                            result.push(component);
                    }
                } // for
            } // if
        } // if

        return result;
    }

    private createEmptyGridConfig(): void {
        this.gridConfig = new GridConfig(this.Mode, this.fieldDefinitionService);
    }

    private createGridConfig(): void {
        // New configuration code.
        if (this.FormField.childFormFields && (this.FormField.childFormFields.length > 0)) {
            this.gridConfig = new GridConfig(this.Mode, this.fieldDefinitionService, this.FormField.childFormFields, this.formInstance);
            this.gridConfig.setIsFootnote(this.FormField.isFootnote);
        }
    }

    // Note:  method controlReceivedFocus(), next, must be defined as an arrow function.
    //
    // 05-12-2023 note:  once the grid field editor component is fully tested, we
    //                   can first disable and then remove the following method.
    public controlReceivedFocus = (myComponent: IFormFieldComponent, formFieldComponent: IFormFieldComponent, event: FocusEvent) => {
        // Note:  the following line should not be needed, but this arrow function
        //        is not returning property 'this' as does arrow function controlValueChanged().
        let myself: GridFormFieldComponent = <GridFormFieldComponent>myComponent;

        let componentFormField: FormField = formFieldComponent.FormField;

        // Find the selected cell index.
        let cellIndex: number = 0;
        let colDefs: FormField[] = myself.GridConfig.ColumnDefs;
        for (let colIndex: number = 0; colIndex < colDefs.length; colIndex++) {
            let colDef: FormField = colDefs[colIndex];
            if (colDef.name == componentFormField.name) {
                cellIndex = colIndex;
                break;
            }
        }
        this.runtimeData.selectedGridColumnIndex = cellIndex;
    }

    // Note:  method controlValueChanged(), next, must be defined as an arrow function.
    //
    // 05-12-2023 note:  once the grid field editor component is fully tested, we
    //                   can first disable and then remove the following method.
    public controlValueChanged = (value: FormInstanceElement) => {
        if (value.transientValuesHash) {
            let iGridRowId: number = value.transientValuesHash[GRID_ROW_ID_KEY];
            let iColumnId: number = value.transientValuesHash[GRID_COLUMN_ID_KEY];

            if (iGridRowId && iColumnId) {
                let columnDef: FormField = this.gridConfig.getColumnDefByClientId(iColumnId);
                let gridRowDef: GridRowDef = this.allModesDataSource.getRowByClientId(iGridRowId);

                if (columnDef && gridRowDef) {
                    let wrapper: GridFormInstanceElementWrapper = gridRowDef.getFormInstanceElementWrapper(columnDef);

                    if (wrapper) {
                        // A value has been set, so set the 'transientValueSetFlag' flag.
                        wrapper.formInstanceElement.UserUpdatedData = true;
                        // pharvey - let the Grid's FormInstanceElement know there's been a value change
                        this.FormInstanceElement.UserUpdatedData = true;

                        // Perform model value changed logic.
                        this.handleNgModelChangeLogic(gridRowDef, columnDef, wrapper, FormFieldProcessingPhaseEnum.EDITING_DATA);
                    }
                }

            }
        }
    }

    // pharv - 4/12/2022 - modified to take row and col indexes in order to keep
    // dynamically generated FormField components in memory so they can be looked up
    // by name and used to render validation messages
    //
    // 05-12-2023 note:  once the grid field editor component is fully tested, we
    //                   can first disable and then remove the following method.
    private createFieldControlInRowCell(formField: FormField, cellFormInstanceElementWrapper: GridFormInstanceElementWrapper, viewContainerRef: ViewContainerRef, bSetFocusInFieldControl: boolean, rowIndex: number, colIndex: number): void {
        let fieldTypeAndName: FieldTypeAndName = this.formFieldTypeAndNameService.getFieldTypeAndField(formField.fieldDefinitionClassName);
        let formFieldClass: AngularCoreType<any> = fieldTypeAndName.formFieldClass;
        let componentRef: ComponentRef<FormFieldBaseComponent> =
            fieldTypeAndName.componentRepresentative.createFormFieldDynamically(this.resolver, this.fieldDefinitionService, viewContainerRef, formField, this.Mode, cellFormInstanceElementWrapper.formInstanceElement, false, null, true);

        cellFormInstanceElementWrapper.componentRef = componentRef;
        cellFormInstanceElementWrapper.formInstanceElement.transientValuesHash[GRID_COLUMN_ID_KEY] = formField.gridColClientId;

        let formFieldComponent: any = componentRef.instance;
        let cellName = this.getCellNameFor(rowIndex, colIndex);
        this.runtimeData.dynamicallyCreatedFormFieldsByName[cellName] = formFieldComponent;

        // https://stackoverflow.com/a/49038739
        let formFieldComp = componentRef.instance as FormFieldBaseComponent;
        formFieldComp.touched.subscribe(() => {
            this.updateValidationErrors(rowIndex, colIndex);
        });

        formFieldComponent.onInit.subscribe(hshEventData => {
            let component: FormFieldBaseComponent = hshEventData['component'];
            if (component) {
                let field: FormField = component.FormField;

                this.gridColumnInit(hshEventData, field);
            }
        });

        componentRef.instance.registerOnChange(this.controlValueChanged);
        componentRef.instance.registerOnFocus(this, this.controlReceivedFocus);

        if (bSetFocusInFieldControl) {
            let formFieldBaseComponent: FormFieldBaseComponent = <FormFieldBaseComponent>formFieldComponent;

            formFieldBaseComponent.setFocus();
        }

        // getting the component's HTML
        let element: HTMLElement = <HTMLElement>componentRef.location.nativeElement;

        // Done.
    }

    private removeFieldControlsFromGridRow(gridRow: GridRowDef): void {
        if (this.gridFieldEditorComponent != null)
            this.gridFieldEditorComponent.removeFieldControlsFromGridRow(gridRow);
    }

    private createConfigFromChildGridRows(childGridRows: GridRowViewModel[]): void {
        // Create my configuration and load my data.
        this.createGridConfig();
        this.allModesDataSource = new GridAllModesDataSource(this.gridConfig, this);
        this.allModesDataSource.loadGridDataFromViewModelElements(this.fieldDefinitionService, childGridRows);

        // Do we have a footer for numeric totals?
        this.trackColumnTotalsIfSoConfigured();

        // Do we need to show a paginator?
        if (childGridRows.length >= this.loadingDataProgress.arrPageSizeOptions[0]) {
            this.loadingDataProgress.bShowPaginator = true;
        }

        // Update my progress.
        if (this.loadingDataProgress.isLoadingGridData) {
            this.loadingDataProgress.iLoadingDataProgressValue = 100;
            this.loadingDataProgress.iLoadingDataProgressBufferValue = 100;

            // Set a timer to reset my loading flag.
            setTimeout(() => {
                this.loadingDataProgress.isLoadingGridData = false;
            }, 2500);
        }
    }

    private trackColumnTotalsIfSoConfigured(): void {
        // Do we have a footer for numeric totals?
        if (this.FormField.showFooter) {
            // Do we have numeric form fields?
            let numericFFCompPairs: ComponentAndFormField[] = this.runtimeMetadata.AllNumericComponents;

            if (numericFFCompPairs && (numericFFCompPairs.length > 0)) {
                this.allModesDataSource.trackColumnTotals(numericFFCompPairs);
            }
        }
    }

    private createFixedGridRows(): void {
        if ((this.allModesDataSource.GridRowCount == 0) &&
            (this.FormField.transientFixedFirstColumnValues.values != null) &&
            (this.FormField.transientFixedFirstColumnValues.values.length > 0)) {
            let colDefs: FormField[] = this.gridConfig.ColumnDefs;
            let childGridRows: GridRowViewModel[] = [];
            for (let index: number = 0; index < this.FormField.transientFixedFirstColumnValues.values.length; index++) {
                let value: string = this.FormField.transientFixedFirstColumnValues.values[index];

                let childGridRow: GridRowViewModel = new GridRowViewModel();
                childGridRow.id = 0;
                childGridRow.isDeleted = false;
                childGridRow.rowIndex = index;
                childGridRow.cellDataHash = {};

                for (let col: number = 0; col < colDefs.length; col++) {
                    let colDef: FormField = colDefs[col];
                    let formInstanceElement: FormInstanceElement = new FormInstanceElement();
                    childGridRow.cellDataHash[colDef.name] = formInstanceElement;

                    if (col == 0) {
                        formInstanceElement.valueType = FormInstanceElementValueTypeEnum.TypeText;
                        formInstanceElement.textValue = value;
                    }
                }

                childGridRows.push(childGridRow);
            }
            this.allModesDataSource.loadGridDataFromViewModelElements(this.fieldDefinitionService, childGridRows);
        }
    }

    public copyDataToGrid(pasteData: string, replaceOrAppend: any): void {
        this.allModesDataSource.copyDataToGrid(pasteData, replaceOrAppend, this.runtimeMetadata.AllComponents, this.hasFixedFirstColumnJsonConfig, this.FormField, this.fieldDefinitionService);
    }

    private formFieldConstraintUpdated = (updatedConstraint: IFormFieldConstraint, constraintLiaison: IFormFieldConstraintLiaison): void => {
        // If any of my child form fields use the same constraint, updated their constraint fields accordingly.
        if (this.gridConfig.ColumnDefs != null) {
            for (let index: number = 0; index < this.gridConfig.ColumnDefs.length; index++) {
                let columnDef: FormField = this.gridConfig.ColumnDefs[index];

                constraintLiaison.updateOtherFieldIfUsingSameConstraint(updatedConstraint, columnDef);
            }
        }

        if ((this.runtimeMetadata != null) && (this.runtimeMetadata.AllComponents != null)) {
            for (let index: number = 0; index < this.runtimeMetadata.AllComponents.length; index++) {
                let componentInfo: ComponentAndFormField = this.runtimeMetadata.AllComponents[index];
                let childFormField: FormField = <FormField>componentInfo.Component.getFormField();

                constraintLiaison.updateOtherFieldIfUsingSameConstraint(updatedConstraint, childFormField);
            }
        }
    }

    private acceptsConstraintUpdateFrom = (formField: FormField): boolean => {
        return formField != this.FormField;
    }

    private createAnyVirtualColDefs(): void {
        if (this.FormField.childFormFields != null) {
            // Remove any existing virtual fields.
            this.removeAnyExistingVirtualColDefs();

            // Do we have any virtual fields to create?
            if (GridConfig.hasColumnDefWithVirtualFormFieldsFor(this.FormField.childFormFields, this.fieldDefinitionService)) {
                let maxClientId: number = GridConfig.getMaxClientIdFor(this.FormField.childFormFields);
                this.FormField.childFormFields = GridConfig.getChildAndAnyVirtualFormFieldsFor(this.FormField.childFormFields, this.fieldDefinitionService, maxClientId);
                for (let index: number = 0; index < this.FormField.childFormFields.length; index++) {
                    let childFormField: FormField = this.FormField.childFormFields[index];

                    childFormField.fieldOrder = index + 1;
                    childFormField.clientId = index + 1;
                    if ((childFormField.id == null) || (childFormField.id == 0)) {
                        childFormField.id = -(index + 1); // Note:  this 'id' value is never saved in the database, so I am using a negative value to distinguish it from persistent id values.
                    }
                    if ((childFormField.displayName == null) || (childFormField.displayName.trim() == ''))
                        childFormField.displayName = childFormField.name;
                } // for
            } // if
        } // if
    }

    private removeAnyExistingVirtualColDefs(): void {
        // Remove any existing virtual fields.
        this.FormField.childFormFields = this.FormField.childFormFields.filter(ff => !ff.transientFieldIsVirtual);
    }

    private get instanceOrPreviewChildFormFieldComponents(): FormFieldBaseComponent[] {
        let formFieldComponents: FormFieldBaseComponent[] = [];

        if (this.formFieldComponentsQueryList != null)
            formFieldComponents = this.formFieldComponentsQueryList.toArray();

        return formFieldComponents;
    }

    private get hasFixedFirstColumnJsonConfig(): boolean {
        return ((this.gridConfig != null) && (this.gridConfig.ColumnCount > 0) && (this.gridConfig.getColumnDef(0).fixedFirstColumnJson != null));
    }

    //TEAMS-894: KLW - Needed for grid validation
    public handleEditorGridValidation(state: string) {
        this.mainGridValidation.emit(state);
    }
}
