<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>
<ng-template #tooltipTemplate><ng-content select="[projectedTooltip]"></ng-content></ng-template>

<ng-template #noAutoComplete>
    <!-- If there are instructions, display label first, then instructions then field -->
    <ng-container *ngIf="this.HasInstructions">
        <div class="{{this.Mode == 'design' ? 'design-mode-field-label' : 'field-label'}}" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
        <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
    </ng-container>

    <mat-form-field appearance="outline"
                    [floatLabel]="'always'"
                    class="drop-down-mat-form-field full-width">
        <!-- If there are no instructions, display label integrated into the field -->
        <mat-label *ngIf="this.ShowLabel && !this.HasInstructions && !FieldIsInGrid"
                   class="{{this.Mode =='design' ? 'design-mode-field-label' : 'field-label'}}">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></mat-label>

        <mat-chip-list [formControl]="this.DropDownOptionsFormControl">
            <mat-chip *ngFor="let selected of this.DropDownOptionsFormControl.value"
                      [removable]="true"
                      (removed)="onRemove(selected)"
                      class="mat-elevation-z2 regular-chip">
                {{ selected }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip class="mat-elevation-z3 remove-all-chip" *ngIf="this.ShowRemoveAll" (click)="this.removeAllChips()" (keyup.enter)="this.removeAllChips()">
                Remove All
            </mat-chip>
        </mat-chip-list>

        <mat-select #multiSelect
                    [formControl]="this.DropDownOptionsFormControl"
                    multiple
                    (blur)="this.handleValidationOnBlur()"
                    [disableOptionCentering]="true"
                    class="highlight-when-focused">

            <mat-select-trigger>
                <!--Intentionally leave empty so there are no values when an option is selected-->
            </mat-select-trigger>

            <mat-option *ngFor="let dropDownOption of this.DropDownOptions"
                        [disabled]="this.IsDesign"
                        title="{{dropDownOption}}"
                        [value]="dropDownOption">
                {{dropDownOption}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</ng-template>

<ng-template #multiDropDown>
    <ng-container *ngIf="this.DropDownReadyToDisplay">
        <div *ngIf="this.AutocompleteEnabled && !this.IsDesign; else noAutoComplete">
            <app-autocomplete #multidropdownauto
                              [title]="this.DropDownOptionsTitle"
                              (selectedItems)="selectChange($event)"
                              (onBlur)="this.blurValidation()">
            </app-autocomplete>
        </div>
        <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
    </ng-container>
</ng-template>

<div class="outer-div">
    <div class="flex-row-top-right">
        <div class="fill-remaining-space">
            <ng-template [ngTemplateOutlet]="multiDropDown"></ng-template>
        </div>
        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
    </div>
</div>
