import {
    Component, EventEmitter, OnInit, Output, Type as AngularCoreType, ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { TooltipPosition } from '@angular/material/tooltip';
import { CurrentUserService } from '../../../../security/current-user.service';
import { AlertDialogComponent, AlertDialogModel } from '../../../../shared/dialogs/alert/alert-dialog.component';
import { CommentInfo } from '../../../models/comments-data/comment-info';
import { CommentsFormFieldData } from '../../../models/comments-data/comments-form-field.model';
import { ICommentInfo } from '../../../models/comments-data/icomment-info.interface';
import { IUserOrGroupInfo } from '../../../models/comments-data/iuser-or-group-info.interface';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormField } from '../../../models/form-builder/form-field.model';
import { PickerItem } from '../../../models/picker-item.model';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';


@Component({
    selector: 'app-comments-form-field',
    templateUrl: './comments-form-field.component.html',
    styleUrls: ['./comments-form-field.component.scss', '../form-fields.scss'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CommentsFormFieldComponent,
            multi: true
        }
    ]
})
export class CommentsFormFieldComponent extends FormFieldBaseComponent implements OnInit {
    // Properties.
    // Note:  several properties are implemented in my base class.
    @Output() onInit = new EventEmitter();

    private numDisplayRows: number = 1;
    private commentsData: CommentsFormFieldData = new CommentsFormFieldData();

    @ViewChild(MatTable) table: MatTable<ICommentInfo>;

    private readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.NAME,
            FormFieldPropertyEnum.FIELD_GROUP,
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.BLANK_VALUE,
            FormFieldPropertyEnum.HELP_TEXT,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT,
            FormFieldPropertyEnum.NUMBER_OF_DISPLAY_ROWS,
            FormFieldPropertyEnum.SHOW_COMMENTS_PROPERTIES
        ];

    private displayedColumns: string[] = [];

    //VNEXT-614: KLW - Adding property getter to determine if control should be disabled
    public get Disabled() {
        return this.readOnly;
    }

    private readonly numDisplayRowsValues: string[] = [null, '1', '2', '3', '4', '5'];

    // Constructor.
    public constructor(public dialog: MatDialog, private currentUserService: CurrentUserService) {
        super();
    }

    // Life cycle methods.
    public ngOnInit(): void {
        this.propertyUpdated(this.FormField, FormFieldPropertyEnum.NUMBER_OF_DISPLAY_ROWS);

        if (this.Mode === 'design') {
            this.createExampleComments();
        } else if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            // Note:  existing comments, if any, will be loaded in method formInstanceElementReceived().
        }

        // Derived columns to display.
        this.deriveDisplayedColumns();

        // Emit 'onInit' to my parent component.
        let hshProperties = this.getProperties();
        this.onInit.emit(hshProperties);



    }

    public ngAfterViewInit(): void {
        return; // Can see @ViewChild() values when this method is called.
    }

    // Methods called by my HTML code.
    public columnIsSticky(colName: string): boolean {
        // Note:  this does not need to be a hard code value, but it is.
        return true;
    }
    public get HeaderRowIsSticky(): boolean {
        return true;
    }

    public get NumDisplayRows(): number {
        return this.numDisplayRows;
    }

    public getCommentTableCssClasses(isDiv: boolean): string {
        let cssClasses: string = (isDiv ? 'mat-table-div comments-mat-table-div-max-height' : 'comments-mat-table comments-mat-table-max-height');

        if (this.FormField.displayRows != null) {
            switch (this.FormField.displayRows) {
                case '2':
                case '3':
                case '4':
                case '5':
                    cssClasses =
                        (isDiv ?
                            `mat-table-div comments-mat-table-div-${this.FormField.displayRows}-rows` :
                            `comments-mat-table comments-mat-table-${this.FormField.displayRows}-rows`);
                    break;

                default:
                    break;
            }
        }

        return cssClasses;
    }

    public get CommentsListing(): ICommentInfo[] {
        return this.commentsData.Comments;
    }

    public get DisplayedColumns(): string[] {
        return this.displayedColumns;
    }

    public get CommentSelectionDisabled(): boolean {
        return this.mode == 'design';
    }

    public get AddViewableByUserOrGroupLabel(): string {
        return 'Add User or Group To View Comment';
    }
    public get AddViewableByUserOrGroupButtonTitle(): string {
        return 'Add User or Group';
    }
    public get AddViewableByButtonAdditionalStyle(): string {
        return '';
    }
    public get AddViewableByButtonFlyoverText(): string {
        return 'Add a person who can view this comment';
    }
    public get AddViewableByButtonCanEnable(): boolean {
        return (this.commentsData.SelectedComment != null);
    }
    public ViewableByCanMoveUp(comment: ICommentInfo, viewableBy: IUserOrGroupInfo): boolean {
        return (!comment.isFirstViewableBy(viewableBy));
    }
    public ViewableByCanMoveDown(comment: ICommentInfo, viewableBy: IUserOrGroupInfo): boolean {
        return (!comment.isLastViewableBy(viewableBy));
    }
    public MoveViewableByUpTooltip(viewableBy: IUserOrGroupInfo): string {
        let action = viewableBy.userType.toLowerCase();
        return `Move this ${action} up`;
    }
    public MoveViewableByDownTooltip(viewableBy: IUserOrGroupInfo): string {
        let action = viewableBy.userType.toLowerCase();
        return `Move this ${action} down`;
    }
    public DeleteViewableByTooltip(viewableBy: IUserOrGroupInfo): string {
        let action = viewableBy.userType.toLowerCase();
        return `Delete this ${action}`;
    }
    public get PickerInputDisabled(): boolean {
        return (this.mode === 'design');
    }

    public get TooltipPosition(): TooltipPosition {
        let position: TooltipPosition = 'below';

        return (position);
    }

    public get EnableCommentEditing(): boolean {
        // Note:  check with Shaun to see if this needs to be configurable.
        return true;
    }
    public getEditCommentTooltip(element: ICommentInfo): string {
        return (element.editingEnabled ? 'End editing this comment' : 'Edit this comment');
    }
    public get EditCommentButtonDisabled(): boolean {
        return (this.Mode === 'design');
    }

    public getShowViewableByRowsTooltip(comment: ICommentInfo): string {
        let action = (comment.isExpanded ? 'Hide' : 'Show');
        let tooltip = `${action} users, groups who have permission to see this comment`;
        return tooltip;
    }

    public get TrashCanTooltip(): string {
        return 'Delete this comment';
    }
    public get TrashCanButtonDisabled(): boolean {
        return (this.mode === 'design');
    }

    public get AddNewCommentButtonDisabled(): boolean {
        return (this.mode == 'design');
    }

    public get TextAreaRowCount(): number {
        // Note:  we might want to make this configurable.
        return 2;
    }

    public get AddNewCommentIconTitle(): string {
        return 'Add a new comment';
    }
    public get AddNewCommentIconTooltip(): string {
        return 'Click this button to add a new comment to this table';
    }

    public HasViewableByUsersAndGroups(comment: ICommentInfo): boolean {
        return ((comment.viewableByUsersAndGroups != null) && (comment.viewableByUsersAndGroups.length > 0));
    }
    public ViewableByUsersAndGroups(comment: ICommentInfo): IUserOrGroupInfo[] {
        return comment.viewableByUsersAndGroups;
    }
    public ViewableUserOrGroupType(userOrGroup: IUserOrGroupInfo): string {
        return userOrGroup.userType;
    }
    public ViewableUserOrGroup(userOrGroup: IUserOrGroupInfo): string {
        return userOrGroup.displayText;
    }
    public CommentViewableByClass(comment: ICommentInfo): string {
        return (comment.isExpanded ? 'expanded-viewable-by-div' : 'hidden-viewable-by-div');
    }
    public CommentViewableByStyle(comment: ICommentInfo): string {
        let viewableByHeight = 40;
        let style = '';
        if (comment.isExpanded && (comment.viewableByUsersAndGroups != null)) {
            let height = comment.viewableByUsersAndGroups.length * viewableByHeight;
            style = `height: ${height}px; max-height: ${height}px;`;
        }
        return style;
    }
    public get CommentViewableByTableStyle(): string {
        return "margin-left: 200px;";
    }
    public get CommentViewableByTableRowStyle(): string {
        return "height: 40px; width: 300px;";
    }
    // End methods called by my HTML code.

    // Handle control events.
    public addViewableByButtonClicked(userOrGroupData: PickerItem): void {
        let selectedComments = this.commentsData.Comments.filter(x => { return x.isSelected });

        if (selectedComments.length > 0) {
            for (let comment of selectedComments) {
                this.commentsData.addViewableByToComment(userOrGroupData, comment);
            }
            userOrGroupData['clearMemberInput'] = true;
        } else {
            let message = 'Please select a comment using one of the checkboxes to add a user or group.';
            let alertDlgModel = new AlertDialogModel('Cannot Add User or Group', message);
            this.dialog.open(AlertDialogComponent, {
                maxWidth: "600px",
                data: alertDlgModel
            });
        }

        //VNEXT-665: KLW - Set flag to clear Max Picker
        userOrGroupData['clearMemberInput'] = true;
    }
    public deleteViewableBy(comment: ICommentInfo, viewableBy: IUserOrGroupInfo): void {
        this.commentsData.deleteViewableBy(comment, viewableBy);
    }
    public moveViewableByUp(comment: ICommentInfo, viewableBy: IUserOrGroupInfo): void {
        this.commentsData.moveViewableByUp(comment, viewableBy);
    }
    public moveViewableByDown(comment: ICommentInfo, viewableBy: IUserOrGroupInfo): void {
        this.commentsData.moveViewableByDown(comment, viewableBy);
    }

    public deleteComment(comment: ICommentInfo): void {
        this.commentsData.deleteComment(comment);

        this.table.renderRows();
    }

    public editComment(element: ICommentInfo): void {
        element.editingEnabled = (!element.editingEnabled);
    }

    public showViewableByRowsFor(comment: ICommentInfo): void {
        comment.isExpanded = !comment.isExpanded;
    }

    public handleModelChange(element: ICommentInfo, fieldName: string): void {
        // Note:  this method is only used during testing/troubleshooting.
    }
    // End methods that handle control events.

    // Override base class methods.
    public getProperties(): any {
        let hshProperties = {
            component: this,
            formField: this.FormField,
            properties: this.formFieldProperties,
            displayRowsValues: this.numDisplayRowsValues,

            propertyUpdateRequired: true,
            saveDataRequired: true
        };

        return (hshProperties);
    }

    public getFormFieldClass(): AngularCoreType<any> {
        return (CommentsFormFieldComponent);
    }

    public hasNumericData(): boolean {
        return false;
    }

    protected formInstanceElementReceived(): void {
        if (this.FormInstanceElement && this.FormInstanceElement.textValue) {
            this.commentsData = CommentsFormFieldData.deserializeData(this.FormInstanceElement);
        }

        return;
    }

    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    protected writeValueTriggered(): void {
        if (this.FormInstanceElement && this.FormInstanceElement.textValue) {
            this.commentsData = CommentsFormFieldData.deserializeData(this.FormInstanceElement);
        }

        return;
    }

    public propertyUpdated(formField: FormField, propertyName: string): void {
        if (propertyName == FormFieldPropertyEnum.SHOW_COMMENTS_PROPERTIES) {
            this.deriveDisplayedColumns();
        } else if (propertyName == FormFieldPropertyEnum.NUMBER_OF_DISPLAY_ROWS) {
            let numRows: number = parseInt(this.FormField.displayRows);
            if (numRows != NaN) {
                this.numDisplayRows = numRows;

                if ((this.Mode === 'design') && (numRows != this.commentsData.Comments.length)) {
                    this.createExampleComments();
                    if (this.table != null)
                        this.table.renderRows();
                }
            }
        }
    }

    public saveData(ignoredComponentDataToSave: any): void {
        this.packageDataAndUpdateFlags();
    }

    // Handle control events.
    public addNewComment(): void {
        let comment = this.commentsData.addNewComment(this.currentUserService.user.displayName, new Date());

        this.table.renderRows();
    }

    // Implement helper methods.
    private createExampleComments(): number {
        let numCommentsCreated: number = 0;
        let exampleComment: CommentInfo = null;

        if (this.commentsData.CommentCount == 0) {
            exampleComment = new CommentInfo('Example comment', this.currentUserService.user.displayName, new Date());
            this.commentsData.addComment(exampleComment);
            numCommentsCreated++;
        }

        if (this.FormField.displayRows != null) {
            if (this.numDisplayRows > this.commentsData.CommentCount) {
                // We need more example comments.
                for (let rowIndex: number = this.commentsData.CommentCount; rowIndex < this.numDisplayRows; rowIndex++) {
                    exampleComment = new CommentInfo(`Example comment ${rowIndex + 1}`, this.currentUserService.user.displayName, new Date());
                    this.commentsData.addComment(exampleComment);
                    numCommentsCreated++;
                }
            } else if (this.numDisplayRows < this.commentsData.CommentCount) {
                // We need fewer example comments.
                this.commentsData.popComments(this.commentsData.CommentCount - this.numDisplayRows);
            }
        }

        return numCommentsCreated;
    }

    private packageDataAndUpdateFlags(): void {
        for (let comment of this.commentsData.Comments) {
            // don't save this setting
            comment.isExpanded = false;
            comment.isSelected = false;
        }
        this.FormInstanceElement.textValue = JSON.stringify(this.commentsData);
        this.FormInstanceElement.UserUpdatedData = true;
    }

    private deriveDisplayedColumns(): void {
        this.displayedColumns = [];
        this.displayedColumns.push('commentNumber');
        this.displayedColumns.push('comment');
        this.displayedColumns.push('commenter');
        this.displayedColumns.push('viewableBy');
        this.displayedColumns.push('action');
    }
}
