import {
    Component,
    OnInit,
    Renderer2,
    Output,
    EventEmitter,
    Type as AngularCoreType,
    Input
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    ControlValueAccessor,
    AsyncValidatorFn,
    FormGroup,
    FormControl,
    Validators,
    ValidatorFn,
    //ValidationErrors
} from '@angular/forms'; // Used for Reactive Forms

import { FormulaEvaluator } from './formula-evaluator';
import { FieldNameHashes } from './field-name-hashes'
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { IFieldNameToFormField } from '../../../interfaces/iform-field-component';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { INumericValuesHashByFieldName, IGridRow } from '../../../interfaces/grid-row.interface';

import { FieldDefinition } from '../../../models/form-builder/field-definition.model';
import { FieldDefinitionService } from '../../../services/field-definition.service';
import { ValidationMessageInfo } from '../form-field-base/form-field-base.component';
import { FormulaEvaluationHelper } from './formula-evaluation-helper';

// Note:  please note the 'providers' definition below, as it is needed.
//        Without it, you will get the following exception:
//
//             No value accessor for form control with unspecified name
//
// The above exception gets thrown when a component, in this case our
// base class, implements interface 'ControlValueAccessor' and does not
// provide the 'providers' definition below.  Implementing the
// 'ControlValueAccessor' interface allows a form field component to
// support [(ngMode)], so users of the component can use [(ngModel)].
@Component({
    selector: 'app-formula-form-field',
    templateUrl: './formula-form-field.component.html',
    styleUrls: ['./formula-form-field.component.scss'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FormulaFormFieldComponent,
            multi: true
        }
    ]
})
export class FormulaFormFieldComponent extends FormFieldBaseComponent implements OnInit {
    // Instance data.
    @Output() onInit = new EventEmitter();

    private readonly previewModeValue: number = 0; // Used only in 'design' mode.
    // changed the next property to an Input so that an initial value can bet set
    @Input() calculatedValue: number = 0; // used in 'preview' and 'instance' modes.

    private readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.TOOL_TIP,
            FormFieldPropertyEnum.FORMULA,
            FormFieldPropertyEnum.DISPLAY_FORMAT,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT,
            FormFieldPropertyEnum.ROUND_TO_NUM_DIGITS_AFTER_DECIMAL_POINT,
            FormFieldPropertyEnum.ROUND_TO_WHOLE_NUMBER,
            FormFieldPropertyEnum.SHOW_DIGITS_WITH_COMMA_SEPARATORS,
            FormFieldPropertyEnum.SHOW_DOLLAR_SIGN_PREFIX
        ];

    private readonly displayFormats: string[] =
        [
            'Currency',
            'Integer'
        ];

    private fieldNameHashes: FieldNameHashes = new FieldNameHashes();
    private readonly formulaCalculationErrorValidatorName = 'formulaCalculationError';
    private calculationError: string = null;

    // 01-27-2021:  replaced the following variable with new property
    //              'transientHashOfOtherFieldValues' in model class
    //              FormInstanceElement.
    //private hshFieldValues: any = {};

    // Constructor.
    public constructor(private renderer: Renderer2, private fieldDefinitionService: FieldDefinitionService)
    {
        super();

        return;
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshEventProperties = {
            component: this,
            formField: this.FormField,
            properties: this.formFieldProperties,
            displayFormatValues: this.displayFormats,

            // 04-07-2020 Note:  the following two properties are new and
            //                   not yet implemented in the form builder
            //                   class.
            //
            // formFieldNamesRequired:  indicates that this class needs
            //                          to know the names of other form
            //                          fields.
            // formFieldValueUpdatesRequired:  indicates that this class
            //                                 needs to be notified of
            //                                 form field value changes.
            formFieldNamesRequired: true,
            formFieldValueUpdatesRequired: true,

            propertyUpdateRequired: true
        };

        return (hshEventProperties);
    }

    public ngOnInit(): void {
        let hshEventProperties = this.getProperties();

        this.onInit.emit(hshEventProperties);

        this.calculatedValue = this.FormInstanceElement.doubleValue;
    }

    // Accessor methods

    public get PreviewModeValue(): number {
        return (this.previewModeValue);
    }

    public get CalculatedValue(): number {
        return this.calculatedValue;
    }
    public get FormattedCalculatedValue(): string {
        // Apply any optional formatting properties.
        let value: number = this.formField.roundResultToWholeNumber == true ? Math.round(this.calculatedValue) : this.calculatedValue;
        let formattedValue: string = this.formField.showDigitsWithCommandSeparators ? value.toLocaleString() : `${value}`;
        if (this.formField.showDollarSignPrefix == true)
            formattedValue = `$ ${formattedValue}`;      

        return formattedValue;
    }

    public get FieldNameHashes(): FieldNameHashes {
        return this.fieldNameHashes;
    }
    public set FieldNameHashes(value: FieldNameHashes) {
        this.fieldNameHashes = value;
    }
    public get CalculationError(): string {
        return this.calculationError;
    }
    public set CalculationError(value: string) {
        this.calculationError = value;
    }

    // Override methods defined in my base class.
    public receiveFormFieldNames(formFieldNamesParam: string[], hshColNameToFormFieldParam: IFieldNameToFormField): void {
        // Save the form field names.
        this.FieldNameHashes.hshColNameToFormField = hshColNameToFormFieldParam;

        this.FieldNameHashes.hshDisplayVariableNameToFormField = {};

        for (let fieldName in this.FieldNameHashes.hshColNameToFormField) {
            let gridFormField: FormField = this.FieldNameHashes.hshColNameToFormField[fieldName];

            this.FieldNameHashes.hshFieldIdToFieldName[gridFormField.gridColClientId] = fieldName;

            if (gridFormField.displayName) {
                let gridFieldVariableName: string = FormulaFormFieldComponent.displayNameToVariableName(gridFormField);

                this.FieldNameHashes.hshDisplayVariableNameToFormField[gridFieldVariableName] = gridFormField;
            }
        }
    }

    public propertyUpdated(formField: FormField, propertyName: string): void {
        if (propertyName === FormFieldPropertyEnum.FORMULA) {
            // Try to parse this formula.
            this.FormField.transientFormulaError = null;

            // pharv -- 8/4/2023 -- VNEXT-778 - while fixing the reported bug I factored evaluateFormula() out of this class and into the new FormulaEvaluator class
            // which is now called from this class and from other places in the codebase 
            let helper = new FormulaEvaluationHelper().set(this.FormField, this.fieldNameHashes);
            let hshResult = FormulaEvaluator.evaluateFormula(helper, this.FormField.formula, null);

            if (hshResult) {
                if (hshResult['error']) {
                    this.FormField.transientFormulaError = hshResult['error'];

                    if (this.FormField.defaultValue && (parseFloat(this.FormField.defaultValue) != NaN)) {
                        this.calculatedValue = parseFloat(this.FormField.defaultValue);
                    } else {
                        this.calculatedValue = 0;
                    }
                } else {
                    this.FormInstanceElement.DoubleValue = hshResult['result'];
                    this.calculatedValue = hshResult['result']; //1
                    this.FormField.transientFormulaError = null;
                }                
            }
        }

        return;
    }

    public requiresFieldValueUpdate(): boolean {
        return true;
    }
    public formFieldValueUpdated(iColIndex: number, formFieldParam: FormField, formInstanceElement: FormInstanceElement, gridRow: IGridRow): boolean {
        // Determine the field's value.
        let numValue: number = 0.0;

        let fieldDefinition: FieldDefinition = this.fieldDefinitionService.getFieldDefinition(formFieldParam.fieldDefinitionClassName);

        if (fieldDefinition.isNumeric) {
            if (formInstanceElement != null) {
                numValue = formInstanceElement.toNumber(formFieldParam, fieldDefinition.formInstanceElementPropertyName);
            }            
        }
        
        // Save the value in the values hash.
        let hshDebugFieldValues: any = {};
        hshDebugFieldValues[`col${iColIndex + 1}`] = numValue;

        hshDebugFieldValues[`col${formFieldParam.id}`] = numValue;

        if (formFieldParam.displayName) {
            let displayVariableName: string = FormulaFormFieldComponent.displayNameToVariableName(formFieldParam);
            hshDebugFieldValues[displayVariableName] = numValue;
        }

        let hshFieldValues: INumericValuesHashByFieldName = gridRow.getNumericValuesHashByFieldName(this.fieldDefinitionService);

        let helper = new FormulaEvaluationHelper().set(this.FormField, this.fieldNameHashes);
        let hshResult = FormulaEvaluator.evaluateFormula(helper, this.FormField.formula, hshFieldValues);

        if (hshResult) {
            if (! hshResult['error']) {
                this.FormInstanceElement.DoubleValue = hshResult['result'];

                // 05-11-2023 note:  setting the value synchronously can trigger
                //                   the ExpressionChangedAfterItHasBeenCheckedError
                //                   error, so it is safer to do it in the background.
                //this.calculatedValue = hshResult['result'];
                setTimeout(() => {
                    this.calculatedValue = hshResult['result'];
                }, 0);        
            }
        }

        // For now, always return true.
        return true;
    }

    // Override the getDisplayValue() base class method.
    // Define a method that allows a component to return its display value.
    public pseudoStatic_getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum): string {
        this.getNumericValue(formFieldParam, formInstanceElementParam, gridRow, processingPhase);

        return (formInstanceElementParam.doubleValue ? new Intl.NumberFormat('en-us', { minimumFractionDigits: 0 }).format(formInstanceElementParam.doubleValue) : '');
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return FormulaFormFieldComponent;
    }

    // Override writeValueTriggered().
    protected writeValueTriggered(): void {
        super.writeValueTriggered();
    }

    // Override a base class method for building validators.
    //protected buildValidatorFunctionsAndMessages(arrValidatorsParam: ValidatorFn[], arrValidationMessagesParam: ValidationMessageInfo[], arrAsyncValidatorsParam?: AsyncValidatorFn[]): void {
    protected buildValidatorFunctionsAndMessages(
        arrValidatorsParam: ValidatorFn[],
        arrValidationMessagesParam: ValidationMessageInfo[],
        arrAsyncValidatorsParam?: AsyncValidatorFn[]): void {
        // First call super.
        super.buildValidatorFunctionsAndMessages(arrValidatorsParam, arrValidationMessagesParam, arrAsyncValidatorsParam);

        // Add my validator.
        { // Using a block to make it look like the code in the base class's method.
            let fieldName = this.FormField.displayName || this.FormField.name;

            arrValidatorsParam.push(Validators.required);
            arrValidationMessagesParam.push({ type: this.formulaCalculationErrorValidatorName, message: `${fieldName} calculation error` });

            //VNEXT-610: KLW - Add the custom validation for no whitespace
            arrValidatorsParam.push(this.formulaCalculationErrorValidator);
            arrValidationMessagesParam.push({ type: this.formulaCalculationErrorValidatorName, message: `${fieldName} has a calculation error` });
        }
    }

    // Implement private helper methods.
    private formulaCalculationErrorValidator(control: FormControl) {
        let retVal: boolean = false;

        if (control.value) {
            if (this.CalculationError != null) {
                retVal = true;
            }
        }

        return retVal ? { 'formulaCalculationError': true } : null;
    }

    private static displayNameToVariableName(formFieldParam: FormField): string {
        let variableName: string = formFieldParam.displayName.toLowerCase().replace(/ /g, '_');

        return variableName;
    }

    public hasNumericData(): boolean {
        return true;
    }
    public getNumericValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum): number {
        if ((processingPhase === FormFieldProcessingPhaseEnum.EDITING_DATA) || (processingPhase === FormFieldProcessingPhaseEnum.LOADING_DATA) || (processingPhase === FormFieldProcessingPhaseEnum.CREATING_DATA)) {
            let hshFieldValues: INumericValuesHashByFieldName = gridRow.getNumericValuesHashByFieldName(this.fieldDefinitionService);

            if ((hshFieldValues != null) && (this.FieldNameHashes.hshColNameToFormField != null) && (formFieldParam.formula != null) && (formFieldParam.formula.trim() !== '')) {

                let helper = new FormulaEvaluationHelper().set(this.FormField, this.fieldNameHashes);
                let hshResult = FormulaEvaluator.evaluateFormula(helper, formFieldParam.formula, hshFieldValues); 

                if (hshResult != null) {
                    if (!hshResult['error']) {
                        formInstanceElementParam.DoubleValue = hshResult['result'];
                    }
                }
            }
        }

        return formInstanceElementParam.doubleValue;
    }

    public hasCalculatedValue(): boolean {
        return (true);
    }
}
