import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { JiraComponent } from "./jira/jira.component";
import { HomeComponent } from "./views/home/home.component";
import { AdminComponent } from './views/admin/admin.component';
import { SiteComponent } from './views/site/site.component';
import { MenuResolver } from './shared/resolvers/menu.resolver';
import { DataCollectionResolver } from './shared/resolvers/data-collection.resolver';
import { LandingPageComponent } from './views/landing-page/landing-page.component';
import { HttpErrorComponent } from './views/http-error/http-error.component';
import { ClaimResolver } from './shared/resolvers/claim.resolver';
import { ClaimGuard } from './route-guards/claim.route-guard';
import { PrintableFormComponent } from './views/site/form/printable-form.component';
import { FormInstanceResolver } from './shared/resolvers/form-instance.resolver';
import { UnsavedChangesGuard } from './route-guards/unsaved-changes.route-guard';
import { HomePageRouteGuard } from './route-guards/home-page.route-guard';

const routes: Routes = [
    {
        path: '',
        component: LandingPageComponent,
    },
    {
        path: '404',
        component: HttpErrorComponent
    },
    {
        path: 'unavailable',
        component: HttpErrorComponent
    },
    {
        path: "home",
        component: HomeComponent,
        resolve: { navMenu: MenuResolver, claims:ClaimResolver },
        data: { menu: "app" },
        loadChildren: () => import('./modules/home.module').then(
            module => module.HomeModule
        )
    },
    {
        path: 'admin',
        component: AdminComponent,
        resolve: { navMenu: MenuResolver, claims: ClaimResolver },
        data: { menu: "admin", requiredClaim: 'CanViewAdmin'},
        canActivate: [ClaimGuard],
        loadChildren: () => import('./modules/admin.module').then(
            module => module.AdminModule
        )
    },
    {
        path: 'site/:id',
        component: SiteComponent,
        resolve: { currentSite: DataCollectionResolver, navMenu: MenuResolver, claims: ClaimResolver },
        data: { menu: "collection" },
        loadChildren: () => import('./modules/site.module').then(
            module => module.SiteModule
        )
    },
    {
        // No ClaimGuard here since api will only return FormInstances the user has access to
        path: 'form/:id',
        component: PrintableFormComponent,
        resolve: { formInstance: FormInstanceResolver }
    }
];

@NgModule({
    // For onSameUrlNavigation option see https://angular.io/api/router/ExtraOptions#onSameUrlNavigation
    // basically it allows a route to be reloaded when it is the active route, whereas the default is to
    // ignore attempts to reload the active route
    // For scrollPositionRestoration option see https://stackoverflow.com/questions/39601026/angular-2-scroll-to-top-on-route-change (Answer titled "Angular 6.1 and later")
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
    providers: [ClaimGuard, UnsavedChangesGuard, HomePageRouteGuard]
})
export class AppRoutingModule { }
